import {ChangeDetectorRef, Component, NgZone, OnInit} from '@angular/core';
import {NotificationService} from '../../services/notification/notification.service';
import {IDtaxNotification} from '../../../models/dtax_notification';
import {MatDialog} from '@angular/material/dialog';
import {NotificationDialogComponent} from './notification-dialog/notification-dialog.component';
import {Subscription} from 'rxjs';
import {MatTabChangeEvent} from '@angular/material/tabs';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

    notificationsRef: Subscription;
    notifications: (IDtaxNotification & { id: string })[] = [];
    notificationStatsRef: Subscription;
    notificationStats: { [key: string]: number } = {};

    constructor(
        private notificationService: NotificationService,
        public dialog: MatDialog,
        private cdr: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.notificationStatsRef = this.notificationService.onNotificationStatsChange().subscribe((data) => {
            this.notificationStats = data;
        });
    }

    get tabs() {
        return Object.keys(this.notificationStats);
    }

    async removeNotification($event: MouseEvent, id: string) {
        $event.stopPropagation();
        await this.notificationService.removeNotification(id);
    }

    onNotificationClick(notification: IDtaxNotification) {
        const dialogRef = this.dialog.open(NotificationDialogComponent, {
            data: notification,
        });
    }

    onTabChange($event: MatTabChangeEvent) {
        const label = $event.tab.textLabel;
        console.log(label);
        this.notificationsRef?.unsubscribe();
        this.notificationsRef = this.notificationService.getNotifications({type: label}).subscribe((data) => {
            this.notifications = data;
            console.log(this.notifications);
            this.cdr.detectChanges();
        });
    }
}
