import {Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren,} from "@angular/core";
import {ApprovedInvoiceType, ShopInvoice,} from "../../../../../models/shop_invoice";
import {KeyValue} from "@angular/common";
import {FormGroup, NgForm} from "@angular/forms";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {ShopItem} from "../../../../../models/shop_item";
import {nanoid} from "nanoid";
import {InvoiceItemAddComponent} from "../invoice-items/invoice-item-add/invoice-item-add.component";
import {LoadingController, ModalController} from "@ionic/angular";
import {HttpClient} from "@angular/common/http";
import {InvoiceOcrService} from "../../../../services/invoice-ocr/invoice-ocr.service";
import {Fichier} from "src/models/fichier";
import {RemoteStorageService} from "../../../../services/remote_storage/remote-storage.service";
import {MaskitoElementPredicateAsync, MaskitoOptions} from "@maskito/core";
import {firstValueFrom} from "rxjs";
import {AngularFireFunctions} from "@angular/fire/compat/functions";
import * as moment from "moment";
import {AnomalyDialogComponent} from "./anomaly-dialog/anomaly-dialog.component";
import {PappersService} from "../../../../services/pappers/pappers.service";

interface ApprovedOption {
    value: ApprovedInvoiceType;
    color: string;
}

@Component({
    selector: "app-invoice",
    templateUrl: "./invoice.component.html",
    styleUrls: ["./invoice.component.scss"],
})
export class InvoiceComponent implements OnInit {
    @Input() invoice!: KeyValue<string, ShopInvoice>;
    @Input() userId!: string;
    @Input() tripId!: string;
    @Input() selected!: boolean;
    @Input() dbPath!: string;
    @Output() selectInvoice: EventEmitter<void> = new EventEmitter<void>();
    @Output() deleteInvoice: EventEmitter<void> = new EventEmitter<void>();
    @Output() updateInvoice: EventEmitter<void> = new EventEmitter<void>();
    @ViewChildren("invoiceItemAdd") invoiceItemAdd: QueryList<InvoiceItemAddComponent>;

    invoiceDate = moment().toISOString();
    maxInvoiceDate = moment().toISOString();
    minInvoiceDate = moment().subtract(90, 'days').toISOString();

    invoiceDateEmpty = true;

    sortedItems!: [string, ShopItem][];
    filesToOcr: Fichier[] = [];
    newItems: ShopItem[] = [];
    approvedOptions: ApprovedOption[] = [
        {
            value: "valid",
            color: "green",
        },
        {
            value: "pending",
            color: "orange",
        },
        {
            value: "anomaly",
            color: "red",
        },
    ];

    readonly dateMask: MaskitoOptions = {
        mask: [
            /[0-3]/,
            /[0-9]/,
            "/",
            /[0-1]/,
            /[0-9]/,
            "/",
            /[1-2]/,
            /\d/,
            /\d/,
            /\d/,
        ],
    };

    constructor(
        private afDb: AngularFireDatabase,
        private pappers: PappersService,
        private storageService: RemoteStorageService,
        private loadingController: LoadingController,
        private http: HttpClient,
        private ocr: InvoiceOcrService,
        private modalCtrl: ModalController
    ) {
    }

    readonly maskPredicate: MaskitoElementPredicateAsync = async (el) =>
        (el as HTMLIonInputElement).getInputElement();

    ngOnInit(): void {
        this.sortedItems = this.sortItemsByDate();
        if (Object.keys(this.invoice.value.items ?? {}).length === 0) {
            this.addItem();
        }

        const regexDate = new RegExp(/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/\d{4}$/);

        const invoiceDateMoment = moment(this.invoice.value.date, "DD/MM/YYYY");
        if (regexDate.test(this.invoice.value.date) && invoiceDateMoment.isValid()) {
            this.invoiceDate = invoiceDateMoment.format("YYYY-MM-DDTHH:mm:ss");
            this.invoiceDateEmpty = false;
        }
        console.log("invoiceDate", this.invoiceDate);
    }

    sortItemsByDate(): [string, ShopItem][] {
        const itemsArray = Object.entries(this.invoice.value.items ?? {});
        itemsArray.sort(
            (a, b) =>
                (a[1].upload_ms_timestamp ?? 0) - (b[1].upload_ms_timestamp ?? 0)
        );
        return itemsArray;
    }

    downloadImageAndConvertToBase64(url: string): Promise<string> {
        return new Promise((resolve, reject) => {
            this.http.get(url, {responseType: "blob"}).subscribe({
                next: (blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const base64data = reader.result as string;
                        const base64 = base64data.split(",")[1]; // Supprimer le préfixe.
                        resolve(base64); // Résoudre la promesse avec la chaîne Base64.
                    };
                    reader.onerror = () => {
                        reject("Erreur lors de la lecture du blob."); // Rejeter la promesse avec une erreur.
                    };
                    reader.readAsDataURL(blob);
                },
                error: (error) => {
                    reject("Erreur lors du téléchargement du document : " + error);
                },
            });
        });
    }

    get itemsAddAreValid() {
        return this.invoiceItemAdd.toArray().every((item) => item.form.valid);
    }

    getInvoiceAmountWithoutTax(invoice: ShopInvoice) {
        let total = 0;
        for (const value of Object.values(invoice.items ?? {})) {
            // @ts-ignore
            total =
                total + parseFloat((value.amount / (1 + value.tax / 100)).toFixed(2));
        }
        return total.toFixed(2);
    }

    getInvoiceTaxAmount(invoice: ShopInvoice) {
        let total = 0;
        for (const value of Object.values(invoice.items ?? {})) {
            // @ts-ignore
            total =
                total +
                parseFloat((value.amount / (1 + value.tax / 100)).toFixed(2)) *
                (value.tax / 100);
        }
        return total.toFixed(2);
    }

    getInvoiceAmount(invoice: ShopInvoice) {
        let total = 0;
        for (const value of Object.values(invoice.items ?? {})) {
            total = total + value.amount;
        }
        return total.toFixed(2);
    }

    async onDeleteInvoice() {
        if (!confirm("Are you sure you want to delete this invoice ?")) {
            return;
        }
        this.deleteInvoice.emit();
        await this.afDb.object(this.dbPath).remove();
    }

    async onSiretChange(value: string) {
        const vat = this.extractVATFromSiret(value);
        if (vat) {
            this.invoice.value.vatNumber = vat;
            await this.getNameFromVAT();
        }
    }

    async onVATChange(value: string) {
        const siretInput = this.invoice.value.siret ?? "";
        const sirenFromSiret = this.extractSirenFromSiret(siretInput);
        const sirenFromTva = this.extractSirenFromVAT(value);
        if (sirenFromTva && sirenFromSiret !== sirenFromTva) {
            this.invoice.value.siret = sirenFromTva;
            await this.getNameFromVAT();
        }
    }

    extractSirenFromVAT(numeroTva: string): string | undefined {
        numeroTva = numeroTva.replace(/\s/g, "");
        if (numeroTva.startsWith("FR") && numeroTva.length === 13) {
            return numeroTva.substring(4);
        }
    }

    extractSirenFromSiret(siret: string): string | undefined {
        siret = siret.replace(/\s/g, "");
        if (siret.length === 14 || siret.length === 9) {
            return siret.substring(0, 9);
        }
    }

    extractVATFromSiret(siret: string): string | undefined {
        siret = siret.replace(/\s/g, "");
        if (siret.length !== 14 && siret.length !== 9) {
            return undefined;
        }
        const siren = this.extractSirenFromSiret(siret);
        const cleTva = (12 + 3 * (parseInt(siren, 10) % 97)) % 97;
        return `FR${cleTva.toString().padStart(2, '0')}${siren}`;
    }

    async getNameFromVAT() {
        const vat = this.invoice?.value?.vatNumber || "";
        if (!vat.startsWith("FR")) {
            return this.checkVAT();
        }
        const siren = this.extractSirenFromVAT(vat);
        //  show loader
        const loading = await this.loadingController.create({
            message: "Loading...",
        });
        await loading.present();
        const result = await this.pappers.autocomplete(siren);
        await loading.dismiss();
        if (!result.is_active) {
            alert("Invalid VAT number");
            return;
        }
        this.invoice.value.shop_name = result.name;
        console.log(result);
    }

    async checkVAT() {
        const vat = this.invoice?.value?.vatNumber || "";
        const isoCode = vat.substring(0, 2);
        const vatNoIso = vat.substring(2, 99);

        window.open(
            `https://ec.europa.eu/taxation_customs/vies/rest-api/ms/${isoCode}/vat/${vatNoIso}`,
            "_blank"
        );
    }

    async checkSiret() {
        const siret = this.invoice?.value?.siret || "";
        const siren = siret.substring(0, 9);
        window.open(`https://www.pappers.fr/entreprise/${siren}`, "_blank");
    }

    async onOCRInvoice() {
        if (!confirm("Êtes-vous sûr de vouloir lancer l'OCR ?")) {
            return;
        }
        const files: string[] = [];
        for (const fileUpload of this.filesToOcr) {
            const fileB64 = await this.downloadImageAndConvertToBase64(
                fileUpload.uri
            );
            files.push(fileB64);
        }
        const loading = await this.loadingController.create({
            message: "Traitement en cours...",
        });
        await loading.present();
        const result = await this.ocr.getInvoiceData(files);
        const invoice = result.data.invoice;
        this.newItems = Array.from(Object.values(invoice.items ?? {}));
        console.log(this.newItems);
        this.invoice.value.invoice_number ??= invoice.invoice_number;
        console.log("invoice.date", invoice.date);
        const ocrInvoiceDate = moment(invoice.date, "DD/MM/YYYY");
        console.log("isAfter: -90", ocrInvoiceDate.isSameOrAfter(moment().subtract(90, 'days')));
        console.log("isBefore: today", ocrInvoiceDate.isSameOrBefore(moment()));
        if (ocrInvoiceDate.isValid() && ocrInvoiceDate.isSameOrAfter(moment().subtract(90, 'days')) && ocrInvoiceDate.isSameOrBefore(moment())) {
            this.invoice.value.date = invoice.date;
            this.invoiceDate = moment(invoice.date, "DD/MM/YYYY").toISOString();
        } else {
            this.invoiceDateEmpty = true;
            this.invoiceDate = undefined;
            this.invoice.value.date = undefined;
        }
        this.invoice.value.vatNumber ??= invoice.vatNumber;
        this.invoice.value.siret ??= invoice.siret;
        this.invoice.value.shop_name ??= invoice.shop_name;
        this.invoice.value.shop_address ??= invoice.shop_address;
        this.invoice.value.ocr ??= this.invoice.value.ocr ?? {};
        for (const file of this.filesToOcr) {
            this.invoice.value.fichiers[file.id].ocr = result.data.rawOcr;
        }
        this.filesToOcr = [];
        await loading.dismiss();
        await this.saveInvoice(this.dbPath);
    }

    onSelectInvoice() {
        this.selectInvoice.emit();
    }

    async deleteItem(itemId) {
        await this.afDb.object(this.dbPath + "/items/" + itemId).remove();
    }

    async anomalyForm(invoiceId: string, form: NgForm) {
        await this.onUpdateInvoice();
        if (+form.value.anomalyId === 5) {
            await this.afDb
                .object(`${this.dbPath}/anomaly_comment`)
                .set(form.value.other);
        }
        await this.afDb
            .object(`${this.dbPath}/anomaly_id`)
            .set(form.value.anomalyId);
    }

    async saveInvoice(path: string) {
        const invoiceVal = this.invoice.value;
        const loading = await this.loadingController.create({
            message: "Uploading files...",
        });
        await loading.present();
        loading.message = "Uploading invoice items...";
        if (!invoiceVal.items) {
            invoiceVal.items = {};
        }
        for (const invoiceItemAddComp of this.invoiceItemAdd) {
            const form = invoiceItemAddComp.form;
            const itemId = form.get("id").value ?? nanoid();
            invoiceVal.items[itemId] = this.convertFormToItem(form);
        }

        if (!this.invoiceDate) {
            invoiceVal.date = null;
        } else {
            invoiceVal.date = moment(this.invoiceDate).format("DD/MM/YYYY") ?? null;
        }

        await this.afDb.object(path).update(invoiceVal);
        if (path !== this.dbPath) {
            await this.afDb.object(this.dbPath).remove();
        }

        this.newItems = [];
        await loading.dismiss();
        this.updateInvoice.emit();
    }

    async onUpdateInvoice() {
        if (!this.itemsAddAreValid) {
            alert("There is an error with an item, please check it");
            return;
        }

        const publishedPath = `users/${this.userId}/trips/${this.tripId}/shop_invoices/${this.invoice.key}`;
        await this.saveInvoice(publishedPath);
    }

    async onDraftInvoice() {
        const draftPath = `users/${this.userId}/trips/${this.tripId}/temp_shop_invoices/${this.invoice.key}`;
        await this.saveInvoice(draftPath);
    }

    convertFormToItem(form: FormGroup): ShopItem {
        return {
            description: form.get("description")?.value ?? "",
            brand: form.get("brand")?.value ?? "",
            tax: form.get("tax")?.value ?? 20,
            num_serie: form.get("num_serie")?.value,
            quantity: form.get("quantity")?.value,
            categorie: form.get("category")?.value,
            upload_ms_timestamp: form.get("upload_ms_timestamp")?.value ?? Date.now(),
            amount: parseFloat(form.get("amountTTC")?.value ?? "0"),
        };
    }

    addItem() {
        this.newItems.push({
            upload_ms_timestamp: Date.now(),
        });
    }

    deleteItemAdd(item: ShopItem) {
        const index = this.newItems.indexOf(item);
        this.newItems.splice(index, 1);
    }

    async onFileDropped($event: Fichier[]) {
        const filesObject: { [id: string]: Fichier } = {};
        for (const file of $event) {
            filesObject[file.id] = file;
        }
        await this.afDb.object(`${this.dbPath}/fichiers`).update(filesObject);
    }

    onOCRCheckBoxChange($event: Map<Fichier, boolean>) {
        $event.forEach((isChecked, file) => {
            const index = this.filesToOcr.indexOf(file);
            if (isChecked && index === -1) {
                this.filesToOcr.push(file);
            } else if (!isChecked && index > -1) {
                this.filesToOcr.splice(index, 1);
            }
        });
    }

    async onRemoveFile($event: Fichier) {
        await this.storageService.removeFromUri($event.uri);
        await this.afDb.object(`${this.dbPath}/fichiers/${$event.id}`).remove();
    }

    async onNewItemSave($event: FormGroup) {
        const item = this.convertFormToItem($event);
        item.upload_ms_timestamp = Date.now();
        const categ = item.categorie?.id;
        const itemId = nanoid(21);
        if (categ === 59 || categ === 62) {
            await this.afDb.object(`${this.dbPath}`).update({
                [`anomalies/${itemId}`]: {
                    anomaly_id: 7,
                    data: itemId,
                },
                approved: "anomaly"
            });
        }
        await this.afDb.object(`${this.dbPath}/items/${itemId}`).set(item);
    }

    async onSaveItem(itemId: string, form: FormGroup) {
        const item = this.convertFormToItem(form);
        item.upload_ms_timestamp ??= Date.now();
        const categ = item.categorie?.id;
        if (categ === 59 || categ === 62) {
            await this.afDb.object(`${this.dbPath}`).update({
                [`anomalies/${itemId}`]: {
                    anomaly_id: 7,
                    data: itemId,
                },
                approved: "anomaly"
            });
        }
        await this.afDb.object(`${this.dbPath}/items/${itemId}`).set(item);
    }

    async presentAnomalyDialog() {
        const modal = await this.modalCtrl.create({
            component: AnomalyDialogComponent,
            cssClass: 'bottom-sheet',
            componentProps: {
                selectedAnomalyId: this.invoice.value.anomalies?.main_anomaly?.anomaly_id ?? this.invoice.value.anomaly_id,
                customAnomaly: this.invoice.value.anomalies?.main_anomaly?.data ?? this.invoice.value.anomaly_comment,
            }
        });
        await modal.present();
        const {data} = await modal.onWillDismiss();
        if (data) {
            await this.afDb.object(`${this.dbPath}`).update({
                "anomalies/main_anomaly": {
                    anomaly_id: data.anomalyId,
                    data: data.customAnomaly,
                },
                approved: "anomaly"
            });

        }
    }

    async onSelectChange(opened: boolean) {
        if (!opened && this.invoice.value.approved) {
            if (this.invoice.value.approved === 'anomaly') {
                await this.presentAnomalyDialog();
            }
        }
    }
}
