import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-customs-status',
    templateUrl: './customs-status.component.html',
    styleUrls: ['./customs-status.component.css']
})
export class CustomsStatusComponent implements OnChanges {

    @Input() currentStatus: any;
    color: string;
    text: string;
    tipText: string;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.currentStatus) {
            this.onStatusChange();
        }
    }

    onStatusChange(): void {
        const type = this.currentStatus?.type;
        const status = this.currentStatus?.status;
        const reason = this.currentStatus?.reason;
        if (type === 'bve_creation') {
            if (status === 'received_ack') {
                this.text = 'Recorded';
                this.tipText = 'We are generating taxform.';
                this.color = 'primary';
            } else if (status === 'ok') {
                this.text = 'Generated';
                this.tipText = 'Taxform has been generated.';
                this.color = 'success';
            } else if (status === 'internal_error') {
                this.text = 'Internal error';
                this.tipText = 'Taxform number received by the customs, but we had an internal error.';
                this.color = 'danger';
            } else if (status === 'ko') {
                this.text = 'Customs error';
                this.tipText = 'Taxform number received by the customs, but they refused it.';
                this.color = 'danger';
            }
        } else if (type === 'bve_deletion') {
            if (status === 'ok') {
                this.text = 'Deleted';
                this.tipText = 'Taxform has been deleted by the customs.';
                this.color = 'success';
            } else if (status === 'ko') {
                this.text = 'Deletion refused';
                this.tipText = `Customs notified by taxform deletion, but they refused it. ${reason}`;
                this.color = 'danger';
            }
        } else if (type === 'bve_apurement') {
            if (status === 'ko') {
                this.text = 'Internal error';
                this.tipText = `Internal error during clearance. ${reason}`;
                this.color = 'danger';
            } else if (status === 'ok') {
                this.text = 'Validated';
                this.tipText = 'Taxform has been validated by the customs.';
                this.color = 'success';
            }
        } else if (type === 'bve_apurement_failure') {
            if (status === 'ok') {
                this.text = 'Refused';
                this.tipText = 'Taxform has been refused by the customs.';
                this.color = 'danger';
            } else if (status === 'ko') {
                this.text = 'Internal error';
                this.tipText = 'Customs notified that the taxform was rejected but we had an internal error.';
                this.color = 'danger';
            }
        }
    }

}
