<cdk-accordion class="example-accordion">
    <cdk-accordion-item
            *ngFor="let item of history | keyvalue: keyDescOrder"
            #accordionItem="cdkAccordionItem"
            class="example-accordion-item"
            role="button"
            tabindex="0"
            [attr.id]="'header-' + item.key"
            [attr.aria-expanded]="accordionItem.expanded"
            [attr.aria-controls]="'body-' + item.key">
        <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
            <div class="header-info">
                <p class="smaller">
                    {{ item.key | date: 'dd/MM/yyyy' }}
                </p>
                <p>
                    {{ item.value.customHistory ? item.value.customHistory.type : item.value.type }} - {{ item.value.customHistory ? item.value.customHistory.status : item.value.status }}
                </p>
            </div>
            <span class="example-accordion-item-description">
        Click to {{ accordionItem.expanded ? 'close' : 'open' }}
      </span>
        </div>
        <div
                class="example-accordion-item-body"
                role="region"
                [style.display]="accordionItem.expanded ? '' : 'none'"
                [attr.id]="'body-' + item.key"
                [attr.aria-labelledby]="'header-' + item.key">
            <pre>
            {{ item.value | json }}
            </pre>
        </div>
    </cdk-accordion-item>
</cdk-accordion>