import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {QRCodeComponent} from 'angularx-qrcode';
import {MatDialog} from '@angular/material/dialog';
import {IntroducerService} from '../../../services/introducer/introducer.service';
import {Introducer} from '../../../../models/marketers';
import * as XLSX from 'xlsx';

export interface IntroducerSource extends Introducer {
    name: string;
}

@Component({
    selector: 'app-marketers',
    templateUrl: './introducers.component.html',
    styleUrls: ['./introducers.component.css']
})
export class IntroducersComponent implements OnInit {
    @ViewChild('qrCode') qrCode: QRCodeComponent;

    @Input() plateform?: string;

    displayedColumns: string[] = ['nomMarketer', 'nombreClicks', 'nombreRefers', 'totalRefund', 'urlInvitation', 'actions'];
    dataSource: MatTableDataSource<IntroducerSource>;
    url: string;
    expandedElement: IntroducerSource | null;

    constructor(private marketerService: IntroducerService, public dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.marketerService.getIntroducers({plateform: this.plateform}).then(marketers => {
            const marketersObject = Object.entries(Object.fromEntries(marketers));
            const mappedMarketers = marketersObject.map(([key, value]) => ({name: key, ...value}));
            this.dataSource = new MatTableDataSource(mappedMarketers);
        });
    }

    saveAsImage(marketer: IntroducerSource) {
        this.url = marketer.url ?? '';
        const parentElement = this.qrCode.qrcElement.nativeElement
            .querySelector('canvas')
            .toDataURL('image/png');
        setTimeout(() => {
            const blobData = this.convertBase64ToBlob(parentElement);
            // saves as image
            const blob = new Blob([blobData], {type: 'image/png'});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            console.log(url);
            link.download = `${marketer.name}-qrcode`;
            link.click();
        }, 100);
    }

    async downloadCsv($event: MouseEvent, marketer: IntroducerSource) {
        $event.stopPropagation();

        const workBook: XLSX.WorkBook = XLSX.utils.book_new();

        const wsSummary: XLSX.WorkSheet = XLSX.utils.json_to_sheet([marketer]);
        XLSX.utils.book_append_sheet(workBook, wsSummary, 'Summary');
        const width = [marketer].reduce((w, r) => Math.max(w, r.name.length), 10);
        wsSummary['!cols'] = [{wch: width}];
        const userTable = new Array<any>();
        const introducerUsers = await this.marketerService.getIntroducerUsers(marketer.name);
        for (const [userId, user] of introducerUsers) {
            for (const [, trip] of Object.entries(user.trips)) {
                userTable.push({
                    user_id: userId,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    affiliation_date: new Date(user.date).toLocaleDateString(),
                    bve: trip.bve,
                    refund_amount: trip.refund,
                    fees: trip.commission_amount,
                    dtb_to_user_payment_date: new Date(trip.refund_date).toLocaleDateString(),
                    gov_to_dtb_payment_date: new Date(trip.estimated_gov_refund_date).toLocaleDateString(),
                    dtb_to_you_payment_date: new Date(trip.estimated_comp_refund_date).toLocaleDateString(),
                });
            }
        }

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(userTable);

        XLSX.utils.book_append_sheet(workBook, ws, 'Details');

        ws['!cols'] = [{wch: 30}];

        XLSX.writeFile(workBook, `${marketer.name}.xlsx`);
    }


    private convertBase64ToBlob(Base64Image: string) {
        // split into two parts
        const parts = Base64Image.split(';base64,');
        // hold the content type
        const imageType = parts[0].split(':')[1];
        // decode base64 string
        const decodedData = window.atob(parts[1]);
        // create unit8array of size same as row data length
        const uInt8Array = new Uint8Array(decodedData.length);
        // insert all character code into uint8array
        for (let i = 0; i < decodedData.length; ++i) {
            uInt8Array[i] = decodedData.charCodeAt(i);
        }
        // return blob image after conversion
        return new Blob([uInt8Array], {type: imageType});
    }
}
