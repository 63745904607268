import { Injectable } from '@angular/core';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {nanoid} from 'nanoid';
import { FileSystemFileEntry as NgxFileSystemFileEntry } from 'ngx-file-drop';
import {firstValueFrom} from 'rxjs';

export interface UploadFileI {
    id?: string;
    file: File | NgxFileSystemFileEntry;
    path: string;
}

export interface UploadFileResultI {
    id: string;
    url: string;
}
@Injectable({
  providedIn: 'root'
})
export class RemoteStorageService {

  constructor(private afStorage: AngularFireStorage) { }

    async removeFromPath(path: string): Promise<void> {
        return await firstValueFrom(this.afStorage.ref(path).delete());
    }

    async removeFromUri(uri: string): Promise<void> {
        return await firstValueFrom(this.afStorage.refFromURL(uri).delete());
    }
  async uploadFile(params: UploadFileI): Promise<UploadFileResultI> {
      const id = params.id ?? nanoid();
      const path = params.path + '/' + id;
      let url: string;
    if (params.file instanceof File) {
      url = await this.uploadFromFile(path, params.file);
    } else if (params.file && (params.file as NgxFileSystemFileEntry).file) {
        url = await this.uploadFromFileSystemFileEntry(path, params.file);
    }
    return { id, url };
  }

  private async uploadFromFile(path: string, file: File): Promise<string> {
    const task = await this.afStorage.upload(path , file);
    return await task.ref.getDownloadURL();
  }

  private async uploadFromFileSystemFileEntry(path: string, fileEntry: NgxFileSystemFileEntry): Promise<string> {
      return new Promise<string>(async (resolve, reject) => {
      try {
        await fileEntry.file(async (f: File) => {
            resolve(this.uploadFromFile(path, f));
        });
      } catch (error) {
        reject(error);
      }
    });
  }
}
