import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {prettyPrintJson} from 'pretty-print-json';

@Component({
  selector: 'app-info-error',
  templateUrl: './info-error.component.html',
  styleUrls: ['./info-error.component.scss'],
})
export class InfoErrorComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    document.getElementById('data').innerHTML = prettyPrintJson.toHtml(this.data);
  }

}
