import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {MatSelectChange} from '@angular/material/select';

interface CustomsStubData {
  tripId: string;
  userId: string;
}

@Component({
  selector: 'app-customs-stub',
  templateUrl: './customs-stub.component.html',
  styleUrls: ['./customs-stub.component.scss'],
})
export class CustomsStubComponent implements OnInit {
  showStubSpinner = false;
  selected = undefined;
  showMultipleBves = false;
  showMultipleBvesP16 = false;
  bves = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: CustomsStubData, private fns: AngularFireFunctions, private db: AngularFireDatabase) {}

  ngOnInit() {}

  onStubChange(event: MatSelectChange) {
    this.showMultipleBves = event.value === 'BVEApurementMultiple';
    this.showMultipleBvesP16 = event.value === 'BVEApurementP0016';
  }
  sendStub(stub: string) {
    console.log(this.bves);
    if (this.selected) {
      this.showStubSpinner = true;
      const callable = this.fns.httpsCallable('edi-sendStub');
      console.log('sending stub');
      const data = {tripId: this.data.tripId, userId: this.data.userId, type: stub, bves: this.bves};
      console.log(data);
      callable(data).toPromise().then(() => {
        this.showStubSpinner = false;
      }).catch(reason => {
        console.log('error: ' + reason);
      });
    }
  }
  readMail() {
    if (this.selected) {
      const callable = this.fns.httpsCallable('edi-readMailDouanes');
      console.log('reading mail');
      callable({}).toPromise().then(() => {
      }).catch(reason => {
        console.log('error: ' + reason);
      });
    }
  }
}
