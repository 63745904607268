import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {PappersAutocompleteResponse} from "./pappers.interfaces";
import {firstValueFrom} from "rxjs";

export class Company {
    siren: string;
    name: string;
    is_active: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class PappersService {

    base_url = "https://api.pappers.fr/v3/";

    constructor(private http: HttpClient) {
    }

    async autocomplete(siren: string): Promise<Company | undefined> {
        const response = await firstValueFrom(this.http.get<PappersAutocompleteResponse>(`${this.base_url}suggestions-frontend?q=${siren}&longueur=1&cibles_entreprise=siren`));
        const entreprise = response.entreprises[0];
        if (!entreprise) {
            return undefined;
        }
        return {
            siren: entreprise.siren,
            name: entreprise.nom_entreprise || entreprise.denomination,
            is_active: entreprise.statut_rcs === "inscrit"
        };
    }


}