export type TDtaxNotificationType = 'customs' | 'invoice';
export type TDtaxNotificationLevel = 'info' | 'warning' | 'error';

export interface IDtaxNotificationCustomsData {
    userId: string;
    tripId: string;
    email: string;
}

export interface IDtaxNotification {
    text: string;
    level: TDtaxNotificationLevel;
    isGlobal: boolean;
    type: TDtaxNotificationType;
    data: IDtaxNotificationCustomsData;
    hiddenBy: string[];
}

export interface IDtaxNotificationStats {
    [key: string]: number;
}
