import {Component, OnInit} from '@angular/core';

import {Platform, PopoverController} from '@ionic/angular';
import {AuthService} from './services/auth.service';
import {Router, RoutesRecognized} from '@angular/router';
import {LoadingController} from '@ionic/angular';
import {register} from 'swiper/element/bundle';
import {NotificationsComponent} from './widgets/notifications/notifications.component';
import {NotificationService} from './services/notification/notification.service';
import {Subscription} from 'rxjs';
import {IDtaxNotificationStats} from '../models/dtax_notification';
import {MatIconRegistry} from "@angular/material/icon";

const {version} = require('../../package.json');


register();

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
    appReady = false;
    version: any = version;
    isOperator = false;
    isLogged = false;
    title = '';
    notificationStatsSubscription: Subscription;
    notificationCount: number;

    constructor(
        private platform: Platform,
        public authService: AuthService,
        private router: Router,
        private loadingController: LoadingController,
        private notificationService: NotificationService,
        public popoverController: PopoverController,
        private readonly iconRegistry: MatIconRegistry
    ) {
        const defaultFontSetClasses = iconRegistry.getDefaultFontSetClass();
        const outlinedFontSetClasses = defaultFontSetClasses
            .filter((fontSetClass) => fontSetClass !== 'material-icons')
            .concat(['material-symbols-outlined']);
        iconRegistry.setDefaultFontSetClass(...outlinedFontSetClasses);
    }

    async ngOnInit(): Promise<void> {
        console.log('Creating');
        await this.initializeApp();
        this.authService.isOperator().then((isOperator) => {
            this.isOperator = isOperator;
        });
        this.authService.logStateChange.subscribe(async (isLogged) => {
            this.isLogged = isLogged;
            this.isOperator = await this.authService.isOperator();
        });
        this.router.events.subscribe((data) => {
            if (data instanceof RoutesRecognized) {
                this.title = data.state.root.firstChild.data.title ?? 'Dashboard';
            }
        });
        this.notificationStatsSubscription = this.notificationService.onNotificationStatsChange().subscribe(
            stats => {
                console.log(`Notification stats: ${JSON.stringify(stats, null, 2)}`);
                this.notificationCount = Object.values(stats ?? {}).reduce((a, b) => a + b, 0);
                console.log('Count: ' + this.notificationCount);
            }
        );
    }

    async initializeApp() {
        this.platform.ready().then(() => {
            this.loadingController.create({
                message: 'Loading App',
                duration: 1000
            }).then((res) => {
                res.present();
                setTimeout(() => this.appReady = true, 1000);
            });
        });
        await this.authService.initialize();
    }

    async openNotificationMenu(event: Event) {
        const popover = await this.popoverController.create({
            component: NotificationsComponent,
            event,
            side: 'bottom',
            cssClass: 'notifications-popover',
            translucent: false,
        });

        await popover.present();
    }

    onInvoiceClick() {
        // redirect to google
        const url = "https://google.fr";
        window.open(url, "_blank");
    }
}
