<mat-table [dataSource]="dataSource" class="block max-h-96 overflow-y-auto">

    <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> User ID</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.userId}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="firstName">
        <mat-header-cell *matHeaderCellDef> First name</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.first_name}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="lastName">
        <mat-header-cell *matHeaderCellDef> Last name</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.last_name}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="gov_rf_date">
        <mat-header-cell *matHeaderCellDef matTooltip="Estimated date on which we will be refunded"> Estimated
            government refund date
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.estimated_gov_refund_date | date:'dd/MM/yyyy'}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="comp_rf_date">
        <mat-header-cell *matHeaderCellDef matTooltip="Estimated date we will pay you"> Estimated payment date
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.estimated_comp_refund_date | date:'dd/MM/yyyy'}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="refund">
        <mat-header-cell *matHeaderCellDef matTooltip="Amount we refunded to user"> Refund</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.refund | number : '1.2-2'}}€</mat-cell>
    </ng-container>

    <ng-container matColumnDef="fees">
        <mat-header-cell *matHeaderCellDef matTooltip="Amount we will refund you"> Fees</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.commission_amount | number : '1.2-2'}}€</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

</mat-table>
