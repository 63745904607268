import {inject, Injectable} from '@angular/core';
import {
    collection,
    deleteDoc,
    doc,
    Firestore,
    getCountFromServer,
    getDoc,
    onSnapshot,
    query,
    where
} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {IDtaxNotification, IDtaxNotificationStats} from '../../../models/dtax_notification';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    firestore: Firestore = inject(Firestore);

    constructor() {
    }

    getNotifications(params?: { type: string }) {
        const notificationsCollection = collection(this.firestore, 'notifications');

        const wheres = [
            where('isGlobal', '==', true)
        ];
        if (params?.type) {
            wheres.push(where('type', '==', params.type));
        }

        const notificationsQuery = query(notificationsCollection, ...wheres);
        return new Observable<(IDtaxNotification & { id: string })[]>(observer => onSnapshot(notificationsQuery,
            (snapshot => observer.next(snapshot.docs.map((docSnapshot) => {
                const data = docSnapshot.data() as IDtaxNotification;
                return {
                    id: docSnapshot.id,
                    ...data
                };
            }))),
            (error => observer.error(error.message))
        ));
    }

    async removeNotification(id: string) {
        const notificationRef = doc(this.firestore, 'notifications', id);
        return await deleteDoc(notificationRef);
    }

    onNotificationStatsChange() {
        const stats = doc(this.firestore, 'stats/notifications');
        return new Observable<IDtaxNotificationStats>(
            observer =>
                onSnapshot(stats,
                    snapshot => observer.next(snapshot.data() as IDtaxNotificationStats))
        );
    }
}
