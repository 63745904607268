import {Component, Input, OnInit} from '@angular/core';
import {TDtaxNotificationLevel, TDtaxNotificationType} from 'src/models/dtax_notification';

@Component({
    selector: 'app-notification-type',
    templateUrl: './notification-type.component.html',
    styleUrls: ['./notification-type.component.css']
})
export class NotificationTypeComponent implements OnInit {

    @Input() level: TDtaxNotificationLevel;
    icon = '';
    color = '';

    constructor() {
    }

    ngOnInit(): void {
        switch (this.level) {
            case 'info':
                this.icon = 'information-circle-outline';
                this.color = 'medium';
                break;
            case 'warning':
                this.icon = 'warning-outline';
                this.color = 'warning';
                break;
            case 'error':
                this.icon = 'close-circle-outline';
                this.color = 'danger';
                break;
        }
    }

}
