<nb-layout>

    <nb-layout-header fixed>
        <!-- Insert header here -->
    </nb-layout-header>

    <nb-layout-column>
        <nb-layout>
            <nb-layout-column>
                <ion-app>
                    <ion-header>
                        <ion-toolbar>
                            <ion-buttons slot="start">
                                <ion-menu-button></ion-menu-button>
                            </ion-buttons>
                            <ion-title>{{ title }} - v{{ version }}</ion-title>
                            <ion-buttons *ngIf="isLogged" slot="end" class="relative">
                                <!---
                                <ion-button [matBadge]="notificationCount"
                                            (click)="openNotificationMenu($event)"
                                            color="primary">
                                    <ion-icon slot="icon-only" name="notifications"></ion-icon>
                                </ion-button>
                                --->
                                <ion-button color="danger" (click)="authService.logout(true)">
                                    Logout
                                </ion-button>
                            </ion-buttons>
                        </ion-toolbar>
                    </ion-header>
                    <ion-content>
                        <ion-tabs *ngIf="appReady">
                            <ion-tab-bar slot="bottom" *ngIf="isOperator">
                                <ion-tab-button tab="admin/list">
                                    <ion-icon name="cafe"></ion-icon>
                                    <ion-label>Back-office</ion-label>
                                </ion-tab-button>
                                <ion-tab-button (click)="onInvoiceClick()">
                                    <ion-icon name="receipt"></ion-icon>
                                    <ion-label>Invoices</ion-label>
                                </ion-tab-button>
                                <ion-tab-button tab="admin/settings">
                                    <ion-icon name="settings"></ion-icon>
                                    <ion-label>Settings</ion-label>
                                </ion-tab-button>
                            </ion-tab-bar>
                        </ion-tabs>
                    </ion-content>
                </ion-app>
            </nb-layout-column>
        </nb-layout>

    </nb-layout-column>

    <nb-layout-footer fixed>
        <!-- Insert footer here -->
    </nb-layout-footer>

</nb-layout>
