import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {InfoErrorComponent} from './info-error/info-error.component';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {AngularFireFunctions} from '@angular/fire/compat/functions';

@Component({
  selector: 'app-customs-error',
  templateUrl: './customs-error.component.html',
  styleUrls: ['./customs-error.component.scss'],
})
export class CustomsErrorComponent implements OnInit {
  errorType = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private afDb: AngularFireDatabase,
              private fns: AngularFireFunctions,
              public dialog: MatDialog) {
  }

  ngOnInit() {
  }

  openDialog(value: any) {
    this.dialog.open(InfoErrorComponent, {data: value});
  }

  async sendMail(trip: any) {
    if (confirm('Are you sure to send BVE/OI creation email to customs?')) {
      const userId = trip.value.user;
      const tripId = trip.value.trip;
      const dbRef = this.afDb.database.ref('trips/' + trip.value.trip);
      const typeSend = await (await dbRef.child('customs/currentStatus/type').get()).val();
      const callable = this.fns.httpsCallable('edi-sendMailDouanes');
      let data: Promise<any>;
      if (typeSend === 'bve_creation') {
        data = callable({type: 'generation', tripId, userId, retry: true}).toPromise();
      } else if (typeSend === 'bve_deletion') {
        const bveId = await (await dbRef.child('taxform/docId').get()).val();
        data = callable({
          type: 'suppression',
          tripId,
          userId,
          bveId,
          cause: 'Modification facture',
          retry: true
        }).toPromise();
      } else {
        this.errorType = true;
      }

      data.then(() => {
        console.log('mail sent');
      }).catch(reason => {
        console.log('error: ' + reason);
      });
    }

  }

  removeError(errorId) {
    if (confirm('Are you sure to remove this error ?')) {
      this.afDb.database.ref('/customs/error/' + errorId).remove().then(() => {
        this.data.error = Object.fromEntries(Object.entries(this.data?.error).filter(([key, value]) => key !== errorId));
      });
    }
  }
}

