<ion-content class="ion-padding">
    <ion-list>
        <ion-item *ngIf="timerQBO === 0" color="danger">
            <ion-label>Refresh token QBO expiry</ion-label>
        </ion-item>
        <ion-item
                *ngIf="customsErrorQty !== 0"
                color="danger"
                (click)="showCustomsError()"
                button=""
        >
            <ion-label>Customs error</ion-label>
            <ion-badge slot="end">{{customsErrorQty}}</ion-badge>
        </ion-item>
        <ion-item [routerLink]="['/admin/marketers']" color="primary">
            <ion-label>Introducers</ion-label>
        </ion-item>
        <ion-item button (click)="setFetcher(0)">
            <ion-label>{{ statuses[0] }}</ion-label>
            <ion-badge slot="end">{{ stat0 }}</ion-badge>
        </ion-item>
        <ion-item button (click)="setFetcher(1)">
            <ion-label>{{ statuses[1] }}</ion-label>
            <ion-badge slot="end">{{ stat1 }}</ion-badge>
        </ion-item>
        <ion-item button (click)="setFetcher(2)">
            <ion-label>{{ statuses[2] }}</ion-label>
            <ion-badge slot="end">{{ stat2 }}</ion-badge>
        </ion-item>
        <ion-item button (click)="setFetcher(3)">
            <ion-label>{{ statuses[3] }}</ion-label>
            <ion-badge slot="end">{{ stat3 }}</ion-badge>
        </ion-item>
        <ion-item button (click)="setFetcher(8)">
            <ion-label>{{ statuses[8] }}</ion-label>
            <ion-badge slot="end">{{ stat8 }}</ion-badge>
        </ion-item>
        <ion-item button (click)="setFetcher(4)">
            <ion-label>{{ statuses[4] }}</ion-label>
            <ion-badge slot="end">{{ stat4 }}</ion-badge>
        </ion-item>
        <ion-item button (click)="setFetcher(5)">
            <ion-label>{{ statuses[5] }}</ion-label>
            <ion-badge slot="end">{{ stat5 }}</ion-badge>
        </ion-item>
        <ion-item button (click)="setFetcher(6)">
            <ion-label>{{ statuses[6] }}</ion-label>
            <ion-badge slot="end">{{ stat6 }}</ion-badge>
        </ion-item>
        <ion-item button (click)="setFetcher(7)">
            <ion-label>{{ statuses[7] }}</ion-label>
            <ion-badge slot="end">{{ stat7 }}</ion-badge>
        </ion-item>
        <ion-item button (click)="setFetcher(99)">
            <ion-label>All</ion-label>
            <ion-badge slot="end">{{ statAll }}</ion-badge>
        </ion-item>
    </ion-list>

    <ion-item class="search-by-uid">
        <ion-label>Search by user ID :</ion-label>
        <ion-input [(ngModel)]="userId" type="text"></ion-input>
        <button (click)="setFetcherByUserId(userId)">SEARCH</button>
    </ion-item>

    <h2>Trips</h2>
    <ion-button (click)="export_profiles()" class="ion-float-right"
    >Export All Profiles
    </ion-button
    >
    <ion-button (click)="export()" class="ion-float-right"
    >Export Trips
    </ion-button
    >
    <div class="long-table">
        <ion-grid>
            <ion-row class="header">
                <ion-col (click)="sortString('value.departure_date')"
                >Departure Date
                </ion-col
                >
                <ion-col (click)="sortString('value.name')"><b>Trip name</b></ion-col>
                <ion-col (click)="sortString('key')">Trip Id</ion-col>
                <ion-col (click)="sortString('value.profile.email')"
                >Email address
                </ion-col
                >
                <ion-col (click)="sortString('value.user')">User Id</ion-col>
                <ion-col (click)="sortString('value.profile.first_name')"
                >First Name
                </ion-col
                >
                <ion-col (click)="sortString('value.profile.last_name')"
                >Last Name
                </ion-col
                >

                <!-- <ion-col (click)="sortString('value.profile.dob')"
                  >Date of birth</ion-col
                >
                <ion-col (click)="sortString('value.profile.nationality')"
                  >Nationality</ion-col
                >
                <ion-col (click)="sortString('value.profile.address1')"
                  >Address</ion-col
                >
                <ion-col (click)="sortString('value.profile.zip')">Postcode</ion-col>
                <ion-col (click)="sortString('value.profile.city')">City</ion-col>
                <ion-col (click)="sortString('value.profile.country')">Country</ion-col>
                <ion-col (click)="sortString('value.profile.phone')"
                  >Phone Number</ion-col
                >
                <ion-col (click)="sortString('value.profile.passport_number')"
                  >Passport Number</ion-col
                >
                <ion-col (click)="sortString('value.profile.passport_expiration')"
                  >Date of passport Expiry</ion-col
                >
                <ion-col (click)="sortString('value.profile.residency_expiration')"
                  >Residency certificate date of expiry</ion-col
                >
                <ion-col (click)="sortString('value.profile.created_ts')"
                  >Creation profile date</ion-col
                >
                <ion-col (click)="sortString('value.profile.lastlogin_ts')"
                  >Last login</ion-col
                > -->
                <!-- <ion-col (click)="sortString('value.profile.passportReady')"
                  >Passport: box checked if uploaded</ion-col
                >
                <ion-col (click)="sortString('value.profile.passportReady_ts')"
                  >Date of passport upload</ion-col
                >
                <ion-col (click)="sortString('value.profile.residencyReady')"
                  >Residency certificate: box checked if upload</ion-col
                >
                <ion-col (click)="sortString('value.profile.residencyReady_ts')"
                  >Date of residency certificate upload</ion-col
                >
                <ion-col (click)="sortString('value.profile.nationality')"
                  >Bank Details</ion-col
                >
                <ion-col (click)="sortString('value.name')"><b>Trip name</b></ion-col>
                <ion-col (click)="sortString('value.arrival_country')"
                  >Arrival Country</ion-col
                >
                <ion-col (click)="sortString('value.arrival_date')"
                  >Arrival Date</ion-col
                > -->
                <!-- <ion-col (click)="sortString('value.departure_country')"
                  >Departure Country</ion-col
                > -->

                <!-- <ion-col (click)="sortString('value.residence_country')"
                  >Residence Country</ion-col
                >
                <ion-col (click)="sortString('value.created_at')">Created At</ion-col> -->
                <ion-col (click)="sortString('value.status')">Trip Status</ion-col>
                <!-- <ion-col (click)="sortString('value.invoices')"
                  >Total VAT to be refund</ion-col
                > -->
                <ion-col (click)="sortString('value.taxform')"
                >Tax Free Form: box checked if uploaded
                </ion-col
                >
                <!-- <ion-col (click)="sortString('value.taxform.date')"
                  >Date of Tax free form uploaded</ion-col
                > -->
                <ion-col (click)="sortString('value.taxform.docId')"
                >Tax free Form Number
                </ion-col
                >
                <ion-col (click)="sortString('value.taxform.vatAmount')"
                >Total VAT Amount
                </ion-col
                >
                <ion-col (click)="sortString('value.taxform.totalTTC')"
                >Total TTC Amount
                </ion-col
                >
                <ion-col (click)="sortString('value.taxform.dtaxAmount')"
                >Total Refunded VAT
                </ion-col
                >
                <ion-col (click)="sortString('value.taxform.refundDate')"
                >Refund Date
                </ion-col
                >
            </ion-row>
            <ion-row
                    *ngFor="let item of list;"
                    (click)="presentModal(item.value.user, item.key)"
            >
                <ion-col
                >{{ item.value.departure_date | amDateFormat: 'MMM. Do YYYY'
                    }}</ion-col
                >
                <ion-col><b>{{ item.value.name }}</b></ion-col>
                <ion-col>{{ item.key }}</ion-col>
                <ion-col>{{ item.value.profile.email }}</ion-col>
                <ion-col>{{ item.value.user }}</ion-col>
                <ion-col>{{ item.value.profile.first_name }}</ion-col>
                <ion-col>{{ item.value.profile.last_name }}</ion-col>
                <!-- <ion-col
                  >{{ item.value.profile.dob | amDateFormat: 'MMM. Do YYYY' }}</ion-col
                >
                <ion-col>{{ item.value.profile.nationality }}</ion-col>
                <ion-col
                  >{{ item.value.profile.address1 }}<br />{{ item.value.profile.address2
                  }}</ion-col
                >
                <ion-col>{{ item.value.profile.zip }}</ion-col>
                <ion-col>{{ item.value.profile.city }}</ion-col>
                <ion-col>{{ item.value.profile.country }}</ion-col>
                <ion-col>{{ item.value.profile.phone }}</ion-col>
                <ion-col>{{ item.value.profile.passport_number }}</ion-col>
                <ion-col
                  >{{ item.value.profile.passport_expiration | amDateFormat: 'MMM. Do
                  YYYY' }}</ion-col
                >
                <ion-col
                  >{{ item.value.profile.residency_expiration | amDateFormat: 'MMM. Do
                  YYYY' }}</ion-col
                >
                <ion-col
                  >{{ item.value.profile.created_ts | amDateFormat: 'MMM. Do YYYY'
                  }}</ion-col
                >
                <ion-col
                  >{{ item.value.profile.lastlogin_ts | amDateFormat: 'MMM. Do YYYY'
                  }}</ion-col
                >
                <ion-col
                  ><ion-icon
                    name="checkbox-outline"
                    *ngIf="!!item.value.profile.passportReady"
                  ></ion-icon
                ></ion-col>
                <ion-col
                  >{{ item.value.profile.passportReady_ts | amDateFormat: 'MMM. Do YYYY'
                  }}</ion-col
                >
                <ion-col
                  ><ion-icon
                    name="checkbox-outline"
                    *ngIf="!!item.value.profile.residencyReady"
                  ></ion-icon
                ></ion-col>
                <ion-col
                  >{{ item.value.profile.residencyReady_ts | amDateFormat: 'MMM. Do
                  YYYY'}}</ion-col
                >
                <ion-col>
                  <div *ngIf="item.value.profile.bank">
                    <b>{{ item.value.profile.bank.country }}</b><br />
                    {{ item.value.profile.bank.holder }}<br />
                    {{ item.value.profile.bank.iban }}<br />
                    {{ item.value.profile.bank.swift }}<br />
                  </div>
                </ion-col>
                <ion-col><b>{{ item.value.name }}</b></ion-col>
                <ion-col>{{ item.value.arrival_country }}</ion-col>
                <ion-col
                  >{{ item.value.arrival_date | amDateFormat: 'MMM. Do YYYY' }}</ion-col
                >
                <ion-col>{{ item.value.departure_country }}</ion-col> -->

                <!-- <ion-col>{{ item.value.residence_country }}</ion-col>
                <ion-col
                  >{{ item.value.created_at | amDateFormat: 'MMM. Do YYYY' }}</ion-col
                > -->
                <ion-col>{{ statuses[item.value.status] }}</ion-col>
                <!-- <ion-col
                  >{{ calcTotalRefundable(item.value.invoices | keyvalue) }}€</ion-col
                > -->
                <ion-col
                >
                    <ion-icon
                            name="checkbox-outline"
                            *ngIf="item.value.taxform && item.value.taxform.id"
                    ></ion-icon
                    >
                </ion-col>
                <!-- <ion-col>
                  <div *ngIf="item.value.taxform">
                    {{ item.value.taxform.date | amDateFormat: 'MMM. Do YYYY'}}
                  </div>
                </ion-col> -->
                <ion-col>
                    <div *ngIf="item.value.taxform && item.value.taxform.docId">
                        {{ item.value.taxform.docId }}
                    </div>
                </ion-col>
                <ion-col>
                    <div *ngIf="item.value.taxform && item.value.taxform.docId">
                        {{ item.value.taxform.vatAmount }}
                    </div>
                </ion-col>
                <ion-col>
                    <div *ngIf="item.value.taxform && item.value.taxform.docId">
                        {{ item.value.taxform.totalTTC }}
                    </div>
                </ion-col>
                <ion-col>
                    <div *ngIf="item.value.taxform && item.value.taxform.docId">
                        {{ item.value.taxform.dtaxAmount }}
                    </div>
                </ion-col>
                <ion-col>
                    <div *ngIf="item.value.taxform && item.value.taxform.docId">
                        {{ item.value.taxform.refundDate }}
                    </div>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
</ion-content>
