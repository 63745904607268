import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {Routes, RouterModule} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {ListPage} from './list.page';
import {MomentModule} from 'ngx-moment';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatRadioModule} from '@angular/material/radio';
import {AdminPageModule} from "../admin.module";
import {ViewPageModule} from "../view/view.module";
import {ViewPage} from "../view/view.page";
import {ProfileViewComponent} from "../view/profile-view/profile-view.component";

const routes: Routes = [
    {
        path: '',
        component: ListPage
    },
    {path: 'view/:user/:trip', component: ViewPage},
    {path: 'view/:user', component: ProfileViewComponent},
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        MomentModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        MatRadioModule,
    ],
    declarations: [ListPage]
})
export class ListPageModule {
}
