import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {LoadingController, ModalController} from '@ionic/angular';
import {environment} from '../../../../environments/environment';

import * as JSZip from 'jszip';
import * as JSZipUtils from 'jszip-utils';
import {saveAs} from 'file-saver';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {Trip} from '../../../../models/trip';
import {ShopInvoice, StatusCompta} from '../../../../models/shop_invoice';
import {Profile} from '../../../../models/profile';
import {NgForm} from '@angular/forms';
import {nanoid} from 'nanoid';
import status_qbo from '../../../../assets/status_qbo.json';
import {MatDialog} from '@angular/material/dialog';
import {CustomsStubComponent} from '../customs-stub/customs-stub.component';
import {firstValueFrom, Subscription} from 'rxjs';
import {ConfirmComponent} from './confirm/confirm.component';
import {finalize} from 'rxjs/operators';
import countriesResidency from '../../../../assets/countries-residency.json';
import countriesNationality from '../../../../assets/countries-nationality.json';
import residencyDocumentType from '../../../../assets/residency_doc_type.json';
import {InvoiceItemAddComponent} from './invoice-items/invoice-item-add/invoice-item-add.component';
import {IntroducerService} from '../../../services/introducer/introducer.service';
import Decimal from 'decimal.js';
import {PassportFile} from '../../../../models/passport_file';
import {IntroducerTrip} from 'src/models/marketers';
import {Photo} from '../../../../models/photo';
import {ErrorDialogComponent} from 'src/app/widgets/error-dialog/error-dialog.component';
import {grade_rates} from 'src/models/constants';

const moment = require('moment-timezone');

const lookup = require('country-code-lookup');


@Component({
    selector: 'app-view',
    templateUrl: './view.page.html',
    styleUrls: ['./view.page.scss'],
})
export class ViewPage implements OnInit, OnDestroy {
    @Input() user: string;
    @Input() trip: string;
    @ViewChild('file2') fileInput: ElementRef;
    @ViewChildren('invoiceItemAdd') invoiceItemAdd: QueryList<InvoiceItemAddComponent>;

    currencies = ['AUD', 'AED', 'BGN', 'BRL', 'CAD', 'CHF', 'CZK', 'DKK', 'EUR', 'GBP', 'HKD', 'HUF', 'IDR', 'ILS', 'INR', 'JPY', 'MYR', 'NOK', 'NZD', 'PLN', 'RON', 'SEK', 'SGD', 'TRY', 'USD'];
    public statusQboList: { start: any; wait: any; finish: any } = status_qbo;

    statuses = environment.statuses;
    // prodStatus = environment.production;
    anomalyId: number;

    residencyMaxDate = moment().add(20, 'years').toISOString();

    file;

    ref: Subscription;
    refP: Subscription;
    refM: Subscription;
    refAdmin: Subscription;
    canRefreshRef: Subscription;
    refIntroducer: Subscription;

    canRefreshPablo = false;
    item: Trip;
    profile: Profile;
    statusCompta: StatusCompta;
    meta: any;
    admin: any = {};
    introducerTrip: IntroducerTrip;

    imagePath;
    loading;

    passportImages = {};
    residencyImages = {};

    docId: string;
    vatAmount: string;
    totalTTC: string;
    dtaxAmount: string;
    refundDate: string;

    residencyCountryList = countriesResidency;
    nationalityCountryList = countriesNationality;
    documentTypeList = residencyDocumentType;


    sortedInvoices: [string, ShopInvoice][] = [];
    newInvoices: Map<string, ShopInvoice> = new Map<string, ShopInvoice>();

    qboClicked = false;
    qontoLoading = false;
    wiseLoading = false;

    pabloMode: 'Pablo-O' | 'Pablo-I' = 'Pablo-O';

    commission_amount: number;
    estimated_gov_refund_date: number | string;
    estimated_comp_refund_date: number | string;

    private invoiceSelected: number;

    constructor(
        private route: ActivatedRoute,
        private modalCtrl: ModalController,
        private afDb: AngularFireDatabase,
        private loadingController: LoadingController,
        private afStorage: AngularFireStorage,
        private fns: AngularFireFunctions,
        private stubDialog: MatDialog,
        private confirmDialog: MatDialog,
        private errorDialog: MatDialog,
        public introducerService: IntroducerService
    ) {
        // this.fns.useEmulator("localhost",5001);
        // this.afDb.database.useEmulator("localhost", 9050);
    }

    urlToPromise(url) {
        console.log('Url : ' + url);
        let promise = (window as any).Promise;
        if (!promise) {
            promise = JSZip.external.Promise;
        }
        return new promise((resolve, reject) => {
            JSZipUtils.getBinaryContent(url, (err, data) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(data);
                }
            });
        });
    }

    dateFormat(date: string) {
        const splitDate = date.split('-');
        return splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0];
    }

    ngOnDestroy(): void {
        this.ref.unsubscribe();
        this.refP.unsubscribe();
        this.refM.unsubscribe();
        this.refAdmin.unsubscribe();
        this.canRefreshRef.unsubscribe();
        this.refIntroducer?.unsubscribe();
    }


    async ngOnInit() {
        if (!this.user && !this.trip) {
            await this.introducerService.getIntroducers();
            this.route.paramMap.subscribe((params) => {
                this.user = params.get('user');
                this.trip = params.get('trip');
            });
        }

        this.item = {};

        // eslint-disable-next-line @typescript-eslint/no-shadow
        this.route.paramMap.subscribe(() => {
            const itemsRef = this.afDb.object(
                'users/' + this.user + '/trips/' + this.trip
            );
            this.canRefreshRef = this.afDb.object('customs/imap/ack/status').snapshotChanges().subscribe(
                {
                    next: (result) => {
                        const status = result.payload.val();
                        this.canRefreshPablo = status !== 'pending';
                    }
                }
            );
            this.ref = itemsRef.snapshotChanges().subscribe({
                next: (results) => {
                    this.item = results.payload.val();
                    // clone this.statusCompta
                    this.statusCompta = {...this.item.status_compta};
                    console.log('Record-item', results.payload.val());
                    this.sortedInvoices = this.sortInvoicesByDate();
                    if (this.item && this.item.taxform && this.item.taxform.docId) {
                        this.docId = this.item.taxform.docId;
                        this.vatAmount = this.item.taxform.vatAmount;
                        this.totalTTC = this.item.taxform.totalTTC;
                        this.dtaxAmount = this.item.taxform.dtaxAmount;
                        console.log(this.sortedInvoices);
                        const taxformRfDate = this.item.taxform.refundDate;
                        if (taxformRfDate && !taxformRfDate.startsWith('-0001')) {
                            const refundDate = new Date(taxformRfDate);
                            const refundDateISO = refundDate.toISOString();
                            this.refundDate = refundDateISO.substring(0, 10);
                        } else {
                            this.refundDate = new Date().toISOString().substring(0, 10);
                        }
                    }
                },
                error: (err) => {
                    console.log(err);
                }
            });

            const itemsRefProf = this.afDb.object('users/' + this.user + '/profile');
            this.refP = itemsRefProf.snapshotChanges().subscribe(
                (results) => {
                    this.profile = results.payload.val();
                    console.log('Record-profile', results.payload.val());
                    this.passportImages = this.profile.passportPhotos || {};
                    this.residencyImages = this.profile.residencyPhotos || {};
                    this.bankProfile();
                },
                (err) => {
                    console.log(err);
                }
            );
            const itemsRefMeta = this.afDb.object('users/' + this.user + '/meta');
            this.refM = itemsRefMeta.snapshotChanges().subscribe((results) => {
                this.meta = results.payload.val();
                if (this.meta?.referral?.referrer_type === 'marketer') {
                    this.refIntroducer = this
                        .introducerService
                        .listenIntroducerTripDetail(this.meta?.referral?.id_referrer, this.user, this.trip)
                        .subscribe({
                            next: (result) => {
                                this.introducerTrip = result.payload.val();
                                this.commission_amount = this.introducerTrip?.commission_amount || 0;
                                this.estimated_gov_refund_date = moment.unix(this.introducerTrip?.estimated_gov_refund_date / 1000 || 0).format('YYYY-MM-DD');
                                this.estimated_comp_refund_date = moment.unix(this.introducerTrip?.estimated_comp_refund_date / 1000 || 0).format('YYYY-MM-DD');
                            }
                        });
                }
                console.log('Record-meta', results.payload.val());
            });

            const itemsRefAdmin = this.afDb.object('users/' + this.user + '/admin');
            this.refAdmin = itemsRefAdmin.snapshotChanges().subscribe({
                next: (results) => {
                    this.admin = results.payload.val();
                    console.log('Record-admin', results.payload.val());
                }
            });

        });
    }

    deleteTrip() {
        console.log('delete Trip');
        this.confirmDialog.open(ConfirmComponent, {
            height: '16em',
            width: '30em',
            data: {
                tripId: this.trip,
                userId: this.user,
            },
        });
    }

    bankProfile() {
        if (this.profile.bank == null) {
            this.afDb
                .object('users/' + this.user + '/profile/')
                .update({bank: {iban: '', swift: '', currency: 'EUR'}});
        } else {
            if (!this.profile.bank.currency) {
                this.afDb
                    .object('users/' + this.user + '/profile/bank')
                    .update({currency: 'EUR'});
            }
        }
    }

    async close() {
        const modal = await this.modalCtrl.getTop();
        modal.dismiss();
    }


    async pennylaneCreateJournalBank() {
        const qCJB = this.fns.httpsCallable('compta-createJournalBank');
        qCJB({tripId: this.trip, reset: false, amount: +(this.item.taxform?.vatAmount || "0")})
            .toPromise()
            .then((result) => {
                console.log('creation journal bank');
            })
            .catch((err) => {
                this.errorDialog.open(ErrorDialogComponent, {
                    data: err
                });
                console.log('error creation journal bank');
            });
    }

    async qboCreation(invoiceVal: any, resume = false) {
        this.qboClicked = true;
        const totalTTC = this.getTripAmount('ttc');
        const totalHT = this.getTripAmount('ht');
        const qC = this.fns.httpsCallable('qbo-qboCreation');
        qC({
            user: this.user,
            trip: this.trip,
            invoices: invoiceVal,
            totalTTC,
            totalHT,
            resume,
        })
            .toPromise()
            .then(() => {
                console.log('end');
            })
            .catch((error) => {
                console.error(error);
            });
    }

    async qboCreateJournalBank(form: any) {
        const montantTVA = form.value.montant_tva;
        const qCJB = this.fns.httpsCallable('qbo-createJournalBank');
        qCJB({user: this.user, trip: this.trip, montantTVA})
            .toPromise()
            .then(() => {
                console.log('creation journal bank');
            })
            .catch(() => {
                console.log('error creation journal bank');
            });
    }

    async addProfileImage(files, path) {
        if (files.length === 0) {
            return;
        }
        const file = files[0];
        if (file) {
            if (!/^image\//i.test(file.type)) {
                return;
            }
        }
        this.imagePath = files;
        this.loading = await this.loadingController.create({
            message: 'Uploading image...',
        });
        await this.loading.present();
        const photoId = (+new Date()).toString(12);
        const filePath = this.user + '/' + photoId + '.jpg';
        const ref = this.afStorage.ref(filePath);
        this.afStorage.upload(filePath, file).then(() => {
            ref
                .getDownloadURL()
                .toPromise()
                .then((res) => {
                    this.afDb
                        .object('users/' + this.user + '/profile/' + path + '/' + photoId)
                        .update({id: photoId, uri: res, date: new Date()});
                    this.loading.dismiss();
                });
        });
    }

    removeProfileImage(image, path) {
        this.afDb
            .object('users/' + this.user + '/profile/' + path + '/' + image.id)
            .remove();
        this.afStorage.refFromURL(image.uri).delete();
    }

    addImage(event: Event, type: string) {
        const inputElement = event.target as HTMLInputElement;
        if (inputElement.size === 0) {
            return;
        }
        const file: File = inputElement.files[0];
        switch (type) {
            case 'OI':
                this.addManualOI(file);
                break;
            case 'TX':
                this.addTaxfree(file);
                break;
            case 'STX':
                this.addStampedTaxFree(file);
                break;
        }
    }

    async addOverallInvoice() {
        console.log('addAutomaticOverallInvoice');
        this.loading = await this.loadingController.create({
            message: 'Uploading Overall Invoice',
            // duration: 10000
        });
        await this.loading.present();
        const callable = this.fns.httpsCallable('invoiceGen-generateInvoice');
        const cloudPath = await callable({tripId: this.trip}).toPromise();
        console.log(cloudPath);
        this.loading.dismiss();
    }

    async addManualOI(value) {
        {
            console.log('addManualOI', value);
            this.loading = await this.loadingController.create({
                message: 'Uploading OI Form',
                // duration: 10000
            });
            await this.loading.present();
            const photoId = `OI-${(+new Date()).toString(12)}`;
            const filePath = this.user + '/' + this.trip + '/' + photoId + '.pdf';
            const ref = this.afStorage.ref(filePath);
            const task = this.afStorage.upload(filePath, value);
            return new Promise<any>((resolve, reject) => {
                task
                    .snapshotChanges()
                    .pipe(
                        finalize(async () => {
                            ref.getDownloadURL().subscribe(
                                (res) => {
                                    resolve(res);
                                    this.afDb
                                        .object(
                                            'users/' +
                                            this.user +
                                            '/trips/' +
                                            this.trip +
                                            '/overall_invoice'
                                        )
                                        .update({id: photoId, uri: res, date: new Date()});
                                    this.afDb
                                        .object('trips/' + this.trip + '/overall_invoice')
                                        .update({id: photoId, uri: res, date: new Date()});
                                },
                                (err) => reject(err)
                            );
                        })
                    )
                    .subscribe();
                this.loading.dismiss();
            });
        }
    }

    async addTaxfree(value: File) {
        this.loading = await this.loadingController.create({
            message: 'Uploading Taxfree Form',
            // duration: 10000
        });
        await this.loading.present();
        const photoId = `TX-${(+new Date()).toString(12)}`;
        const filePath = this.user + '/' + this.trip + '/' + photoId + '.pdf';
        const ref = this.afStorage.ref(filePath);
        const task = this.afStorage.upload(filePath, value);
        return new Promise<any>((resolve, reject) => {
            task
                .snapshotChanges()
                .pipe(
                    finalize(async () => {
                        ref.getDownloadURL().subscribe(
                            (res) => {
                                resolve(res);
                                this.afDb
                                    .object(
                                        'users/' + this.user + '/trips/' + this.trip + '/taxform'
                                    )
                                    .update({id: photoId, uri: res, date: new Date()});
                                this.afDb
                                    .object('trips/' + this.trip + '/taxform')
                                    .update({id: photoId, uri: res, date: new Date()});
                            },
                            (err) => reject(err)
                        );
                    })
                )
                .subscribe();
            this.loading.dismiss();
        });
    }

    async addStampedTaxFree(value: File) {
        this.loading = await this.loadingController.create({
            message: 'Uploading Stamped Taxfree Form',
            // duration: 10000
        });
        await this.loading.present();
        const photoId = `STX-${(+new Date()).toString(12)}`;
        const filePath = this.user + '/' + this.trip + '/' + photoId + '.pdf';
        const ref = this.afStorage.ref(filePath);
        const task = this.afStorage.upload(filePath, value);
        return new Promise<any>((resolve, reject) => {
            task
                .snapshotChanges()
                .pipe(
                    finalize(async () => {
                        ref.getDownloadURL().subscribe(
                            (res) => {
                                resolve(res);
                                this.afDb
                                    .object(
                                        'users/' + this.user + '/trips/' + this.trip + '/taxform'
                                    )
                                    .update({
                                        stampedId: photoId,
                                        stampedUri: res,
                                        stampedDate: new Date(),
                                    });
                                this.afDb.object('trips/' + this.trip + '/taxform').update({
                                    stampedId: photoId,
                                    stampedUri: res,
                                    stampedDate: new Date(),
                                });
                            },
                            (err) => reject(err)
                        );
                    })
                )
                .subscribe();
            this.loading.dismiss();
        });
    }

    // setElectronicValidation = () => {
    //   this.electronicValidation = !this.electronicValidation;

    //   if (this.electronicValidation === true) {
    //     this.afDb
    //       .object("users/" + this.user + "/trips/" + this.trip + "/taxform")
    //       .update({
    //         electronicStamp: new Date(),
    //       });
    //     this.afDb.object("trips/" + this.trip + "/taxform").update({
    //       electronicStamp: new Date(),
    //     });
    //   } else {
    //     this.afDb
    //       .object("users/" + this.user + "/trips/" + this.trip + "/taxform")
    //       .update({
    //         electronicStamp: null,
    //       });
    //     this.afDb.object("trips/" + this.trip + "/taxform").update({
    //       electronicStamp: null,
    //     });
    //   }
    // };

    async proceedFurtherStatus() {
        await this.afDb
            .object('users/' + this.user + '/trips/' + this.trip + '')
            .update({status: 3});
    }

    async backStep() {
        const step = this.item.status;
        await this.afDb
            .object('users/' + this.user + '/trips/' + this.trip + '')
            .update({status: step - 1});
    }

    async nextStep() {
        const step = this.item.status;
        await this.afDb
            .object('users/' + this.user + '/trips/' + this.trip + '')
            .update({status: step + 1});
    }

    updateTaxformNumber(text = '') {
        this.afDb
            .object('users/' + this.user + '/trips/' + this.trip + '/taxform')
            .update({docId: text});
        this.afDb.object('trips/' + this.trip + '/taxform').update({docId: text});
    }

    updateVatAmount(text = '') {
        this.afDb
            .object('users/' + this.user + '/trips/' + this.trip + '/taxform')
            .update({vatAmount: text});
        this.afDb
            .object('trips/' + this.trip + '/taxform')
            .update({vatAmount: text});
    }

    updateTotalTTC(text = '') {
        this.afDb
            .object('users/' + this.user + '/trips/' + this.trip + '/taxform')
            .update({totalTTC: text});
        this.afDb
            .object('trips/' + this.trip + '/taxform')
            .update({totalTTC: text});
    }

    updateDTAXAmount(text = '') {
        this.afDb
            .object('users/' + this.user + '/trips/' + this.trip + '/taxform')
            .update({dtaxAmount: text});
        this.afDb
            .object('trips/' + this.trip + '/taxform')
            .update({dtaxAmount: text});
    }

    updateRefundDate(text = '') {
        this.afDb
            .object('users/' + this.user + '/trips/' + this.trip + '/taxform')
            .update({refundDate: text});
        this.afDb
            .object('trips/' + this.trip + '/taxform')
            .update({refundDate: text});
    }

    async setDone() {
        await this.afDb
            .object('users/' + this.user + '/trips/' + this.trip + '')
            .update({status: 5});
        if (this.meta?.referral?.referrer_type === 'marketer') {
            const default_rate = grade_rates[this.admin?.grade ?? 'standard'] ?? 72;
            const totalRefund = (+this.getTripAmount('tva')) * ((this.item.refund_rate ?? default_rate) / 100);
            await this.introducerService.onUserRefundDone({
                user: this.profile,
                userId: this.user,
                bve: this.item.taxform.docId,
                tripId: this.trip,
                introducer: this.meta.referral.id_referrer,
                refundAmount: totalRefund,
                commissionAmount: this.commission_amount,
                estimatedCompRefundDate: Date.parse(String(this.estimated_comp_refund_date)),
                estimatedGovRefundDate: Date.parse(String(this.estimated_gov_refund_date)),
            });
        }
    }

    async makePayment(bank: "wise" | "qonto") {
        const makePayment = this.fns.httpsCallable(`bank-${bank}-makePayment`);
        const estimatedRefund = +this.item.taxform.dtaxAmount;

        const default_rate = grade_rates[this.admin?.grade ?? 'standard'] ?? 72;
        const calculatedRefund = (+this.getTripAmount('tva')) * ((this.item.refund_rate ?? default_rate) / 100);

        if (!estimatedRefund && !confirm(`Couldn't retrieve saved refund amount from taxform, we calculated ${calculatedRefund.toFixed(2)}€ instead`)) {
            return;
        }
        if ((estimatedRefund ?? 0) > calculatedRefund + 1) {
            alert(`Mismatch between saved refund amount and calculated refund amount, we had ${estimatedRefund} and we calculated ${calculatedRefund}`);
            return;
        }
        return firstValueFrom(makePayment({tripId: this.trip}))
            .then(result => {
                if (result.success === false) {
                    this.errorDialog.open(ErrorDialogComponent, {
                        data: result
                    });
                } else {
                    alert("Payment done.")
                }
                console.log(result);
            })
            .catch((error) => {
                this.errorDialog.open(ErrorDialogComponent, {
                    data: error
                });
                console.error(error);
            })

    }

    async makePaymentWithQonto() {
        this.qontoLoading = true;
        await this.makePayment("qonto");
        this.qontoLoading = false;
    }

    async makePaymentWithWise() {
        this.wiseLoading = true;
        await this.makePayment("wise");
        this.wiseLoading = false;
    }


    async clearPayment() {
        if (!confirm("Are you sure to clear the payment ? Make payment button will be available again")) {
            return;
        }
        await this.afDb
            .object('users/' + this.user + '/trips/' + this.trip)
            .update({
                "taxform/payment_reference": null,
                "bank_sequence": null,
                "status_compta/bank_make_payment": "pending"
            });

    }

    setApproved() {
        this.afDb
            .object('users/' + this.user + '/trips/' + this.trip)
            .update({status: 4});
    }

    setBveErrorFalse() {
        this.afDb
            .object('users/' + this.user + '/trips/' + this.trip + '')
            .update({bve_error: false});
    }

    setBveErrorTrue() {
        this.afDb
            .object('users/' + this.user + '/trips/' + this.trip + '')
            .update({bve_error: true});
    }

    getInvoiceAmount(invoice: ShopInvoice) {
        let ttc = new Decimal(0);
        for (const item of Object.values(invoice.items ?? {})) {
            ttc = ttc.plus(item.amount ?? 0);
        }
        return ttc;
    }

    getInvoiceTVAAmount(invoice: ShopInvoice): Decimal {
        let tva = new Decimal(0);
        for (const item of Object.values(invoice.items ?? {})) {
            const quantity = 1 // item.quantity ?? 1;
            const itemUnitPrice = new Decimal(item.amount).dividedBy(new Decimal(quantity)).toDecimalPlaces(2, Decimal.ROUND_HALF_UP);
            const itemTaxRate = new Decimal(item.tax).dividedBy(100).toDecimalPlaces(4, Decimal.ROUND_HALF_UP);
            const unitHtAmount = itemUnitPrice.dividedBy(new Decimal(1).plus(itemTaxRate)).toDecimalPlaces(2, Decimal.ROUND_HALF_UP);
            const unitTvaAmount = itemUnitPrice.minus(unitHtAmount).toDecimalPlaces(2, Decimal.ROUND_HALF_UP);
            const tvaAmount = unitTvaAmount.times(new Decimal(quantity)).toDecimalPlaces(2, Decimal.ROUND_HALF_UP);
            tva = tva.plus(tvaAmount);
        }
        return tva;
    }

    getInvoiceAmountWithoutTax(invoice: ShopInvoice) {
        let total = new Decimal(0);
        for (const item of Object.values(invoice.items ?? {})) {
            const quantity = 1 // item.quantity ?? 1;
            const unitTTCAmount = new Decimal(item.amount ?? 0).dividedBy(new Decimal(quantity)).toDecimalPlaces(2, Decimal.ROUND_HALF_UP);
            const itemTaxRate = new Decimal(item.tax ?? 20).dividedBy(100).toDecimalPlaces(4, Decimal.ROUND_HALF_UP);
            const unitHtAmount = unitTTCAmount.dividedBy(new Decimal(1).plus(itemTaxRate)).toDecimalPlaces(2, Decimal.ROUND_HALF_UP);
            const unitTvaAmount = unitTTCAmount.minus(unitHtAmount).toDecimalPlaces(2, Decimal.ROUND_HALF_UP);
            const itemAmount = unitTTCAmount.times(new Decimal(quantity)).toDecimalPlaces(2, Decimal.ROUND_HALF_UP);
            const itemTva = unitTvaAmount.times(new Decimal(quantity)).toDecimalPlaces(2, Decimal.ROUND_HALF_UP);

            total = total.plus(itemAmount.minus(itemTva));
        }
        return total.toFixed(2);
    }

    getTripAmount(type: 'ht' | 'tva' | 'ttc') {
        let total = new Decimal(0);
        for (const value of Object.values(this.item.shop_invoices ?? {})) {
            // eslint-disable-next-line radix
            if (value.approved !== 'anomaly') {
                if (type === 'tva') {
                    total = total.add(this.getInvoiceTVAAmount(value));
                } else if (type === 'ht') {
                    total = total.add(this.getInvoiceAmountWithoutTax(value));
                } else {
                    total = total.add(this.getInvoiceAmount(value));
                }
            }
        }
        return total.toFixed(2);
    }

    checkIfNationalityIsEU(nationality) {
        for (const key in countriesNationality) {
            if (nationality === countriesNationality[key].code) {
                return countriesNationality[key].isEU === 'true';
            }
        }
        return false;
    }

    editedDocumentType: string;
    isEditing = false;

    toggleEditMode() {
        console.log(this.isEditing);
        this.isEditing = !this.isEditing;
        this.editedDocumentType = this.profile.residencyDocumentType;
    }

    isInDocumentTypeList(doc: string, docTypeList: any[]): boolean {
        if (doc == null) {
            return true;
        }
        return docTypeList.some(document => document.name === doc);
    }

    setResidencyValid() {
        this.afDb
            .object('users/' + this.user + '/profile/')
            .update({residencyState: 'valid'});
    }

    setResidencyPending() {
        this.afDb
            .object('users/' + this.user + '/profile/')
            .update({residencyState: 'pending'});
    }

    setResidencyRejected() {
        this.afDb
            .object('users/' + this.user + '/profile/')
            .update({residencyState: 'rejected'});
    }


    updateProfile(profileId, profileVal) {
        console.log(profileVal);
        this.afDb
            .object('users/' + this.user + '/')
            .update({[profileId]: profileVal});
    }

    getCountry(iso) {
        try {
            return lookup.byIso(iso)?.country;
        } catch (e) {
            return '';
        }
    }

    selectInvoice(index) {
        if (this.invoiceSelected === index) {
            this.invoiceSelected = -1;
        } else {
            this.invoiceSelected = index;
        }
    }

    getInvoiceSelected() {
        return this.invoiceSelected;
    }

    sortInvoicesByDate(): [string, ShopInvoice][] {
        const invoiceArray = Object.entries(this.item.shop_invoices ?? {});
        console.log('invoiceArray', invoiceArray);
        invoiceArray.sort((a, b) => (a[1].upload_ms_timestamp ?? 0) - (b[1].upload_ms_timestamp ?? 0));
        return invoiceArray;
    }

    async addNewInvoice() {
        if (this.item.temp_shop_invoices) {
            this.invoiceSelected = this.shopInvoicesLength;
        } else {
            await this.afDb.object('users/' + this.user + '/trips/' + this.trip + '/temp_shop_invoices/' + nanoid()).set({
                upload_ms_timestamp: Date.now(),
                approved: 'valid',
            });
        }
    }

    async createBve(tripId: string, userId: string) {
        const invoices = this.item.shop_invoices;
        let checks = {
            invoices_have_vat_number: true,
            invoices_vat_are_valid: true,
            invoices_are_not_pending: true
        }
        const vats: string[] = []
        for (const invoice of Object.values(invoices)) {
            if (invoice.approved === 'pending') {
                checks.invoices_are_not_pending = false;
            } else if (invoice.approved === 'valid') {
                if (invoice.vatNumber == null || invoice.vatNumber === '') {
                    checks.invoices_have_vat_number = false;
                } else {
                    vats.push(invoice.vatNumber);
                }
            }
        }

        if (!checks.invoices_are_not_pending) {
            alert("One or more invoices are still pending, please check them before creating the taxform");
            return;
        }

        if (!checks.invoices_have_vat_number && !confirm("One or more invoices don't have a VAT number, are you sure to continue?")) {
            return;
        }

        const checkVat = this.fns.httpsCallable("compta-bulkCheckVAT");

        const loading = await this.loadingController.create({
            message: "Checking VATs validity...",
        });
        await loading.present();
        const results = await firstValueFrom(checkVat({vats}));
        console.log(results);
        const hasInvalidVat = results.some(result =>
            Object.values(result).some(value => value === false));
        await loading.dismiss();
        if (hasInvalidVat && !confirm("One or more VAT numbers are invalid, are you sure to continue?")) {
            return;
        }
        if (confirm('Are you sure to send BVE/OI creation email to customs?')) {
            if (!this.item.refund_rate) {
                await this.afDb.object(`users/${this.user}/trips/${this.trip}/refund_rate`).set(grade_rates[this.admin?.grade ?? 'standard'] ?? 72);
            }
            const callable = this.fns.httpsCallable('edi-sendMailDouanes');
            const data = callable({type: 'generation', tripId, userId}).toPromise();
            data
                .then(() => {
                    console.log('Create bve frame asked');
                })
                .catch((reason) => {
                    console.log('error: ' + reason);
                });
        }
    }

    async removeBVE(
        tripId: string,
        userId: string,
        bveId: string,
        status: number
    ) {
        if (
            (status === 2 || status === 3) &&
            confirm('Are you sure to send BVE/OI deletion email to customs?')
        ) {
            const callable = this.fns.httpsCallable('edi-sendMailDouanes');
            const data = callable({
                type: 'suppression',
                tripId,
                userId,
                bveId,
                cause: 'Modification facture',
            }).toPromise();
            data
                .then(() => {
                    console.log('Delete bve frame asked');
                })
                .catch((reason) => {
                    console.log('error: ' + reason);
                });
        }
    }

    canShowCreateBve() {
        const item = this.item;
        const isGoodStatus = item.status === 2;
        const customsTreating = item.customs?.currentStatus?.type === 'bve_creation'
            && ['pending', 'received_ack'].includes(item.customs?.currentStatus?.status);
        const hasTaxform = item.taxform?.uri && item.overall_invoice?.uri;
        return isGoodStatus && !customsTreating && !hasTaxform;
    }

    canShowDeleteBve() {
        const isGoodStatus = [2, 3].includes(this.item.status);
        const customsTreating = this.item.customs?.currentStatus?.type === 'bve_creation';
        return isGoodStatus && !customsTreating;
    }

    sendStub() {
        this.stubDialog.open(CustomsStubComponent, {
            height: '30em',
            width: '40em',
            data: {
                tripId: this.trip,
                userId: this.user,
            },
        });
    }

    toIsoDate(date: Date) {
        return `${moment(date)
            .tz('Europe/Paris')
            .format('YYYY-MM-DDTHH:mm:ss.SSSZ')}`;
    }

    round(value: number, precision: number = 2) {
        const multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }

    downloadBveTrip() {
        const items = [];
        Object.values(this.item.shop_invoices).forEach((invoice) => {
            if (invoice.approved === 'valid') {
                Object.values(invoice.items).forEach((item) => {
                    const itm = item as any;
                    items.push({
                        category: itm.categorie.id,
                        desc: itm.description,
                        quantity: itm.quantity,
                        tvaRate: itm.tax,
                        ttcPrice: this.round(itm.amount / itm.quantity),
                        serialNumber: itm.num_serie,
                        brand: itm.brand,
                    });
                });
            }
        });
        const exportData = {
            operatorEmail: 'hello@dtaxback.com',
            firstName: this.profile.first_name,
            lastName: this.profile.last_name,
            dob: this.profile.dob.replace(/\//g, ''),
            nationality: this.profile.nationality,
            passportNumber: this.profile.passport_number,
            passportExpiry: this.profile.passport_expiration.replace(/\//g, ''),
            email: this.profile.email,
            residencyCountry: this.profile.country,
            residencyAddress: this.profile.country,
            items,
        };
        return saveAs(
            // eslint-disable-next-line max-len
            new Blob([JSON.stringify(exportData, null, 2)], {type: 'JSON'}),
            `bve-${this.profile.first_name}_${this.profile.last_name}-${this.item.departure_country}_${this.item.departure_date}.json`
        );
    }

    removeCredit(creditForm: NgForm) {
        const amount = creditForm.value.amount;
        if (amount) {
            if (
                confirm(
                    `Are you sure you want to remove ${amount}€ of credit? This action can\'t be undone.`
                )
            ) {
                const qty = this.round(amount / 5);
                // eslint-disable-next-line max-len
                this.afDb
                    .object(`users/${this.user}/meta/referral/referred_success_count`)
                    .query.ref.transaction((count) => this.round(count - qty));
            }
        }
    }

    setRefundRate(rate: number) {
        if (Number.isInteger(+rate) && +rate >= 0 && +rate <= 100) {
            this.afDb.object(`users/${this.user}/trips/${this.trip}/refund_rate`).set(+rate);
        } else {
            alert('Please enter a valid value between 0 and 100 without decimals.');
        }
    }

    uploadTaxFreeFormButtonClick() {
        console.log(this.fileInput);
        if (this.fileInput && this.fileInput.nativeElement) {
            this.fileInput.nativeElement.click();
        }
    }

    async download() {
        const loading = await this.loadingController.create({
            message: 'Packing ZIP archive',
            // duration: 10000
        });
        await loading.present();
        loading.message = 'Downloading profile.txt';
        const zipFile: JSZip = new JSZip();
        console.log('Downloading profile');
        const arrivalDate = this.dateFormat(
            new Date(this.item.arrival_date).toISOString().substring(0, 10)
        );
        const departureDate = this.dateFormat(
            new Date(this.item.departure_date).toISOString().substring(0, 10)
        );
        console.log('Downloading trip');
        const trip = zipFile.folder(
            'Trip From ' + arrivalDate + ' to ' + departureDate
        );
        console.log('Downloading invoices');
        const invoices = trip.folder('invoices');

        trip.file('Trip.txt', JSON.stringify({...this.item, ...(this.meta ?? {})}));

        console.log('Downloading passport');
        const filenamebase = `${this.profile.first_name} ${this.profile.last_name} - ${this.item.taxform?.docId ?? this.item.taxform?.id ?? 'UNKNOWN TAXFORM NUMBER'}`;

        const tryDownloadFile = async (zip: JSZip, fileName: string, uri: string) => {
            console.log(`Downloading: ${fileName} - ${uri}`);
            if (!uri) {
                return;
            }
            try {
                zip.file(
                    `${filenamebase} - ${fileName}.jpg`,
                    this.urlToPromise(uri),
                    {binary: true}
                );
            } catch (err) {
                console.log(err);
            }
        };
        const tryDownloadFiles = async (zip: JSZip, fileName: string, photos: Photo[]) => {
            for (const photo of photos) {
                await tryDownloadFile(zip, fileName, photo.uri);
            }
        };

        await tryDownloadFiles(trip, 'Passport', Object.values(this.profile.passportPhotos ?? {}));
        await tryDownloadFiles(trip, 'Residency', Object.values(this.profile.residencyPhotos ?? {}));

        console.log('Downloading shop invoices');
        try {
            for (const [key, value] of Object.entries(this.item.shop_invoices)) {
                console.log('...' + key);
                const invoice = value;
                for (const [idPhoto, photoValue] of Object.entries(invoice.fichiers)) {
                    const photo = photoValue as any;
                    invoices.file(
                        `${filenamebase} - ${value.shop_name} - ${idPhoto} - ${this.getInvoiceAmount(
                            invoice
                        )}.jpg`,
                        this.urlToPromise(photo.uri),
                        {binary: true}
                    );
                }
            }
        } catch (err) {
            console.log(err);
        }

        // Aproval
        await tryDownloadFile(trip, 'TFF', this.item.taxform?.uri);
        await tryDownloadFile(trip, 'STFF', this.item.taxform?.stampedUri);
        await tryDownloadFile(trip, 'OI', this.item.overall_invoice?.uri);

        console.log('Generating Zip...');
        zipFile.generateAsync({type: 'blob'}).then(async (content) => {
            console.log('Done');
            saveAs(
                content,
                (
                    `${this.profile.first_name} ${this.profile.last_name}.zip`
                )
            );
            await loading.dismiss();
        });
    }


    onNewInvoiceDelete(key: string) {
        this.newInvoices.delete(key);
    }

    onNewInvoiceAdd(key: string) {
        this.newInvoices.delete(key);
    }

    get shopInvoicesLength() {
        return Object.keys(this.item.shop_invoices ?? {}).length;
    }

    async refreshEDI() {
        const callable = this.fns.httpsCallable('edi-manualReadMailDouanes');
        const controller = await this.loadingController.create({
            message: 'Calling refresh...',
        });
        await controller.present();
        await callable({}).toPromise();
        // show loading :
        setTimeout(() => {
            controller.dismiss();
        }, 1000);
    }

    getPassportStatus() {
        const status = this.admin?.passportState || this.profile?.passportState;
        if (status === 'valid') {
            return 'valid';
        } else if (status === 'pending') {
            return 'pending';
        } else if (status === 'rejected') {
            return 'rejected';
        } else if (status === 'expired') {
            return 'expired';
        }
    }

    getLastPassportReport() {
        const reports = this.admin?.ariadnext?.dossiers as Record<string, PassportFile>;
        if (!reports) {
            return;
        }
        const reportsObject = Object.values(reports);
        reportsObject.sort((a, b) => b.created - a.created);
        return reportsObject[0];
    }

    downloadPassportReport() {
        const passportReport = this.getLastPassportReport();
        if (!passportReport) {
            alert('No passport report found');
            return;
        }
        const passportReportUrl = passportReport.reportUrl;
        if (!passportReportUrl) {
            alert('No passport report url found');
            return;
        }
        window.open(passportReportUrl, '_blank');
    }

    async updateIntroducerFee(commission_amount: number) {
        await this.introducerService.updateIntroducerTripDetail(this.meta?.referral?.id_referrer, this.user, this.trip, {
            commission_amount
        });
    }

    async updateEstimatedGovRefundDate(estimated_gov_refund_date: number) {
        await this.introducerService.updateIntroducerTripDetail(this.meta?.referral?.id_referrer, this.user, this.trip, {
            estimated_gov_refund_date
        });
    }

    async updateEstimatedCompRefundDate(estimated_comp_refund_date: number) {
        await this.introducerService.updateIntroducerTripDetail(this.meta?.referral?.id_referrer, this.user, this.trip, {
            estimated_comp_refund_date
        });
    }

    async redirectIdVerificationFlow() {
        const callable = this.fns.httpsCallable('ariadnext-createFlowOperator');
        const result = await firstValueFrom(callable({
            uid: this.user,
        }));
        console.log(result);
        window.open(result.data, '_blank');
    }

    getUserRefundRate() {
        return this.item.refund_rate ?? grade_rates[this.admin?.grade ?? 'standard'] ?? 72;
    }

    async onGradeChange($event: string) {
        await this.afDb.object(`users/${this.user}/admin/grade`).set($event);
        await this.afDb.object(`users/${this.user}/trips/${this.trip}/refund_rate`).set(grade_rates[$event]);
    }

    protected readonly environment = environment;

    async onDocumentTypeSelection($event: CustomEvent) {
        const docType = $event.detail.value;
        await this.afDb.object(`users/${this.user}/profile/residencyDocumentType`).set(docType);
    }

    async onResidencyCountrySelection($event: CustomEvent) {
        const residencyCountry = $event.detail.value;
        await this.afDb.object(`users/${this.user}/profile/residencyCountry`).set(residencyCountry);
    }

    async manualPayment() {
        if (!confirm("Are you sure to confirm that manual payment has been done ?")) {
            return;
        }
        await this.afDb
            .object('users/' + this.user + '/trips/' + this.trip + '/taxform/')
            .update({
                refundDate: this.refundDate,
                payment_reference: "MANUAL_PAYMENT"
            });
        await this.afDb.object(`users/${this.user}/trips/${this.trip}/status_compta/bank_make_payment`).set("valid");
    }

    saveSwift() {
        this.afDb.object(`users/${this.user}/profile/bank/swift`).set(this.profile.bank.swift);
    }

    saveCurrency() {
        this.afDb.object(`users/${this.user}/profile/bank/currency`).set(this.profile.bank.currency);
    }
}
