<ion-row (click)="onSelectInvoice()" class="row_style" tabindex="0">
    <ion-col class="invoice_align">
        <b>
            <ion-item (click)="$event.stopPropagation()">
                <ion-input [(ngModel)]="invoice.value.shop_name" placeholder="Shop name"></ion-input>
            </ion-item>
        </b>
    </ion-col>
    <ion-col class="invoice_align">
        <ion-item (click)="$event.stopPropagation()">
            <ion-input [(ngModel)]="invoice.value.invoice_number" placeholder="Invoice number"></ion-input>
        </ion-item>
    </ion-col>
    <ion-col class="invoice_align {{invoiceDateEmpty ? 'bg-red-400' : ''}}">
        <ion-item (click)="$event.stopPropagation()">
            <ion-datetime-button
                    [matTooltip]="invoice.value.date || 'no date found'"
                    datetime="{{invoice.key}}"
                    [color]="invoiceDateEmpty ? 'danger' : undefined"></ion-datetime-button>

            <ion-modal [keepContentsMounted]="true">
                <ng-template>
                    <ion-datetime
                            id="{{invoice.key}}"
                            presentation="date"
                            [min]="minInvoiceDate"
                            [max]="maxInvoiceDate"
                            [(ngModel)]="invoiceDate">

                    </ion-datetime>
                </ng-template>
            </ion-modal>
        </ion-item>
    </ion-col>
    <ion-col class="total_amount text-center">{{ getInvoiceAmountWithoutTax(invoice.value) }}</ion-col>
    <ion-col class="total_amount text-center">{{ getInvoiceTaxAmount(invoice.value) }}</ion-col>
    <ion-col class="total_amount text-center">{{ getInvoiceAmount(invoice.value) }}</ion-col>
    <ion-col class="invoice_align">
        <ion-item (click)="$event.stopPropagation()">
            <ion-input class="text-sm" [(ngModel)]="invoice.value.vatNumber" (ngModelChange)="onVATChange($event)"
                       placeholder="Vat number"></ion-input>
        </ion-item>
        <ion-button matTooltip="Check VAT" matTooltipPosition="below" color="light" (click)="checkVAT()"
                    size="small" *ngIf="invoice.value.vatNumber">Check VAT
        </ion-button>
    </ion-col>
    <ion-col class="invoice_align">
        <ion-item (click)="$event.stopPropagation()">
            <ion-input class="text-sm" [(ngModel)]="invoice.value.siret" (ngModelChange)="onSiretChange($event)"
                       placeholder="Siret"></ion-input>
        </ion-item>
        <ion-button matTooltip="Check Siret" matTooltipPosition="below" color="light" (click)="checkSiret()"
                    size="small" *ngIf="invoice.value.siret">Check Siret
        </ion-button>
    </ion-col>
    <ion-col class="invoice_align">
        <ion-item (click)="$event.stopPropagation()">
            <ion-icon *ngIf="invoice.value.approved === 'valid'" class="icon-status" color="success"
                      name="checkmark-circle-outline" size="large"></ion-icon>
            <ion-icon *ngIf="invoice.value.approved === 'anomaly'" class="icon-status" color="danger"
                      name="close-circle-outline" size="large"></ion-icon>
            <ion-icon *ngIf="invoice.value.approved === 'pending'" class="icon-status" color="warning"
                      name="time-outline" size="large"></ion-icon>
            <mat-select class="select_style" (openedChange)="onSelectChange($event)"
                        [(value)]="invoice.value.approved">
                <mat-option *ngFor="let option of approvedOptions" [value]="option.value">
                    {{ option.value.toUpperCase() }}
                </mat-option>
            </mat-select>
        </ion-item>
    </ion-col>
    <ion-col size="0.3" class="invoice_align" (click)="$event.stopPropagation()">
        <ion-button matTooltip="Save invoice (visible by user)" matTooltipPosition="above" color="light" [disabled]="!invoice.value.shop_name ||
                                    !invoice.value.invoice_number" (click)="onUpdateInvoice()"
                    class="trash-button-invoice" size="small">
            <ion-icon class="trash-size" name="save-outline" color="primary"></ion-icon>
        </ion-button>
        <ion-button matTooltip="Save to draft (invisible to user)" matTooltipPosition="left" color="light"
                    (click)="onDraftInvoice()" class="trash-button-invoice" size="small">
            <ion-icon class="trash-size" name="hammer-outline" color="warning"></ion-icon>
        </ion-button>
        <ion-button matTooltip="Delete invoice" matTooltipPosition="below" color="light" (click)="onDeleteInvoice()"
                    class="trash-button-invoice" size="small">
            <ion-icon class="trash-size" name="trash-outline" color="danger"></ion-icon>
        </ion-button>
    </ion-col>
</ion-row>
<ion-row *ngIf="selected" class="computer-profile-row">
    <div class="item-table">
        <ion-grid>
            <ion-row>
                <ion-col>
                    <ion-row size="12">
                        <app-file-upload [userId]="userId" [tripId]="tripId" class="file-upload" fileCategory="invoice"
                                         [invoice]="invoice.value" (fileDropped)="onFileDropped($event)"
                                         dragAndDropText="Drop invoices">
                        </app-file-upload>
                    </ion-row>
                    <ion-row class="ion-align-items-center" size="12">
                        <ion-col size="12">
                            <app-file-viewer [fileCategory]="'invoice'" [invoice]="invoice.value"
                                             (removeFile)="onRemoveFile($event)"
                                             (ocrCheckBoxChange)="onOCRCheckBoxChange($event)">
                            </app-file-viewer>
                        </ion-col>
                        <ion-col *ngIf="filesToOcr.length > 0">
                            <ion-grid>
                                <ion-row>
                                    <ion-col>
                                        <ion-button color="secondary" (click)="onOCRInvoice()" expand="full">RUN OCR
                                            ({{ filesToOcr.length }} files)
                                        </ion-button>
                                    </ion-col>
                                </ion-row>
                            </ion-grid>
                        </ion-col>
                    </ion-row>
                </ion-col>
                <ion-col>
                    <ion-row size="12">
                        <app-file-upload [userId]="userId" [tripId]="tripId" class="file-upload" fileCategory="other"
                                         [invoice]="invoice.value" (fileDropped)="onFileDropped($event)"
                                         dragAndDropText="Drop other documents">
                        </app-file-upload>
                    </ion-row>
                    <ion-row class="ion-align-items-center">
                        <app-file-viewer [fileCategory]="'other'" [invoice]="invoice.value"
                                         (removeFile)="onRemoveFile($event)">
                        </app-file-viewer>
                    </ion-row>
                </ion-col>
            </ion-row>
        </ion-grid>
        <ion-grid class="item-grid-style">
            <ion-row class="item-header">
                <ion-col size="3"><b>Description
                    <ion-text color="danger">*</ion-text>
                </b></ion-col>
                <ion-col size="2"><b>Category
                    <ion-text color="danger">*</ion-text>
                </b></ion-col>
                <ion-col><b>Brand</b></ion-col>
                <ion-col size="2"><b>Serial Number</b></ion-col>
                <ion-col><b>Quantity
                    <ion-text color="danger">*</ion-text>
                </b></ion-col>
                <ion-col><b>Tax
                    <ion-text color="danger">*</ion-text>
                </b></ion-col>
                <ion-col><b>Amount (HT)
                    <ion-text color="danger">*</ion-text>
                </b></ion-col>
                <ion-col><b>Amount (TVA)
                    <ion-text color="danger">*</ion-text>
                </b></ion-col>
                <ion-col><b>Amount (TTC)
                    <ion-text color="danger">*</ion-text>
                </b>
                </ion-col>
                <ion-col></ion-col>
            </ion-row>
            <ion-row *ngIf="selected">
                <app-invoice-item-add #invoiceItemAdd [isNewItem]="false" *ngFor="let item of sortedItems"
                                      [item]="item[1]" [item_id]="item[0]" (saveItem)="onSaveItem(item[0], $event)"
                                      (deleteItem)="deleteItem(item[0])">
                </app-invoice-item-add>
            </ion-row>
            <app-invoice-item-add #invoiceItemAdd [isNewItem]="true" *ngFor="let item of newItems" [item]="item"
                                  (saveItem)="onNewItemSave($event)" (deleteItem)="deleteItemAdd(item)">
            </app-invoice-item-add>
            <ion-row size="12">
                <ion-button class="add-item" (click)="addItem()">Add Item</ion-button>
            </ion-row>
        </ion-grid>
    </div>
</ion-row>