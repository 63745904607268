import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Routes, RouterModule} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {AdminPage} from './admin.page';
import {CustomsHistoryComponent} from './customs-history/customs-history.component';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {ListPageModule} from './list/list.module';
import {WindowComponent} from './window/window.component';
import {MatDialogModule} from '@angular/material/dialog';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {IntroducersComponent} from './marketers/introducers.component';
import {NbAccordionModule} from '@nebular/theme';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTableModule} from '@angular/material/table';
import {QRCodeModule} from 'angularx-qrcode';
import {CreateIntroducerComponent} from './marketers/create-introducer/create-introducer.component';
import {MatInputModule} from '@angular/material/input';
import {UserTableComponent} from './marketers/user-table/user-table.component';
import {MatTooltipModule} from "@angular/material/tooltip";

const routes: Routes = [
    {
        path: '',
        component: AdminPage,
        children: [
            {path: 'list', loadChildren: () => ListPageModule},
            {
                path: 'settings',
                loadChildren: () => import('../../pages/settings/settings.module').then(m => m.SettingsPageModule)
            },
            {path: 'marketers', component: IntroducersComponent},
        ]
    },

];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        CdkAccordionModule,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatDialogModule,
        DragDropModule,
        NbAccordionModule,
        MatExpansionModule,
        MatTableModule,
        QRCodeModule,
        MatInputModule,
        ReactiveFormsModule,
        MatTooltipModule,
    ],
    exports: [
        IntroducersComponent,
    ],
    declarations: [AdminPage, CustomsHistoryComponent, WindowComponent, IntroducersComponent, CreateIntroducerComponent, UserTableComponent]
})
export class AdminPageModule {
}
