<div>
    <mat-toolbar>
        <span>Custom Stub</span>
    </mat-toolbar>
    <div>
        <div>
            <p>User id: {{data.userId}}</p>
            <p>Trip id: {{data.tripId}}</p>
        </div>
        <div class="stub">
            <h4>Select a stub</h4>
            <div class="form">
                <div class="flex">
                    <mat-form-field appearance="fill">
                        <mat-label>Choose an option</mat-label>
                        <mat-select [(value)]="selected" (selectionChange)="onStubChange($event)">
                            <mat-option value="BVECreationSuccess">BVECreationSuccess</mat-option>
                            <mat-option value="BVECreationFailure">BVECreationFailure</mat-option>
                            <mat-option value="BVERemoveSuccess">BVERemoveSuccess</mat-option>
                            <mat-option value="BVERemoveFailure">BVERemoveFailure</mat-option>
                            <mat-option value="BVEApurement">BVEApurement</mat-option>
                            <mat-option value="BVEApurementFailure">BVEApurementFailure</mat-option>
                            <mat-option value="BVEApurementMultiple">BVEApurementMultiple</mat-option>
                            <mat-option value="BVEApurementP0015">BVEApurementP0015</mat-option>
                            <mat-option value="BVEApurementP0016">BVEApurementP0016</mat-option>
                            <mat-option value="ACK">Accusé reception</mat-option>
                            <mat-option value="GlobaleError">Erreur Globale</mat-option>
                            <mat-option value="BVETamponneSuccess">BVETamponneSuccess</mat-option>
                            <mat-option value="BVETamponneFailure">BVETamponneFailure</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button mat-mini-fab color="accent" aria-label="Send stub" (click)="sendStub(selected)"
                            [disabled]="!selected || showStubSpinner">
                        <mat-icon>send</mat-icon>
                    </button>
                </div>
                <div class="flex" *ngIf="showMultipleBves">
                    <div>
                        <mat-form-field appearance="fill" [(ngModel)]="bves[0]" ngDefaultControl>
                            <mat-label>bve1 (ko)</mat-label>
                            <input matInput>
                        </mat-form-field>
                        <mat-form-field appearance="fill" [(ngModel)]="bves[1]" ngDefaultControl>
                            <mat-label>bve2 (annule)</mat-label>
                            <input matInput>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field appearance="fill" [(ngModel)]="bves[2]" ngDefaultControl>
                            <mat-label>bve3 (ok)</mat-label>
                            <input matInput>
                        </mat-form-field>
                        <mat-form-field appearance="fill" [(ngModel)]="bves[3]" ngDefaultControl>
                            <mat-label>bve4 (ok)</mat-label>
                            <input matInput>
                        </mat-form-field>
                    </div>
                </div>
                <div class="flex" *ngIf="showMultipleBvesP16">
                    <div>
                        <mat-form-field appearance="fill" [(ngModel)]="bves[0]" ngDefaultControl>
                            <mat-label>bve1</mat-label>
                            <input matInput>
                        </mat-form-field>
                        <mat-form-field appearance="fill" [(ngModel)]="bves[1]" ngDefaultControl>
                            <mat-label>bve2</mat-label>
                            <input matInput>
                        </mat-form-field>
                        <mat-form-field appearance="fill" [(ngModel)]="bves[2]" ngDefaultControl>
                            <mat-label>bve3</mat-label>
                            <input matInput>
                        </mat-form-field>
                        <mat-form-field appearance="fill" [(ngModel)]="bves[3]" ngDefaultControl>
                            <mat-label>bve4</mat-label>
                            <input matInput>
                        </mat-form-field>
                        <mat-form-field appearance="fill" [(ngModel)]="bves[4]" ngDefaultControl>
                            <mat-label>bve5</mat-label>
                            <input matInput>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field appearance="fill" [(ngModel)]="bves[5]" ngDefaultControl>
                            <mat-label>bve6</mat-label>
                            <input matInput>
                        </mat-form-field>
                        <mat-form-field appearance="fill" [(ngModel)]="bves[6]" ngDefaultControl>
                            <mat-label>bve7</mat-label>
                            <input matInput>
                        </mat-form-field>
                        <mat-form-field appearance="fill" [(ngModel)]="bves[7]" ngDefaultControl>
                            <mat-label>bve8</mat-label>
                            <input matInput>
                        </mat-form-field>
                        <mat-form-field appearance="fill" [(ngModel)]="bves[8]" ngDefaultControl>
                            <mat-label>bve9</mat-label>
                            <input matInput>
                        </mat-form-field>
                        <mat-form-field appearance="fill" [(ngModel)]="bves[9]" ngDefaultControl>
                            <mat-label>bve10</mat-label>
                            <input matInput>
                        </mat-form-field>
                    </div>

                </div>
                <mat-spinner *ngIf="showStubSpinner" [diameter]="20"></mat-spinner>
            </div>
        </div>
    </div>
</div>