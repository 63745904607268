<div class="upload-table">
    <div *ngFor="let file of files" class="image-container">
        <a (click)="onFileClick(file)" href="javascript:void(0)">
            <mat-card class="pdf">
                <img *ngIf="filesType.get(file) === 'image'"
                     [attr.src]="file.uri"
                     class="upload-image-style" alt="Invoice">

                <pdf-viewer *ngIf="filesType.get(file) === 'pdf'" class="pdf"
                            [src]="file.uri"
                            [page]="1"
                            [autoresize]="true"
                            [original-size]="false"
                            [fit-to-page]="true"
                            [show-all]="false"
                            [zoom-scale]="'page-width'"
                            [render-text]="false"></pdf-viewer>
            </mat-card>
        </a>
        <ng-container *ngIf="!(maxFiles===1)">
            <ion-button *ngIf="file.ocr" color="medium" size="small" (click)="onRawData(file)">RAW DATA</ion-button>
            <button mat-icon-button class="delete-button" (click)="onRemoveFile(file)">
                <mat-icon>close</mat-icon>
            </button>
            <mat-checkbox
                    *ngIf="fileCategory==='invoice' && !file.ocr"
                    [labelPosition]="'before'"
                    (change)="ocrToggle(file, $event)"
                    color="primary">
                OCR
            </mat-checkbox>
        </ng-container>
    </div>
</div>
<ng-template #rawDataOcrTitle>
    <p cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
        Raw data {{invoice.invoice_number}}
    </p>
</ng-template>