import {Component, OnInit} from '@angular/core';
import {IntroducerService} from '../../services/introducer/introducer.service';
import {AuthService} from '../../services/auth.service';

@Component({
    selector: 'app-introducer-page',
    templateUrl: './introducer-page.component.html',
    styleUrls: ['./introducer-page.component.css']
})
export class IntroducerPageComponent implements OnInit {

    plateform: string;

    constructor(private introducerService: IntroducerService, private auth: AuthService) {
    }

    async ngOnInit(): Promise<void> {
        this.plateform = await this.introducerService.getIntroducerPlateform(this.auth.user.uid);
        console.log(this.plateform);
    }

}
