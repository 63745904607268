import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFireDatabase} from '@angular/fire/compat/database';

import firebase from 'firebase/compat/app';
import {Storage} from '@ionic/storage';
import {AlertController} from '@ionic/angular';

import {LoadingController} from '@ionic/angular';
import User = firebase.User;
import {first, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    loading;
    user: User;

    constructor(
        public afAuth: AngularFireAuth,
        public router: Router,
        public alertController: AlertController,
        private afDb: AngularFireDatabase,
        private loadingController: LoadingController // public modalController: ModalController
    ) {
        this.afAuth.authState.subscribe(
            (user) => {
                this.user = user;
                console.log(this.user);
            }
        );
    }

    async initialize() {
        this.user = await this.afAuth.currentUser;
    }

    get logStateChange(): Observable<boolean> {
        return this.afAuth.authState.pipe(
            map(user => !!user)
        );
    }

    isLoggedIn(): boolean {
        return !!this.user;
    }

    async getTokenClaims() {
        return new Promise<firebase.auth.IdTokenResult>((resolve, reject) => {
            this.afAuth.authState.pipe(first()).subscribe({
                next: async (user) => {
                    this.user = user;
                    const tokens = await this.user?.getIdTokenResult();
                    resolve(tokens);
                },
                error: (err) => {
                    reject(err);
                }
            });
        });
    }

    async isOperator(): Promise<boolean> {
        const tokens = await this.getTokenClaims();
        return !!(tokens?.claims?.operator);
    }

    async isIntroducer(): Promise<boolean> {
        const tokens = await this.getTokenClaims();
        return tokens?.claims?.role === 'introducer';
    }

    invokeLoading() {
        this.loadingController
            .create({
                message: 'Loading',
                duration: 5000
            })
            .then(res => {
                res.present();
                this.loading = res;
            });
    }

    async presentAlert(error) {
        const alert = await this.alertController.create({
            header: 'Error',
            message: error,
            buttons: ['OK']
        });

        await alert.present();
    }


    async doEmailLogin(email, password) {
        await this.afAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        return firebase.auth().signInWithEmailAndPassword(email, password).catch((error) => {
            this.presentAlert((error.message));
        });
    }

    doEmailReg(email, password, name) {
        firebase.auth().createUserWithEmailAndPassword(email, password).then((res) => {
            console.log('Fb auth ready', res);
            const profile = this.afDb.object('users/' + res.user.uid + '/profile/').set({
                displayName: name
            });
        }).catch((error) => {
            // Handle Errors here.
            this.presentAlert((error.message));
            // ...
        });
    }

    doAppleLogin() {
        this.invokeLoading();
        console.log('doAppleLogin');
        return new Promise<firebase.auth.UserCredential>((resolve, reject) => {
            const provider = new firebase.auth.OAuthProvider('apple.com');
            provider.addScope('profile');
            provider.addScope('email');
            this.afAuth.signInWithPopup(provider).then(res => {
                console.log('Result:', res);
                try {
                    this.loading.dismiss();
                } catch (err) {
                }
                resolve(res);
            });
        });
    }

    doFacebookLogin() {
        this.invokeLoading();
        console.log('doFacebookLogin', 'signInWithPopup(provider)');
        return new Promise<any>((resolve, reject) => {
            const provider = new firebase.auth.FacebookAuthProvider();
            provider.addScope('profile');
            provider.addScope('default');
            provider.addScope('email');
            this.afAuth.signInWithPopup(provider).then(res => {
                console.log('Result:', res);
                try {
                    this.loading.dismiss();
                } catch (err) {
                }
                resolve(res);
            });
        });
    }

    doGoogleLogin() {
        this.invokeLoading();
        console.log('doGoogleLogin');
        return new Promise<firebase.auth.UserCredential>((resolve, reject) => {
            const provider = new firebase.auth.GoogleAuthProvider();
            provider.addScope('profile');
            provider.addScope('email');
            this.afAuth.signInWithPopup(provider).then(res => {
                console.log('Result:', res);
                try {
                    this.loading.dismiss();
                } catch (err) {
                }
                resolve(res);
            });
        });
    }

    async logout(refresh = false) {
        this.user = null;
        await this.afAuth.signOut();
        if (refresh) {
            window.location.reload();
        }
    }
}
