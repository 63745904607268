import {Injectable} from '@angular/core';
import {AngularFireDatabase, SnapshotAction} from '@angular/fire/compat/database';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DatabaseService {

    constructor(private db: AngularFireDatabase) {
    }

    getPath<T>(path: string): Observable<SnapshotAction<T>> {
        return this.db.object(path).snapshotChanges() as Observable<SnapshotAction<T>>;
    }

    set(path: string, value: any) {
        return this.db.object(path).set(value);
    }

    update(path: string, value: any) {
        return this.db.object(path).update(value);
    }
}
