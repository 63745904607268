<ion-content>
    <ion-list>
        <ion-item button *ngFor="let anomaly of anomalies"
                  (click)="selectAnomaly(anomaly.id)"
                  [ngClass]="{'selected': selectedAnomalyId === anomaly.id}">
            {{ anomaly.name }}
        </ion-item>
        <ion-item *ngIf="selectedAnomalyId === 5">
            <ion-label position="stacked">Raison</ion-label>
            <ion-input [(ngModel)]="customAnomaly"
                       (ionFocus)="onInputFocus()"
                       placeholder="Quelle est la raison ?"></ion-input>
        </ion-item>
    </ion-list>
    <ion-button expand="full" (click)="submitAnomaly()">Valider</ion-button>
</ion-content>
