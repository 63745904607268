<ion-header>
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button matTooltip="Back step"
                        matTooltipPosition="above" (click)="backStep()" [disabled]="item.status<2">
                <ion-icon name="arrow-back-outline" slot="icon-only"></ion-icon>
            </ion-button>
            <ion-button matTooltip="Next step"
                        matTooltipPosition="above" (click)="nextStep()"
                        [disabled]="item.status<1 || (item.status===2 && !item.overall_invoice) || (item.status===4 && (profile.bank.swift === '' || profile.bank.iban === '')) || item.status===5">
                <ion-icon name="arrow-forward-outline" slot="icon-only"></ion-icon>
            </ion-button>
            <ion-button *ngIf="!environment.production" (click)="sendStub()">
                <ion-icon name="send"></ion-icon>
                Customs Stub
            </ion-button>

            <ion-button (click)="deleteTrip()">
                <ion-icon color="danger" name="trash-outline"></ion-icon> &nbsp;
                <ion-text color="danger">Delete Trip</ion-text>
            </ion-button>
            <ion-button (click)="close()">
                <ion-icon name="close" slot="icon-only"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title>{{ item.name }} - {{ statuses[item.status] }}
            <ion-icon *ngIf="profile?.statusProfile === 'valid'" color="success"
                      name="checkmark-circle-outline"></ion-icon>
            <ion-icon *ngIf="profile?.statusProfile === 'rejected'" color="danger"
                      name="close-circle-outline">
            </ion-icon>
            <ion-icon *ngIf="profile?.statusProfile === 'pending'" color="warning" name="time-outline">
            </ion-icon>
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

    <ion-grid *ngIf="profile">

        <ion-row>
            <!-- Personal information -->
            <ion-col>
                <div class="computer-profile-table">
                    <ion-grid class="grid-style">
                        <ion-row class="profile-header">
                            <ion-col>
                                <h3>
                                    Personnal information
                                </h3>
                                <span><i>Required <ion-text color="danger">*</ion-text></i></span>
                            </ion-col>
                        </ion-row>

                        <!-- Computer version -->
                        <ion-row class="computer-profile-row">
                            <ion-col class="input-style">
                                <ion-item>
                                    <ion-label position="floating">Email address
                                        <ion-text color="danger">*</ion-text>
                                    </ion-label>
                                    <ion-input [(ngModel)]="profile.email" class="hl" type="email">
                                    </ion-input>
                                </ion-item>
                                <ion-item>
                                    <ion-label position="floating">Phone
                                        <ion-text color="danger">*</ion-text>
                                    </ion-label>
                                    <ion-input [(ngModel)]="profile.phone" class="hl" type="tel"></ion-input>
                                </ion-item>
                                <app-grade-selector [grade]="admin?.grade"
                                                    [disabled]="item.status >= 5"
                                                    (gradeChange)="onGradeChange($event)"></app-grade-selector>
                            </ion-col>
                            <ion-col class="input-style">
                                <ion-item>
                                    <ion-label position="stacked">Address
                                        <ion-text color="danger">*</ion-text>
                                    </ion-label>
                                    <ion-input [(ngModel)]="profile.address" class="hl">
                                    </ion-input>
                                </ion-item>
                                <ion-item>
                                    <ion-label position="stacked">City
                                        <ion-text color="danger">*</ion-text>
                                    </ion-label>
                                    <ion-input [(ngModel)]="profile.city" class="hl"></ion-input>
                                </ion-item>
                                <ion-item>
                                    <ion-label position="stacked">Country of residency
                                        <ion-text color="danger">*</ion-text>
                                    </ion-label>
                                    <select [(ngModel)]="profile.country" class="select_profile" required>
                                        <option *ngFor="let country of residencyCountryList" [ngValue]="country.code"
                                                [selected]="country.code === profile.country">
                                            {{ country.name }}
                                        </option>
                                    </select>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </div>
            </ion-col>

            <!-- Passport -->
            <ion-col>
                <div class="computer-profile-table">
                    <ion-grid class="grid-style">
                        <ion-row class="profile-header">
                            <ion-col>
                                <ion-row>
                                    <h3>
                                        Passport details
                                    </h3>
                                </ion-row>
                                <span
                                ><i>Required <ion-text color="danger">*</ion-text></i></span
                                >
                            </ion-col>
                        </ion-row>
                        <!-- Computer version -->
                        <ion-row class="computer-profile-row">
                            <ion-col class="input-style">
                                <ion-item>
                                    <ion-label position="floating">First Name
                                        <ion-text color="danger">*</ion-text>
                                    </ion-label>
                                    <ion-input [(ngModel)]="profile.first_name" class="hl" type="given-name">
                                    </ion-input>
                                </ion-item>
                                <ion-item>
                                    <ion-label position="stacked">Nationality
                                        <ion-text color="danger">*</ion-text>
                                    </ion-label>
                                    <select [(ngModel)]="profile.nationality" class="select_profile" required>
                                        <option *ngFor="let country of nationalityCountryList" [ngValue]="country.code"
                                                [selected]="country.code === profile.nationality">
                                            {{ country.name }}
                                        </option>
                                    </select>
                                </ion-item>
                                <ion-item>
                                    <ion-label position="floating">Passport Number
                                        <ion-text color="danger">*</ion-text>
                                    </ion-label>
                                    <ion-input [(ngModel)]="profile.passport_number" class="hl"></ion-input>
                                </ion-item>
                                <ion-item>
                                    <ion-label position="floating">Date of passport Expiry
                                        <ion-text color="danger">*</ion-text>
                                    </ion-label>
                                    <ion-input [(ngModel)]="profile.passport_expiration" class="hl">
                                    </ion-input>
                                </ion-item>
                            </ion-col>
                            <ion-col class="input-style">
                                <ion-item>
                                    <ion-label position="floating">Last Name
                                        <ion-text color="danger">*</ion-text>
                                    </ion-label>
                                    <ion-input [(ngModel)]="profile.last_name" class="hl" type="family-name">
                                    </ion-input>
                                </ion-item>
                                <ion-item>
                                    <ion-label position="floating">Birthday date
                                        <ion-text color="danger">*</ion-text>
                                    </ion-label>
                                    <ion-input [(ngModel)]="profile.dob" class="hl"></ion-input>
                                </ion-item>
                                <ion-item>
                                    <ion-row size="3">
                                        <ion-col size="3" class="flex items-center justify-center">
                                            Status
                                        </ion-col>
                                        <ion-col size="3">
                                            <app-doc-status
                                                    [status]="profile.passportState"></app-doc-status>
                                        </ion-col>
                                    </ion-row>
                                </ion-item>
                                <ion-button color="primary"
                                            (click)="redirectIdVerificationFlow()">Process ID verification
                                </ion-button>
                                <ion-button *ngIf="admin?.ariadnext?.dossiers" color="primary"
                                            (click)="downloadPassportReport()">Download passport report
                                </ion-button>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </div>
            </ion-col>
        </ion-row>

        <ion-row>
            <ion-col size="7.8">
                <div class="computer-profile-table">
                    <ion-grid class="grid-style">
                        <ion-row class="profile-header">
                            <ion-col>
                                <h3>
                                    Proof of residency
                                </h3>
                            </ion-col>
                        </ion-row>
                        <!-- Computer version -->
                        <ion-row class="computer-profile-row">
                            <ion-col>
                                <div class="pad">
                                    <input #fileResidency
                                           (change)="addProfileImage(fileResidency.files, 'residencyPhotos')"
                                           accept="image/*" class="file-uploader" type="file"/>
                                    <div class="image-set">
                                        <ng-container *ngFor="let image of residencyImages | keyvalue; index as i;">
                                            <a href="{{image.value.uri}}" target="_blank">
                                                <div *ngIf="image && image.value.uri"
                                                     [style.background-image]="'url(' + image.value.uri + ')'"
                                                     class="image">
                                                    <ion-icon
                                                            (click)="removeProfileImage(image.value, 'residencyPhotos')"
                                                            color="danger" name="close-circle"></ion-icon>
                                                </div>
                                            </a>
                                        </ng-container>
                                        <div (click)="fileResidency.click()" class="add"></div>
                                    </div>
                                </div>
                            </ion-col>
                            <ion-col class="input-style">
                                <ion-item class="ion-text-right">
                                    <ion-label>Expiration</ion-label>
                                    <ion-input [(ngModel)]="profile.residency_expiration"></ion-input>
                                </ion-item>
                                <ion-item>
                                    <ion-label>Type of document</ion-label>
                                    <ion-select [(ngModel)]="profile.residencyDocumentType"
                                                (ionChange)="onDocumentTypeSelection($event)"
                                                interface="popover">
                                        <ion-select-option *ngFor="let document of documentTypeList"
                                                           [value]="document.name">
                                            {{ document.name }}
                                        </ion-select-option>
                                    </ion-select>
                                </ion-item>
                            </ion-col>
                            <ion-col class="input-style">
                                <ion-item>
                                    <ion-label>Country</ion-label>
                                    <ion-select [(ngModel)]="profile.residencyCountry" interface="popover"
                                                (ionChange)="onResidencyCountrySelection($event)">
                                        <ion-select-option *ngFor="let country of residencyCountryList"
                                                           [value]="country.code">
                                            {{ country.name }}
                                        </ion-select-option>
                                    </ion-select>
                                </ion-item>
                                <ion-item>
                                    <ion-label>Status</ion-label>
                                    <app-doc-status
                                            [status]="profile.residencyState"></app-doc-status>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                    <ion-row>
                        <ion-button (click)="updateProfile('profile',profile); setResidencyValid()"
                                    [disabled]="!profile.residencyPhotos || profile.residencyState === 'valid'"
                                    color="success">Set
                            residency as valid
                        </ion-button>
                        <ion-button (click)="setResidencyPending()" [disabled]="profile.residencyState === 'pending'"
                                    color="warning">
                            Set residency as pending
                        </ion-button>
                        <ion-button (click)="setResidencyRejected()" [disabled]="profile.residencyState === 'rejected'"
                                    color="danger">Set residency as rejected
                        </ion-button>
                    </ion-row>
                </div>
            </ion-col>

            <ion-col size="4">
                <!-- Bank details -->
                <div class="computer-profile-table">
                    <ion-grid class="grid-style">
                        <ion-row class="profile-header">
                            <ion-col>
                                <h3>Refund method</h3>
                            </ion-col>
                        </ion-row>
                        <ion-col class="input-style">
                            <ion-item>
                                <ion-label position="floating">IBAN</ion-label>
                                <ion-input (ngModelChange)="profile.bank.iban=$event" [ngModel]="profile.bank?.iban"
                                           class="hl">
                                </ion-input>
                            </ion-item>
                            <ion-item>
                                <ion-label position="floating">SWIFT</ion-label>
                                <ion-input (ngModelChange)="profile.bank.swift=$event" [ngModel]="profile.bank?.swift"
                                           class="hl">
                                </ion-input>
                                <ion-button slot="end" (click)="saveSwift()">save</ion-button>
                            </ion-item>
                            <ion-item>
                                <ion-label position="floating">CURRENCY</ion-label>
                                <ion-select [(ngModel)]="profile.bank.currency" interface="popover">
                                    <ion-select-option *ngFor="let currency of currencies" [value]="currency">{{currency}}</ion-select-option>
                                </ion-select>
                                <ion-button slot="end" (click)="saveCurrency()">save</ion-button>
                            </ion-item>
                            <ion-item>
                                <ion-label position="stacked">Bank Country</ion-label>
                                <div class="hl">
                                    {{ getCountry(profile.bank?.iban?.substring(0, 2)) }}
                                </div>
                            </ion-item>
                        </ion-col>
                    </ion-grid>
                </div>
            </ion-col>
        </ion-row>

        <br/>

        <ion-grid class="grid-style">
            <ion-row class="profile-header">
                <ion-col>
                    <h3>Trip details</h3>
                </ion-col>
            </ion-row>
            <ion-row class="computer-profile-row">
                <ion-col class="grid-col">
                    <span>Arrival</span>
                    <div class="hl">
                        {{ item.arrival_date | amDateFormat: 'MMM. Do YYYY' }}
                        - {{ getCountry(item.arrival_country) }}
                    </div>
                </ion-col>
                <ion-col class="grid-col">
                    <span>Departure</span>
                    <div class="hl">
                        {{ item.departure_date | amDateFormat: 'MMM. Do YYYY' }}
                        - {{ getCountry(item.departure_country) }}
                    </div>
                    <br/>
                </ion-col>
            </ion-row>
        </ion-grid>

        <br/>

        <!--BVE ERROR -->
        <ion-row *ngIf="item.status>1 && item.customs?.currentStatus?.reason">
            <ion-col class="bve_alert">
                <h3>
                    <ion-icon color="danger" name="warning"></ion-icon>
                    Douane error
                </h3>
                <b>Code :</b> {{ item.customs?.currentStatus?.code }}<br/>
                <!-- <b>Reason :</b> {{ getReasonRegex(item.customs?.currentStatus?.reason) }}<br /> -->
                <b>Reason :</b> {{ item.customs?.currentStatus?.reason }}<br/>
            </ion-col>
        </ion-row>
        <!--BVE CREATION & DELETION-->
        <!-- TAX FREE FORM-->
        <mat-card class="taxform-card">
            <mat-card-header>
                <mat-card-title>Tax Free Form</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-radio-group [(ngModel)]="pabloMode" color="primary">
                    <ion-row>
                        <ion-col size="8">
                            <mat-card class="margin-bottom-1 h-fit">
                                <mat-card-header class="margin-bottom-1 flex justify-between">
                                    <mat-radio-button value="Pablo-O" class="taxform_radio">
                                        Pablo-O
                                    </mat-radio-button>
                                </mat-card-header>
                                <mat-card-content class="taxform_content"
                                                  [class.disabled-card]="pabloMode !== 'Pablo-O'">
                                    <ion-row *ngIf="item.status>1 && item.bve_creation_error_message">
                                        <ion-col class="bve_alert">
                                            <h3>
                                                <ion-icon color="danger" name="warning"></ion-icon>
                                                Erreur creation BVE
                                            </h3>
                                            <b>Erreurs :</b> {{ item.bve_creation_error_message.errors }}<br/>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row *ngIf="item.customs?.currentStatus" size="3">
                                        <ion-col size="3">
                                            Status
                                        </ion-col>
                                        <ion-col size="3">
                                            <app-customs-status
                                                    [currentStatus]="item.customs?.currentStatus"></app-customs-status>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row *ngIf="item.taxform?.date" size="3">
                                        <ion-col size="3">
                                            Date
                                        </ion-col>
                                        <ion-col size="3">
                                            {{ item.taxform?.date | date: 'dd/MM/yyyy' }}
                                        </ion-col>
                                    </ion-row>
                                    <ion-row *ngIf="item.taxform?.place" size="3">
                                        <ion-col size="3">
                                            Place
                                        </ion-col>
                                        <ion-col size="3">
                                            {{ item.taxform?.place }}
                                        </ion-col>
                                    </ion-row>
                                    <ion-item class="margin-bottom-1">
                                        <ion-label>Refund percentage (%):</ion-label>
                                        <ion-input [(ngModel)]="item.refund_rate"
                                                   placeholder="{{getUserRefundRate()}}"></ion-input>
                                        <button slot="end" (click)="setRefundRate(item.refund_rate)">Save</button>
                                    </ion-item>

                                    <ion-row class="justify-between">
                                        <ion-button *ngIf="canShowCreateBve()" class="taxform-button"
                                                    (click)="createBve(trip, user)">CREATE TAX FREE FORM
                                        </ion-button>
                                        <ion-button *ngIf="!canShowCreateBve() && !item.taxform?.uri" [disabled]="true"
                                                    class="taxform-button">
                                    <span class="flex flex-row my-auto">
                                        <p class="text-white">Taxform creation in progress</p>
                                        <mat-spinner [diameter]="20"></mat-spinner>
                                    </span>
                                        </ion-button>

                                        <ion-button [disabled]="canShowDeleteBve()"
                                                    (click)="removeBVE(trip, user, item.taxform.docId, item.status)"
                                                    class="taxform-button" color="danger" type="submit">DELETE TAX FREE
                                            FORM
                                        </ion-button>
                                        <ion-button matTooltip="Check for taxform" color="light"
                                                    [disabled]="!canRefreshPablo"
                                                    (click)="refreshEDI()">
                                            <ion-icon name="refresh-outline"></ion-icon>
                                        </ion-button>
                                    </ion-row>
                                </mat-card-content>
                            </mat-card>
                        </ion-col>
                        <ion-col>
                            <mat-card class="margin-bottom-1 h-52 justify-center">
                                <mat-card-header class="margin-bottom-1">
                                    <mat-radio-button value="Pablo-I" class="taxform_radio">
                                        Backup procedure
                                    </mat-radio-button>
                                </mat-card-header>
                                <mat-card-content class="taxform_content flex flex-col justify-center h-full"
                                                  [class.disabled-card]="pabloMode !== 'Pablo-I'">
                                    <ion-button class="taxform-button" (click)="downloadBveTrip()">DOWNLOAD DATA
                                        (JSON
                                        FILE)
                                    </ion-button>
                                    <ion-button (click)="uploadTaxFreeFormButtonClick()" [disabled]="item.status<2"
                                                class="margin-bottom-1 taxform-button">UPLOAD TAX FREE FORM
                                    </ion-button>
                                </mat-card-content>
                            </mat-card>
                        </ion-col>
                    </ion-row>
                </mat-radio-group>

                <mat-card class="margin-bottom-1">
                    <mat-card-content>
                        <ion-row *ngIf="item.status>1">
                            <ion-col>
                                Current file:
                                <a *ngIf="item.taxform" href="{{ item.taxform.uri }}" target="_blank">{{
                                        item.taxform.uri
                                    }}</a><br/>
                                <input #file2 (change)="addImage($event, 'TX')" [disabled]="item.status<2"
                                       class="file-uploader ion-hide" type="file"/>
                                <ion-item>
                                    <ion-label>Tax-form Number :</ion-label>
                                    <ion-input [(ngModel)]="docId"></ion-input>
                                    <button (click)="updateTaxformNumber(docId)" slot="end">Save</button>
                                </ion-item>
                                <ion-item>
                                    <ion-label>Total VAT Amount :</ion-label>
                                    <ion-input [(ngModel)]="vatAmount"></ion-input>
                                    <button slot="end" (click)="updateVatAmount(vatAmount)">Save</button>
                                </ion-item>
                                <ion-item>
                                    <ion-label>Total TTC Amount :</ion-label>
                                    <ion-input [(ngModel)]="totalTTC"></ion-input>
                                    <button slot="end" (click)="updateTotalTTC(totalTTC)">Save</button>
                                </ion-item>
                                <ion-item>
                                    <ion-label>Refunded VAT Amount :</ion-label>
                                    <ion-input [(ngModel)]="dtaxAmount"></ion-input>
                                    <button slot="end" (click)="updateDTAXAmount(dtaxAmount)">
                                        Save
                                    </button>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                    </mat-card-content>
                </mat-card>

                <mat-card class="margin-bottom-1">
                    <mat-card-header>
                        <mat-card-title>Overall Invoice</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <ion-col *ngIf="item.status>1" class="flex">
                            <ion-button (click)="addOverallInvoice()" class="taxform-button"
                                        [disabled]="item.status<2">CREATE OVERALL INVOICE
                            </ion-button>
                            <ion-col>
                                Current file:
                                <a *ngIf="item.overall_invoice" href="{{ item.overall_invoice.uri }}" target="_blank">{{
                                        item.overall_invoice.uri
                                    }}</a><br/>
                                <input #file1 (change)="addImage($event, 'OI')" [disabled]="item.status<2"
                                       class="file-uploader ion-hide" type="file"/>

                            </ion-col>
                        </ion-col>
                    </mat-card-content>
                </mat-card>

                <ion-button class="taxform-button center" color="success"
                            (click)="updateTaxformNumber(docId); proceedFurtherStatus()" [disabled]="item.status!==2">
                    UPLOAD TAX FREE FORM IN THE APP
                </ion-button>

            </mat-card-content>
        </mat-card>
        <br/>

        <!-- BVE STEPS -->
        <br/>

        <!-- <br />
        <ion-row *ngIf="item.status>2">
          <ion-col class="active-item">
            User BVE error Fixed ?<br />
            <ion-button
              (click)="setBveErrorFalse()"
              [disabled]="item.bve_error === false"
              color="success"
              >YES !
            </ion-button>
          </ion-col>
        </ion-row>
        <br /> -->
        <ion-row *ngIf="item.status===6 || item.status >= 3">
            <ion-col class="active-item">
                <b>Manual verification :</b><br/>
                <ion-button *ngIf="item.bve_error" fill="outline" (click)="setBveErrorFalse()" shape="round"
                            size="small" color="secondary">Electronic Validation
                </ion-button>
                <ion-button *ngIf="item.bve_error" fill="solid" shape="round" size="small" color="secondary">Stamp
                    Validation
                </ion-button>
                <ion-button *ngIf="item.bve_error === false" fill="solid" shape="round" size="small"
                            color="secondary">Electronic Validation
                </ion-button>
                <ion-button *ngIf="item.bve_error === false" fill="outline" (click)="setBveErrorTrue()" shape="round"
                            size="small" color="secondary">Stamp Validation
                </ion-button>
                <br/>
                <i>Operator shall verify the customs approval and upload the validated
                    document.</i><br/>
                Current file:
                <a *ngIf="item.taxform" href="{{ item.taxform.stampedUri }}" target="_blank">{{
                        item.taxform.stampedUri
                    }}</a><br/>
                <input #file3 (change)="addImage($event, 'STX')" [disabled]="item.status <3"
                       class="file-uploader ion-hide" type="file"/>
                <ion-button (click)="file3.click()" [disabled]="item.status <3" color="danger">{{
                        item.bve_error ?
                            'Upload tax form with Stamp Validation' : 'Upload tax form with Electronic Validation'
                    }}
                </ion-button>

                <br/>
                <ion-button (click)="setApproved()" [disabled]="item.status!==6" color="success">APPROVE !
                </ion-button>
            </ion-col>
        </ion-row>
        <br/>
        <ion-row>
            <ion-col class="active-item">
                <b>Referral program</b>
                <ion-row>
                    <ion-col>
                        <p *ngIf="meta?.referral?.id_referrer">Invited by: {{ meta?.referral?.id_referrer }}</p>
                    </ion-col>
                </ion-row>
                <ion-row *ngIf="!meta?.referral?.id_referrer" class="ion-align-items-center">
                    <ion-col size="auto">
                        <ion-select #introducerSelect justify="start" label="Select introducer:"
                                    placeholder="Introducer" interface="action-sheet">
                            <ion-select-option *ngFor="let introducer of introducerService.introducerList"
                                               [value]="introducer">{{ introducer }}
                            </ion-select-option>
                        </ion-select>
                    </ion-col>
                    <ion-col size="auto">
                        <ion-button color="light" size="small" [disabled]="!introducerSelect.value"
                                    (click)="introducerService.setUserIntroducer(user, introducerSelect.value)">
                            <ion-icon name="save-outline" color="primary"></ion-icon>
                        </ion-button>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <p>Referral code: {{ meta?.referral?.referral_code }}</p>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <p>Invited people : {{ meta?.referral?.referred_count ?? 0 }}</p>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <p>
                            Credit left : {{ (meta?.referral?.referred_success_count ?? 0) * 5 }}€
                        </p>
                    </ion-col>
                </ion-row>
            </ion-col>
            <ion-col class="active-item">
                <form #creditForm="ngForm" (ngSubmit)="removeCredit(creditForm)">
                    <ion-item>
                        <ion-label>Amount of credit to remove</ion-label>
                        <ion-input name="amount" ngModel></ion-input>
                    </ion-item>
                    <ion-button type="submit">Remove credit</ion-button>
                </form>
            </ion-col>
        </ion-row>
        <br/>
        <ion-row *ngIf="item.status>=4 && item.status!==6">
            <ion-col class="active-item">
                <b>Set Refund Done</b> (Active if status is
                <u>{{ statuses[4] }}</u>)<br/>
                <ion-label *ngIf="profile.statusProfile!=='valid'">
                    <ion-icon color="danger" name="warning"></ion-icon>
                    Please <b>validate</b> the user profile to use Quickbooks
                </ion-label>
                <br/>
                <div class="flex flex-row justify-between">
                    <ion-item>
                        <ion-label>Refund Date :</ion-label>
                        <ion-input [(ngModel)]="refundDate"
                                   [color]="this.item.taxform.refundDate && !this.item.taxform.refundDate.startsWith('-0001') ? 'success' : 'warning'"
                                   type="date"></ion-input>
                        <button (click)="updateRefundDate(refundDate)">Save</button>
                    </ion-item>
                    <ion-button
                            [disabled]="item.status_compta?.bank_make_payment === 'progress' || item.taxform?.payment_reference"
                            class="w-64 py-2 px-4" color="success" (click)="manualPayment()">
                        Manual payment
                    </ion-button>
                </div>
                <div>
                    <b>Introducer</b>
                    <div class="flex flex-row justify-between">
                        <ion-item class="w-auto">
                            <ion-label>Introducer’s Fees</ion-label>
                            <ion-input [(ngModel)]="commission_amount"></ion-input>
                        </ion-item>
                        <ion-item class="w-auto">
                            <ion-label>Gov.=>DTB refund date</ion-label>
                            <ion-input [(ngModel)]="estimated_gov_refund_date" type="date"></ion-input>
                        </ion-item>
                        <ion-item class="w-auto">
                            <ion-label>DTB=>Introducer refund date</ion-label>
                            <ion-input [(ngModel)]="estimated_comp_refund_date" type="date"></ion-input>
                        </ion-item>
                    </div>
                </div>
                <div class="flex flex-row justify-between">
                    <ion-button [disabled]="item.status!==4" color="success" (click)="setDone()">Set as DONE !
                    </ion-button>
                    <div class="flex flex-row justify-start items-center">
                        <button [matTooltip]="item.taxform?.payment_reference"
                                [disabled]="item.status!==4 || qontoLoading || item.status_compta?.bank_make_payment === 'progress' || item.taxform?.payment_reference"
                                class="w-64 inline-flex items-center justify-center bg-gray-950 text-white py-2 px-4 rounded
               hover:bg-gray-900 active:bg-black disabled:bg-gray-700 disabled:cursor-not-allowed disabled:opacity-50"
                                (click)="makePaymentWithQonto()">
                            <img *ngIf="!qontoLoading && item.status_compta?.bank_make_payment !== 'progress'"
                                 src="https://pbs.twimg.com/profile_images/1493510639054200834/iuwQUAyN_400x400.jpg"
                                 class="h-6 w-6 mr-2" alt="Qonto logo">
                            <mat-spinner *ngIf="qontoLoading || item.status_compta?.bank_make_payment === 'progress'"
                                         diameter="20"></mat-spinner>
                            Make payment with Qonto
                        </button>
                        <button [matTooltip]="item.taxform?.payment_reference"
                                [disabled]="item.status!==4 || wiseLoading || item.status_compta?.bank_make_payment === 'progress' || item.taxform?.payment_reference"
                                class="w-64 inline-flex items-center justify-center bg-green-500 text-white py-2 px-4 rounded
               hover:bg-green-600 active:bg-green-700 disabled:bg-green-300 disabled:cursor-not-allowed disabled:opacity-50"
                                (click)="makePaymentWithWise()">
                            <img *ngIf="!wiseLoading && item.status_compta?.bank_make_payment !== 'progress'"
                                 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZpsuM_8JAUuxcpIMn0xJ1LkJMNK68rvMKIA&s"
                                 class="h-6 w-6 mr-2" alt="Wise logo">
                            <mat-spinner *ngIf="wiseLoading || item.status_compta?.bank_make_payment === 'progress'"
                                         diameter="20"></mat-spinner>
                            Make payment with Wise
                        </button>

                        <button *ngIf="item.status_compta?.bank_make_payment === 'valid'" (click)="clearPayment()"
                                class="w-32 inline-flex items-center justify-center bg-red-600 text-white py-2 px-4 rounded
               hover:bg-red-500 active:bg-red-700 disabled:bg-gray-700 disabled:cursor-not-allowed disabled:opacity-50">
                            Clear payment
                        </button>
                    </div>
                </div>
                <app-compta-creation
                        [buttonClicked]="qboClicked"
                        [item]="item"
                        [statusCompta]="statusCompta"
                        [trip]="trip"
                ></app-compta-creation>

            </ion-col>
        </ion-row>
        <br/>

        <!-- Invoices -->

        <ion-row>
            <ion-col>
                <h3>Invoices</h3>
                <span><i>Required <ion-text color="danger">*</ion-text></i></span>
            </ion-col>
        </ion-row>
        <!-- Computer version -->
        <div class="long-table">
            <ion-grid class="computer-grid-style">
                <ion-row class="header">
                    <ion-col class="header_size"><b>Shop name
                        <ion-text color="danger">*</ion-text>
                    </b></ion-col>
                    <ion-col class="header_size"><b>Invoice number
                        <ion-text color="danger">*</ion-text>
                    </b></ion-col>
                    <ion-col class="header_size"><b>Date of purchase
                        <ion-text color="danger">*</ion-text>
                    </b></ion-col>
                    <ion-col class="header_size"><b>Total amount (HT)</b></ion-col>
                    <ion-col class="header_size"><b>Total amount (TVA)</b></ion-col>
                    <ion-col class="header_size"><b>Total amount (TTC)</b></ion-col>
                    <ion-col class="header_size"><b>Intra-community VAT number</b></ion-col>
                    <ion-col class="header_size"><b>SIRET</b></ion-col>
                    <ion-col class="header_size"><b>Status</b></ion-col>
                    <ion-col size="0.3"></ion-col>
                </ion-row>
                <ng-container *ngFor="let invoice of sortedInvoices; let i = index;">
                    <app-invoice [invoice]="{
                            key: invoice[0],
                            value: invoice[1]
                            }" [userId]="user" [tripId]="trip"
                                 [dbPath]="'users/' + user + '/trips/' + trip + '/shop_invoices/' + invoice[0]"
                                 [selected]="i === getInvoiceSelected()" (selectInvoice)="selectInvoice(i)">
                    </app-invoice>
                </ng-container>
                <ion-row *ngIf="item.temp_shop_invoices">
                    <ion-col>
                        <h3>Draft invoices</h3>
                    </ion-col>
                </ion-row>
                <div>
                    <app-invoice *ngFor="let invoice of (item.temp_shop_invoices | keyvalue); let i = index"
                                 [invoice]="invoice" [userId]="user" [tripId]="trip"
                                 [dbPath]="'users/' + user + '/trips/' + trip + '/temp_shop_invoices/' + invoice.key"
                                 (updateInvoice)="onNewInvoiceAdd(invoice.key)"
                                 (deleteInvoice)="onNewInvoiceDelete(invoice.key)"
                                 (selectInvoice)="selectInvoice(i + shopInvoicesLength)"
                                 [selected]="i + shopInvoicesLength === getInvoiceSelected()">
                    </app-invoice>
                </div>
                <br/>
                <ion-row>
                    <ion-button (click)="addNewInvoice()" class="add-invoice-button">
                        <ion-icon class="add-icon" name="add-circle-outline"></ion-icon>
                        ADD INVOICE
                    </ion-button>
                </ion-row>
                <br/>

                <span class=".inline">
                    <div class="right-total-ttc-spend">
                        <b>Total Spend HT : {{ getTripAmount("ht") }}</b>
                    </div>
                    <div class="right-total-ttc-spend">
                        <b>Total Spend TVA : {{ getTripAmount("tva") }}</b>
                    </div>
                    <div class="right-total-ttc-spend">
                        <b>Total Spend TTC : {{ getTripAmount("ttc") }}</b>
                    </div>
                </span>
            </ion-grid>
        </div>
        <br/>
    </ion-grid>
    <br/>
</ion-content>
