<ion-item>
    <ion-label>Grade</ion-label>
    <ion-select [(ngModel)]="selectedGrade"
                (ionChange)="onSelect($event)"
                [disabled]="disabled"
                interface="popover">
        <ion-select-option *ngFor="let grade of grades" [value]="grade.value">
            {{ grade.label }}
        </ion-select-option>
    </ion-select>
</ion-item>