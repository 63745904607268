import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-create-introducer',
    templateUrl: './create-introducer.component.html',
    styleUrls: ['./create-introducer.component.css']
})
export class CreateIntroducerComponent {
    form: FormGroup;
    slugModified = false;  // Ajouté

    constructor(private fb: FormBuilder) {
        this.form = this.fb.group({
            nom: '',
            slug: ''
        });

        this.form.get('nom').valueChanges.subscribe(val => {
            if (!this.slugModified) { // Ajouté
                this.form.get('slug').setValue(this.slugify(val), {emitEvent: false});
            }
        });

        // Ajouté
        this.form.get('slug').valueChanges.subscribe(val => {
            this.slugModified = true;
            this.form.get('slug').setValue(this.slugify(val), {emitEvent: false});
        });
    }

    slugify(text: string): string {
        return text.toLowerCase().replace(/\s+/g, '-');
    }

    onSubmit() {
        console.log(this.form.value);
    }

}
