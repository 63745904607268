import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Category} from '../../../../../../models/category';
import categories from 'src/assets/categories_fr.json';
import taxes from 'src/assets/taxes.json';
import {ShopItem} from 'src/models/shop_item';

@Component({
    selector: 'app-invoice-item-add',
    templateUrl: './invoice-item-add.component.html',
    styleUrls: ['./invoice-item-add.component.scss']
})
export class InvoiceItemAddComponent implements OnInit {
    @Input() item: ShopItem | undefined;
    @Input() item_id!: string;
    @Input() isNewItem = false;
    @Output() deleteItem: EventEmitter<void> = new EventEmitter<void>();
    @Output() saveItem: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
    form: FormGroup;

    protected readonly categories: Category[] = categories;
    protected readonly taxes: number[] = taxes;


    ngOnInit() {
        const category = this.categories.find(c => c.id === this.item?.categorie?.id);
        this.form = new FormGroup({
            id: new FormControl<string>(this.item_id),
            description: new FormControl<string>(this.item?.description, Validators.required),
            category: new FormControl<Category>(category, Validators.required),
            brand: new FormControl<string>(this.item?.brand),
            num_serie: new FormControl<string>(this.item?.num_serie),
            quantity: new FormControl<number>(this.item?.quantity ?? 1, [Validators.required, Validators.min(1)]),
            tax: new FormControl<number>(this.item?.tax ?? 20, Validators.required),
            upload_ms_timestamp: new FormControl<number>(this.item?.upload_ms_timestamp),
            amountHT: new FormControl<number>(0, Validators.min(0)),
            amountTVA: new FormControl<number>(0, Validators.min(0)),
            amountTTC: new FormControl<number>(this.item?.amount ?? 0, Validators.min(0))
        });

        this.form.get('amountHT')?.valueChanges.subscribe(value => {
            const tax = this.form.get('tax')?.value / 100;
            const amountTTC = value * (1 + tax);
            const amountTVA = value * tax;
            this.form.get('amountTVA')?.patchValue(parseFloat(amountTVA.toFixed(2)), {emitEvent: false});
            this.form.get('amountTTC')?.patchValue(parseFloat(amountTTC.toFixed(2)), {emitEvent: false});
        });

        this.form.get('amountTVA')?.valueChanges.subscribe(value => {
            const tax = this.form.get('tax')?.value / 100;
            const amountHT = value / tax;
            const amountTTC = amountHT * (1 + tax);
            this.form.get('amountHT')?.patchValue(parseFloat(amountHT.toFixed(2)), {emitEvent: false});
            this.form.get('amountTTC')?.patchValue(parseFloat(amountTTC.toFixed(2)), {emitEvent: false});
        });

        this.form.get('amountTTC')?.valueChanges.subscribe(value => {
            const tax = this.form.get('tax')?.value / 100;
            const amountHT = value / (1 + tax);
            const amountTVA = value - amountHT;
            this.form.get('amountHT')?.patchValue(parseFloat(amountHT.toFixed(2)), {emitEvent: false});
            this.form.get('amountTVA')?.patchValue(parseFloat(amountTVA.toFixed(2)), {emitEvent: false});
        });
        this.form.get('tax')?.valueChanges.subscribe(value => {
            this.form.get('amountTTC').updateValueAndValidity({emitEvent: true});
        });

        this.form.get('amountTTC').updateValueAndValidity({emitEvent: true});
    }


    onDeleteItem() {
        this.deleteItem.emit();
    }

    onItemSave() {
        this.saveItem.emit(this.form);
    }
}
