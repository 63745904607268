import {Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {InvoiceItemAddComponent} from "../invoice-items/invoice-item-add/invoice-item-add.component";
import {ActivatedRoute} from "@angular/router";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {firstValueFrom, Subscription} from "rxjs";
import {Profile} from "../../../../../models/profile";
import countriesResidency from '../../../../../assets/countries-residency.json';
import countriesNationality from '../../../../../assets/countries-nationality.json';
import residencyDocumentType from '../../../../../assets/residency_doc_type.json';
import {AngularFireFunctions} from "@angular/fire/compat/functions";
import {PassportFile} from "../../../../../models/passport_file";
import {LoadingController} from "@ionic/angular";
import {AngularFireStorage} from "@angular/fire/compat/storage";

const lookup = require('country-code-lookup');

@Component({
    selector: 'app-profile-view',
    templateUrl: './profile-view.component.html',
    styleUrls: ['./profile-view.component.scss']
})
export class ProfileViewComponent implements OnInit {

    @Input() user: string;
    @ViewChild('file2') fileInput: ElementRef;
    @ViewChildren('invoiceItemAdd') invoiceItemAdd: QueryList<InvoiceItemAddComponent>;

    refP: Subscription;
    profile: Profile;

    refM: Subscription;
    refAdmin: Subscription;
    admin: any = {};

    passportImages = {};
    residencyImages = {};

    imagePath;
    loading;

    residencyCountryList = countriesResidency;
    nationalityCountryList = countriesNationality;
    documentTypeList = residencyDocumentType;

    constructor(
        private afDb: AngularFireDatabase,
        private fns: AngularFireFunctions,
        private loadingController: LoadingController,
        private afStorage: AngularFireStorage,
        private route: ActivatedRoute
    ) {
    }

    async ngOnInit() {
        if (!this.user) {
            this.route.paramMap.subscribe((params) => {
                this.user = params.get('user');
            });
        }

        this.route.paramMap.subscribe(() => {
            const itemsRefProf = this.afDb.object('users/' + this.user + '/profile');
            this.refP = itemsRefProf.snapshotChanges().subscribe(
                (results) => {
                    this.profile = results.payload.val();
                    console.log('Record-profile', results.payload.val());
                    this.passportImages = this.profile.passportPhotos || {};
                    this.residencyImages = this.profile.residencyPhotos || {};
                    if (this.profile.bank == null) {
                        this.bankProfile();
                    }
                },
                (err) => {
                    console.log(err);
                }
            );

            const itemsRefAdmin = this.afDb.object('users/' + this.user + '/admin');
            this.refAdmin = itemsRefAdmin.snapshotChanges().subscribe({
                next: (results) => {
                    this.admin = results.payload.val();
                    console.log('Record-admin', results.payload.val());
                }
            });

        });
    }

    bankProfile() {
        this.afDb
            .object('users/' + this.user + '/profile/')
            .update({bank: {iban: ''}});
    }

    async onGradeChange($event: string) {
        await this.afDb.object(`users/${this.user}/admin/grade`).set($event);
    }

    async redirectIdVerificationFlow() {
        const callable = this.fns.httpsCallable('ariadnext-createFlowOperator');
        const result = await firstValueFrom(callable({
            uid: this.user,
        }));
        console.log(result);
        window.open(result.data, '_blank');
    }

    downloadPassportReport() {
        const passportReport = this.getLastPassportReport();
        if (!passportReport) {
            alert('No passport report found');
            return;
        }
        const passportReportUrl = passportReport.reportUrl;
        if (!passportReportUrl) {
            alert('No passport report url found');
            return;
        }
        window.open(passportReportUrl, '_blank');
    }

    getLastPassportReport() {
        const reports = this.admin?.ariadnext?.dossiers as Record<string, PassportFile>;
        if (!reports) {
            return;
        }
        const reportsObject = Object.values(reports);
        reportsObject.sort((a, b) => b.created - a.created);
        return reportsObject[0];
    }

    async addProfileImage(files, path) {
        if (files.length === 0) {
            return;
        }
        const file = files[0];
        if (file) {
            if (!/^image\//i.test(file.type)) {
                return;
            }
        }
        this.imagePath = files;
        this.loading = await this.loadingController.create({
            message: 'Uploading image...',
        });
        await this.loading.present();
        const photoId = (+new Date()).toString(12);
        const filePath = this.user + '/' + photoId + '.jpg';
        const ref = this.afStorage.ref(filePath);
        this.afStorage.upload(filePath, file).then(() => {
            ref
                .getDownloadURL()
                .toPromise()
                .then((res) => {
                    this.afDb
                        .object('users/' + this.user + '/profile/' + path + '/' + photoId)
                        .update({id: photoId, uri: res, date: new Date()});
                    this.loading.dismiss();
                });
        });
    }

    removeProfileImage(image, path) {
        this.afDb
            .object('users/' + this.user + '/profile/' + path + '/' + image.id)
            .remove();
        this.afStorage.refFromURL(image.uri).delete();
    }

    async onDocumentTypeSelection($event: CustomEvent) {
        const docType = $event.detail.value;
        await this.afDb.object(`users/${this.user}/profile/residencyDocumentType`).set(docType);
    }

    async onResidencyCountrySelection($event: CustomEvent) {
        const residencyCountry = $event.detail.value;
        await this.afDb.object(`users/${this.user}/profile/residencyCountry`).set(residencyCountry);
    }

    updateProfile(profileId, profileVal) {
        console.log(profileVal);
        this.afDb
            .object('users/' + this.user + '/')
            .update({[profileId]: profileVal});
    }

    setResidencyValid() {
        this.afDb
            .object('users/' + this.user + '/profile/')
            .update({residencyState: 'valid'});
    }

    setResidencyPending() {
        this.afDb
            .object('users/' + this.user + '/profile/')
            .update({residencyState: 'pending'});
    }

    setResidencyRejected() {
        this.afDb
            .object('users/' + this.user + '/profile/')
            .update({residencyState: 'rejected'});
    }

    getCountry(iso) {
        try {
            return lookup.byIso(iso)?.country;
        } catch (e) {
            return '';
        }
    }


}
