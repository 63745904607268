import {ModalController} from '@ionic/angular';
import {Component, Input, OnInit} from '@angular/core';
import anomaliesRaw from '../../../../../../assets/anomalies.json';

@Component({
    selector: 'app-anomaly-dialog',
    templateUrl: './anomaly-dialog.component.html',
    styleUrls: ['./anomaly-dialog.component.css']
})
export class AnomalyDialogComponent implements OnInit {
    anomalies: { id: number; name: string }[] = anomaliesRaw;
    @Input('customAnomaly') customAnomaly: string;
    @Input('selectedAnomalyId') selectedAnomalyId: number;

    constructor(private modalCtrl: ModalController) {
    }

    ngOnInit() {
        console.log("Selected", this.selectedAnomalyId);
    }

    selectAnomaly(anomaly: number) {
        this.customAnomaly = '';
        this.selectedAnomalyId = anomaly;
    }

    async submitAnomaly() {
        await this.modalCtrl.dismiss({anomalyId: this.selectedAnomalyId, customAnomaly: this.customAnomaly});
    }

    onInputFocus() {
    }
}
