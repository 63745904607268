import {HttpClient} from '@angular/common/http';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {firstValueFrom, Subscription} from 'rxjs';
import {ShopInvoice} from '../../../../models/shop_invoice';
import {Fichier, FileCategory} from 'src/models/fichier';
import {WindowComponent} from '../window/window.component';
import {NbWindowService} from '@nebular/theme';
import {MatCheckboxChange} from '@angular/material/checkbox';
import * as buffer from "buffer";

@Component({
    selector: 'app-file-viewer',
    templateUrl: './file-viewer.component.html',
    styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent implements OnInit {
    @ViewChild('rawDataOcrTitle', {static: false}) rawDataOcrTitle: TemplateRef<any>;
    @Input() invoice!: ShopInvoice;
    @Output() invoiceChange: EventEmitter<ShopInvoice> = new EventEmitter<ShopInvoice>();
    @Input() fileCategory: FileCategory = 'invoice';
    @Input() maxFiles?: number;
    @Output() removeFile: EventEmitter<Fichier> = new EventEmitter<Fichier>();
    @Output() ocrCheckBoxChange: EventEmitter<Map<Fichier, boolean>> = new EventEmitter<Map<Fichier, boolean>>();
    files: Fichier[] = [];
    filesType: Map<Fichier, 'image' | 'pdf'> = new Map<Fichier, 'image' | 'pdf'>();
    ocrFiles: Map<Fichier, boolean> = new Map<Fichier, boolean>();

    constructor(private http: HttpClient, private windowService: NbWindowService) {
    }

    async ngOnInit() {
        this.files = Array.from(Object.values(this.invoice.fichiers || {}));
        this.files = this.files.filter(file => (file.fileCategory ?? 'invoice') === this.fileCategory);
        this.files = this.resizeList(this.files, this.maxFiles);

        await this.getFilesType();
    }

    resizeList(list: any[], new_size: number | undefined) {
        if (!new_size || new_size >= list.length) {
            return list;
        }
        return list.slice(0, new_size);
    }

    async getFilesType() {
        const newFiles = [...this.files];
        for (const file of newFiles) {
            if (!this.filesType.has(file)) {
                this.filesType.set(file, await this.getFileType(file));
            }
        }
        if (JSON.stringify(newFiles) !== JSON.stringify(this.files)) {
            this.files = newFiles;
        }
    }

    async getFileType(file: Fichier): Promise<'pdf' | 'image' | undefined> {
        try {
            const response = await firstValueFrom(this.http.head(file?.uri.toString(), {
                observe: 'response'
            }));
            const contentType = response.headers.get('Content-Type');
            if (contentType?.startsWith('image')) {
                return 'image';
            } else if (contentType?.includes('pdf')) {
                return 'pdf';
            }
        } catch (error) {
            console.error(`Failed to fetch head from ${file.uri.toString()}:`, error);
        }

        return undefined;
    }

    onRemoveFile(file: Fichier) {
        this.removeFile.emit(file);
    }

    onOcrAdd(file: Fichier) {
        this.ocrFiles.set(file, true);
        this.ocrCheckBoxChange.emit(new Map([[file, true]]));
    }

    onOcrRemove(file: Fichier) {
        this.ocrFiles.set(file, false);
        this.ocrCheckBoxChange.emit(new Map([[file, false]]));
    }

    onRawData(file: Fichier) {
        this.windowService.open(
            WindowComponent,
            {
                title: `Raw data ${this.invoice.invoice_number}`,
                titleTemplate: this.rawDataOcrTitle,
                windowClass: 'raw-data-ocr',
                closeOnEsc: true,
                context: {
                    data: buffer.Buffer.from(file.ocr, 'base64').toString()
                }
            },
        );
    }

    ocrToggle(file: Fichier, $event: MatCheckboxChange) {
        this.ocrCheckBoxChange.emit(new Map([[file, $event.checked]]));
    }

    onFileClick(file: Fichier) {
        const url = file.uri.toString();
        const type = this.filesType.get(file);
        const extension = type === 'pdf' ? 'pdf' : 'jpg';
        this.http.get(url, {responseType: 'blob'})
            .subscribe({
                    next: (blob) => {
                        const fileUrl = URL.createObjectURL(blob);

                        const link = document.createElement('a');
                        link.href = fileUrl;
                        link.target = '_blank';
                        link.download = `${file.id}.${extension}`;
                        document.body.appendChild(link);

                        link.click();

                        document.body.removeChild(link);

                        URL.revokeObjectURL(fileUrl);
                    },
                    error: (error) => {
                        console.error('Erreur while downloading file :', error);
                    }
                }
            );
    }
}
