<h2 mat-dialog-title>Introducer</h2>

<mat-dialog-content [formGroup]="form">
    <mat-form-field>
        <mat-label>Nom introducer</mat-label>
        <input matInput formControlName="nom">
    </mat-form-field>
    <br/>
    <mat-form-field>
        <mat-label>Slug introducer</mat-label>
        <input matInput formControlName="slug">
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>Annuler</button>
    <button mat-button (click)="onSubmit()" [disabled]="form.invalid">Valider</button>
</mat-dialog-actions>
