import {Injectable} from '@angular/core';
import {firstValueFrom, Observable} from 'rxjs';
import {DatabaseService} from '../database/database.service';
import {Profile} from '../../../models/profile';
import firebase from 'firebase/compat/app';
import {Introducer, IntroducerTrip, IntroducerUser} from 'src/models/marketers';
import {SnapshotAction} from '@angular/fire/compat/database';


interface IntroducerUserRefundDoneParams {
    userId: string;
    tripId: string;
    user: Profile;
    introducer: string;
    refundAmount: number;
    bve: string;
    estimatedGovRefundDate: number;
    estimatedCompRefundDate: number;
    commissionAmount: number;
}

interface IGetIntroducerParams {
    plateform?: string;
    refresh?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class IntroducerService {
    introducers: Map<string, Introducer>;

    constructor(private db: DatabaseService) {
    }

    async getIntroducers(options: IGetIntroducerParams = {}) {
        if (options.refresh || !this.introducers) {
            const path = options.plateform ? `marketers/list/${options.plateform}` : 'marketers/list';
            let snapshot;
            if (options.plateform) {
                snapshot = await firstValueFrom(this.db.getPath<Introducer>(path));
                this.introducers = new Map(Object.entries({[options.plateform]: (snapshot.payload.val() ?? {})}));
            } else {
                snapshot = await firstValueFrom(this.db.getPath<Record<string, Introducer>>(path));
                this.introducers = new Map(Object.entries(snapshot.payload.val() ?? {}));
            }
            console.log('Introducers', this.introducers);
        }
        return this.introducers;
    }

    async getIntroducerUsers(introducerId: string): Promise<Map<string, IntroducerUser>> {
        console.log(`marketers/referred_users/${introducerId}`);
        const snapshot = await firstValueFrom(this.db.getPath<Record<string, IntroducerUser>>(`marketers/referred_users/${introducerId}`));
        return new Map(Object.entries(snapshot.payload.val() ?? {}));
    }

    listenIntroducerTripDetail(introducerId: string, userId: string, tripId: string): Observable<SnapshotAction<IntroducerTrip>> {
        return this.db.getPath<IntroducerTrip>(`marketers/referred_users/${introducerId}/${userId}/trips/${tripId}`);
    }

    updateIntroducerTripDetail(introducerId: string, userId: string, tripId: string, data: Partial<IntroducerTrip>) {
        return this.db.update(`marketers/referred_users/${introducerId}/${userId}/trips/${tripId}`, data);
    }

    get introducerList(): string[] {
        return Array.from(this.introducers.keys());
    }

    async getIntroducerPlateform(userId: string): Promise<string> {
        const snapshot = await firstValueFrom(this.db.getPath<string>(`roles/introducer/${userId}/platform`));
        return snapshot.payload.val();
    }

    async setUserIntroducer(userId: string, introducer: string) {
        await Promise.all([
            this.db.update(`users/${userId}/meta/referral/`, {
                id_referrer: introducer,
                referrer_type: 'marketer',
                referrer_source: 'operator',
                referrer_date: Date.now()
            }),
            this.db.update(`marketers/list/${introducer}/`, {
                total_users: firebase.database.ServerValue.increment(1)
            }),
            this.db
                .update(`marketers/referred_users/${introducer}/${userId}/`, {
                    date: Date.now(),
                })
        ]);

    }

    async onUserRefundDone(params: IntroducerUserRefundDoneParams) {
        const totalRefund = params.refundAmount ?? 0;
        const summaryPromise = this.db
            .update(`marketers/list/${params.introducer}/`,
                {
                    total_refund: firebase.database.ServerValue.increment(totalRefund),
                    total_refund_count: firebase.database.ServerValue.increment(1)
                });
        const userInfoPromise = this.db
            .update(`marketers/referred_users/${params.introducer}/${params.userId}/`,
                {
                    first_name: params.user.first_name ?? '',
                    last_name: params.user.last_name ?? '',
                });
        const tripInfoPromise = this.db
            .update(`marketers/referred_users/${params.introducer}/${params.userId}/trips/${params.tripId}/`,
                {
                    bve: params.bve ?? 'No BVE',
                    refund: totalRefund,
                    refund_date: Date.now(),
                    commission_amount: params.commissionAmount || 0,
                    estimated_gov_refund_date: params.estimatedGovRefundDate || 0,
                    estimated_comp_refund_date: params.estimatedCompRefundDate || 0,
                });
        await Promise.all([summaryPromise, userInfoPromise, tripInfoPromise]);
    }
}
