import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Component, Inject, OnInit} from '@angular/core';

@Component({
    selector: 'app-error-dialog',
    templateUrl: 'error-dialog.component.html',
})
export class ErrorDialogComponent implements OnInit {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        this.data = this.parseErrorObject(this.data);
        console.log(this.data);
    }

    tryParseJSON(jsonString: string) {
        try {
            const parsed = JSON.parse(jsonString);
            if (parsed && typeof parsed === 'object') {
                return parsed;
            }
        } catch (e) {
            // Ce n'était pas un JSON stringifié
        }
        return jsonString; // Retourne la chaîne originale si le parsing n'est pas possible
    }

    parseErrorObject(obj: any) {
        for (const key in obj) {
            if (typeof obj[key] === 'string') {
                obj[key] = this.tryParseJSON(obj[key]);
            } else if (typeof obj[key] === 'object') {
                obj[key] = this.parseErrorObject(obj[key]); // Récurse dans les sous-objets
            }
        }
        return obj;
    }


}
