import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-notification-dialog',
    templateUrl: './notification-dialog.component.html',
    styleUrls: ['./notification-dialog.component.css']
})
export class NotificationDialogComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<NotificationDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,) {
    }

    ngOnInit(): void {
    }

}
