import {inject} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';

export const authGuard = async () => {
    const authService = inject(AuthService);
    const router = inject(Router);

    return authService.isOperator().then(operator => {
        if (!operator) {
            return router.parseUrl('/auth');
        }
        return true;
    });
};
export const authIntroducerGuard = async () => {
    const authService = inject(AuthService);
    const router = inject(Router);

    return authService.isIntroducer().then(introducer => {
        if (!introducer) {
            return router.parseUrl('/auth/introducer');
        }
        return true;
    });
};
