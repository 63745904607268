import { Component, OnInit } from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.page.html',
  styleUrls: ['./admin.page.scss'],
})
export class AdminPage implements OnInit {

  constructor(
      public afAuth: AngularFireAuth,
  ) { }

  async logout() {
    await this.afAuth.signOut();

  }

  ngOnInit() {
    this.afAuth.idToken.subscribe(value => {
      console.log('User token: ' + value);
    });
  }

}
