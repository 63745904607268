import {Component, Input, OnInit} from '@angular/core';
import {Trip} from 'src/models/trip';
import {firstValueFrom} from "rxjs";
import {AngularFireFunctions} from "@angular/fire/compat/functions";
import {MatDialog} from "@angular/material/dialog";
import {StatusCompta} from 'src/models/shop_invoice';
import {ErrorDialogComponent} from 'src/app/widgets/error-dialog/error-dialog.component';
import {LoadingController} from "@ionic/angular";
import {PassportFile} from "../../../../../models/passport_file";
import {Profile} from "../../../../../models/profile";
import * as moment from "moment/moment";

@Component({
    selector: 'app-compta-creation',
    templateUrl: './compta-creation.component.html',
    styleUrls: ['./compta-creation.component.css']
})
export class ComptaCreationComponent implements OnInit {

    @Input()
    buttonClicked: boolean = false;

    @Input()
    item: Trip;

    @Input()
    trip: string;

    @Input()
    statusCompta: StatusCompta;

    @Input()
    admin: any;

    @Input() profile: Profile;
    @Input() meta: any;

    constructor(
        private fns: AngularFireFunctions,
        private errorDialog: MatDialog,
        private loadingController: LoadingController,
    ) {
    }

    ngOnInit(): void {
    }

    getLastPassportReport() {
        const reports = this.admin?.ariadnext?.dossiers as Record<string, PassportFile>;
        if (!reports) {
            return;
        }
        const reportsObject = Object.values(reports);
        reportsObject.sort((a, b) => b.created - a.created);
        return reportsObject[0];
    }

    async addToDrive() {
        const loading = await this.loadingController.create({
            message: 'Uploading to Google Drive...',
            // duration: 10000
        });
        await loading.present();
        const passportReport = this.getLastPassportReport();
        const callable = this.fns.httpsCallable('functions-pushToDrive');
        const result = await firstValueFrom(callable({
            profile: this.profile,
            trip: this.item,
            meta: this.meta,
            passportReport
        }));
        loading.message = JSON.stringify(result);
        setTimeout(async () => {
            await loading.dismiss();
        }, 1500);
    }


    async pennylaneCreation(resume = false) {
        const invoices = this.item.shop_invoices;
        const invoicesArray = Object.values(invoices);
        const validInvoices = invoicesArray.filter((invoice) => invoice.approved === 'valid');
        const haveVat = validInvoices.every((invoice) => !!invoice.vatNumber);

        if (!haveVat) {
            return alert('Some invoices do not have a VAT number. Please check them and try again.');
        }
        const vats = validInvoices.map((invoice) => invoice.vatNumber);
        console.log("vats", vats);
        // const checkVat = this.fns.httpsCallable("compta-bulkCheckVAT");
        // const results = await firstValueFrom(checkVat({vats}));
        // console.log(results);
        // const hasInvalidVat = results.some(result =>
        // Object.values(result).some(value => value === false));

        // if (hasInvalidVat) {
        // return alert('Some invoices have an invalid VAT number. Please check them and try again.');
        // }

        const dateCorrect = validInvoices.every((invoice) => {
            if (!invoice.date) {
                return false;
            }
            const invoiceDate = moment(invoice.date, "DD/MM/YYYY");
            const checks = {
                isValid: invoiceDate.isValid(),
                isSameOrAfterMinus90days: invoiceDate.isSameOrAfter(moment().subtract(90, 'days')),
                isSameOrBeforeToday: invoiceDate.isSameOrBefore(moment())
            };
            console.log(invoiceDate);
            console.log(checks);
            return Object.values(checks).every((check) => check);
        });

        if (!dateCorrect) {
            return alert('Some invoices do not have a correct date. Please check them and try again.');
        }


        this.buttonClicked = true;

        if (!this.item.overall_invoice?.uri) {
            console.log('addAutomaticOverallInvoice');
            const callable = this.fns.httpsCallable('invoiceGen-generateInvoice');

            try {
                await firstValueFrom(callable({tripId: this.trip}));
            } catch (e) {
                this.errorDialog.open(ErrorDialogComponent, {
                    data: "Error while generating overall invoice"
                });
            }
        }

        const qC = this.fns.httpsCallable('compta-create', {timeout: 300000});
        firstValueFrom(qC({
            tripId: this.trip,
            reset: !resume
        }))
            .then((result) => {
                if (result.success === false) {
                    this.buttonClicked = false;
                    this.errorDialog.open(ErrorDialogComponent, {
                        data: result
                    });
                }
                console.log(result);
                console.log('end');
            })
            .catch((error) => {
                this.errorDialog.open(ErrorDialogComponent, {
                    data: error
                });
                console.error(error);
            });
    }


}
