import {Component, OnDestroy, OnInit} from '@angular/core';

import {AngularFireDatabase} from '@angular/fire/compat/database';
import {LoadingController, ModalController} from '@ionic/angular';

import {map} from 'rxjs/operators';
import {ViewPage} from '../view/view.page';

import {environment} from '../../../../environments/environment';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {CustomsErrorComponent} from '../customs-error/customs-error.component';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {IntroducerService} from '../../../services/introducer/introducer.service';

const {version} = require('../../../../../package.json');

@Component({
    selector: 'app-list',
    templateUrl: './list.page.html',
    styleUrls: ['./list.page.scss'],
})
export class ListPage implements OnInit, OnDestroy {
    activeStatus;
    statuses = environment.statuses;
    environment = {
        VERSION: version,
    };

    list = [];
    ref;

    stat0;
    stat1;
    stat2;
    stat3;
    stat4;
    stat5;
    stat6;
    stat7;
    stat8;
    statAll;

    userId;

    resSort;

    timerQBO: number;
    dateQBO: string;
    refReshtokenDate: Subscription;

    customsError;
    customsErrorQty;
    refCustomsError: Subscription;

    selectedDocumentType: string;

    constructor(
        private afDb: AngularFireDatabase,
        private fns: AngularFireFunctions,
        public modalController: ModalController,
        private loadingController: LoadingController,
        private matDialog: MatDialog,
        private introducerService: IntroducerService
    ) {
    }

    async presentModal(user, trip) {
        const modal = await this.modalController.create({
            component: ViewPage,
            cssClass: 'view-model-class',
            keyboardClose: false,
            backdropDismiss: false,
            componentProps: {
                user,
                trip,
            },
        });
        return await modal.present();
    }

    ngOnInit() {
        console.log('List');
        this.activeStatus = 99;
        this.userId = '';
        this.setFetcher(99);
        this.setFetcherByUserId('');
        const customsErrorRef = this.afDb.object('customs/error');
        this.refCustomsError = customsErrorRef
            .snapshotChanges()
            .subscribe((value) => {
                this.customsError = value.payload.val();
                this.customsErrorQty = Object.keys(this.customsError ?? {}).length;
            });

        const refreshToken = this.afDb.object('config/quickbooks/');
        this.refReshtokenDate = refreshToken
            .snapshotChanges()
            .subscribe((value) => {
                const qboConfig: any = value.payload.val();
                this.dateQBO = qboConfig.refresh_token;
            });
        this.timerQBO = this.calculTime(this.dateQBO);
        this.userId = this.setFetcherByUserId('');
        this.introducerService.getIntroducers();

        const itemsRef0 = this.afDb
            .list('trips/', (ref) => ref.orderByChild('status').equalTo(0))
            .snapshotChanges();
        itemsRef0.subscribe((results) => {
            this.stat0 = results.length;
        });

        const itemsRef1 = this.afDb
            .list('trips/', (ref) => ref.orderByChild('status').equalTo(1))
            .snapshotChanges();
        itemsRef1.subscribe((results) => {
            this.stat1 = results.length;
        });

        const itemsRef2 = this.afDb
            .list('trips/', (ref) => ref.orderByChild('status').equalTo(2))
            .snapshotChanges();
        itemsRef2.subscribe((results) => {
            this.stat2 = results.length;
        });

        const itemsRef3 = this.afDb
            .list('trips/', (ref) => ref.orderByChild('status').equalTo(3))
            .snapshotChanges();
        itemsRef3.subscribe((results) => {
            this.stat3 = results.length;
        });

        const itemsRef4 = this.afDb
            .list('trips/', (ref) => ref.orderByChild('status').equalTo(4))
            .snapshotChanges();
        itemsRef4.subscribe((results) => {
            this.stat4 = results.length;
        });

        const itemsRef5 = this.afDb
            .list('trips/', (ref) => ref.orderByChild('status').equalTo(5))
            .snapshotChanges();
        itemsRef5.subscribe((results) => {
            this.stat5 = results.length;
        });

        const itemsRef6 = this.afDb
            .list('trips/', (ref) => ref.orderByChild('status').equalTo(6))
            .snapshotChanges();
        itemsRef6.subscribe((results) => {
            this.stat6 = results.length;
        });

        const itemsRef7 = this.afDb
            .list('trips/', (ref) => ref.orderByChild('status').equalTo(7))
            .snapshotChanges();
        itemsRef7.subscribe((results) => {
            this.stat7 = results.length;
        });

        const itemsRef8 = this.afDb
            .list('trips/', (ref) => ref.orderByChild('status').equalTo(8))
            .snapshotChanges();
        itemsRef8.subscribe((results) => {
            this.stat8 = results.length;
        });
        const itemsRefAll = this.afDb
            .list('trips/', (ref) => ref.orderByChild('status'))
            .snapshotChanges();
        itemsRefAll.subscribe((results) => {
            this.statAll = results.length;
        });

    }

    sortResolve(obj, path) {
        return path
            .split('.')
            .reduce((prev, curr) => (prev ? prev[curr] : null), obj || self);
    }

    sortString(resolve) {
        if (this.resSort === resolve) {
            this.resSort = '+' + resolve;
            // vice versa
            this.list.sort((a, b) => {
                if (!this.sortResolve(a, resolve)) {
                    return 1;
                }
                if (!this.sortResolve(b, resolve)) {
                    return -1;
                }
                // eslint-disable-next-line max-len
                return this.sortResolve(a, resolve) === this.sortResolve(b, resolve)
                    ? 0
                    : +(this.sortResolve(a, resolve) < this.sortResolve(b, resolve)) ||
                    -1;
            });
        } else {
            this.resSort = resolve;
            this.list.sort((a, b) => {
                if (!this.sortResolve(a, resolve)) {
                    return 1;
                }
                if (!this.sortResolve(b, resolve)) {
                    return -1;
                }
                // eslint-disable-next-line max-len
                return this.sortResolve(a, resolve) === this.sortResolve(b, resolve)
                    ? 0
                    : +(this.sortResolve(a, resolve) > this.sortResolve(b, resolve)) ||
                    -1;
            });
        }
    }

    calcTotalRefundable(data) {
        let total = 0;
        if (!data) {
            return;
        }
        data.forEach((value) => {
            // console.log(value);
            total += value.value.amount;
        });
        return this.calcRefundable(total);
    }

    calcRefundable(amount: number): string {
        const vat = (amount / 1.2) * 0.2;
        const fee = vat * 0.15;
        return (vat - fee).toFixed(2);
    }

    async showCustomsError() {
        this.matDialog.open(CustomsErrorComponent, {
            height: '30em',
            width: '40em',
            data: {
                error: this.customsError,
            },
        });
    }

    setFetcher(status) {
        this.userId = '';
        this.activeStatus = status;
        this.ref?.unsubscribe();

        const itemsRef =
            status === 99
                ? this.afDb.list('trips/', (ref) => ref.orderByChild('created_at'))
                : this.afDb.list('trips/', (ref) =>
                    ref.orderByChild('status').equalTo(status)
                );

        this.ref = itemsRef.snapshotChanges().pipe(
            map((items) =>
                // <== new way of chaining
                items.map((a) => {
                    const value = a.payload.val();
                    const key = a.payload.key;
                    return {key, value}; // or {key, ...data} in case data is Obj
                })
            )
        ).subscribe((results) => {
            this.list = results.filter((v) => v.key != 'undefined');
        });
    }

    setFetcherByUserId(uid: string) {
        this.activeStatus = undefined;
        this.ref?.unsubscribe();

        const itemsRef = this.afDb.list('trips/', (ref) =>
            ref.orderByChild('user').equalTo(uid)
        );

        this.ref = itemsRef.snapshotChanges().pipe(
            map((items) =>
                // <== new way of chaining
                items.map((a) => {
                    const value = a.payload.val();
                    const key = a.payload.key;
                    return {key, value}; // or {key, ...data} in case data is Obj
                })
            )
        ).subscribe((results) => {
            this.list = results.filter((v) => v.key != 'undefined');
        });
    }

    export() {
        const exportC = [];
        exportC.push([
            'Trip name',
            'Trip Id',
            'First Name',
            'Last Name',
            'Email address',
            'User Id',
            'Date of birth',
            'Nationality',
            'Country',
            'Phone Number',
            'Passport Number',
            'Date of passport Expiry',
            'Residency certificate date of expiry',
            // "Creation profile date",
            // "Last login",
            'Passport: box checked if uploaded',
            // "Date of passport upload",
            'Residency certificate: box checked if upload',
            'Type of document',
            // "Date of residency certificate upload",
            'Bank Details',
            // "Trip name",
            'Arrival Country',
            'Arrival Date',
            'Departure Country',
            'Departure Date',
            'Residence Country',
            'Created At',
            'Trip Status',
            'Total VAT to be refund',
            'Tax Free Form: box checked if uploaded',
            'Date of Tax free form uploaded',
            'Tax free Form Number',
            'Total VAT Amount',
            'Total TTC Amount',
            'Total Refunded VAT',
            'Refund Date',
        ]);

        this.list.forEach((item) => {
            const rowL = [];
            rowL.push(item.value.name);
            rowL.push(item.key);
            rowL.push(item.value.profile.first_name);
            rowL.push(item.value.profile.last_name);
            rowL.push(item.value.profile.email);
            rowL.push(item.value.user);
            // rowL.push(moment(item.value.profile.dob).format("MMM. Do YYYY"));
            rowL.push(item.value.profile.dob);
            rowL.push(item.value.profile.nationality);
            rowL.push(item.value.profile.country);
            rowL.push(item.value.profile.phone);
            rowL.push(item.value.profile.passport_number);
            rowL.push(item.value.profile.passport_expiration);
            rowL.push(item.value.profile.residency_expiration);
            rowL.push(item.value.profile.residencyDocumentType);
            // rowL.push(moment(item.value.profile.created_ts).format("MMM. Do YYYY"));
            // rowL.push(moment(item.value.profile.lastlogin_ts).format("MMM. Do YYYY"));

            rowL.push(!!item.value.profile.passportReady ? '+' : '-');
            // rowL.push(moment(item.value.profile.lastlogin_ts).format("MMM. Do YYYY"));
            rowL.push(!!item.value.profile.residencyReady ? '+' : '-');
            // rowL.push(
            //   moment(item.value.profile.residencyReady_ts).format("MMM. Do YYYY")
            // );

            if (item.value.profile.bank) {
                rowL.push(
                    // item.value.profile.bank.country +
                    //   " " +
                    //   item.value.profile.bank.holder +
                    //   " " +
                    item.value.profile.bank.iban +
                    ' ' +
                    item.value.profile.bank.swift
                );
            } else {
                rowL.push('');
            }

            // rowL.push(item.value.name);
            rowL.push(item.value.arrival_country);
            rowL.push(item.value.arrival_date);
            rowL.push(item.value.departure_country);
            rowL.push(item.value.departure_date);
            rowL.push(item.value.residence_country);
            rowL.push(item.value.created_at);
            rowL.push(this.statuses[item.value.status]);

            const rare = [];
            try {
                const workWith = Object.entries(item.value.invoices);
                console.log(workWith);
                if (workWith.length) {
                    workWith.forEach((val) => {
                        rare.push({value: val[1]});
                    });
                }
            } catch (err) {
            }
            console.log(rare);
            rowL.push(this.calcTotalRefundable(rare) + '€');
            rowL.push(item.value.taxform && item.value.taxform.id ? '+' : '-');

            if (item.value.taxform) {
                rowL.push(item.value.taxform.date);
            } else {
                rowL.push('');
            }

            if (item.value.taxform && item.value.taxform.docId) {
                rowL.push(item.value.taxform.docId);
                rowL.push(parseFloat(item.value.taxform.vatAmount));
                rowL.push(parseFloat(item.value.taxform.totalTTC));
                rowL.push(parseFloat(item.value.taxform.dtaxAmount));
                rowL.push(item.value.taxform.refundDate);
            } else {
                rowL.push('');
            }

            exportC.push(rowL);
        });

        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(exportC);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */

        const fileName = () => {
            const today = new Date().toLocaleDateString('fr');
            let name = '';
            if (
                this.activeStatus >= 0 &&
                this.activeStatus <= this.statuses.length - 1
            ) {
                name = `${this.statuses[this.activeStatus]}_${today}.xlsx`;
            } else if (this.activeStatus === 99) {
                name = `All-trips_${today}.xlsx`;
            } else if (this.activeStatus === undefined && this.userId) {
                name = `uid_${this.userId}_${today}.xlsx`;
            } else {
                name = `All-trips_${today}.xlsx`;
            }
            return name;
        };
        XLSX.writeFile(wb, fileName());

        // XLSX.writeFile(
        //   wb,
        //   this.statuses[this.activeStatus] +
        //     " - " +
        //     moment().format("d-m-Y") +
        //     ".xlsx"
        // );
    }

    async export_profiles() {
        const loading = await this.loadingController.create({
            message: 'Extraction of profiles in progress',
            // duration: 10000
        });
        await loading.present();

        const exportC = [];
        exportC.push(['Email address', 'First Name', 'Last Name', 'Phone Number']);

        const profiles = this.fns.httpsCallable('getData');
        const result = await profiles({path: '/users'}).toPromise();
        const array = Object.values(result);
        array.forEach((element: any) => {
            const rowL = [];
            if (typeof element.profile === 'undefined') {
                rowL.push('undefined');
            } else {
                rowL.push(element.profile.email);
                rowL.push(element.profile.first_name);
                rowL.push(element.profile.last_name);
                rowL.push(element.profile.phone);
            }
            exportC.push(rowL);
        });

        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(exportC);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        await loading.dismiss();
        /* save to file */
        XLSX.writeFile(wb, 'all_profiles - ' + moment().format('d-m-Y') + '.xlsx');
    }

    calculTime(resetDate: any) {
        const dateBase = new Date(resetDate);
        const today = new Date();
        const diffTemps = today.getTime() - dateBase.getTime();
        const diffJours = diffTemps / (1000 * 3600 * 24);
        return 101 - Math.round(diffJours);
    }

    ngOnDestroy(): void {
        this.refCustomsError.unsubscribe();
    }
}