import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {authGuard, authIntroducerGuard} from './guards/auth.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'auth',
        pathMatch: 'full'
    },
    {
        path: 'auth',
        loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthPageModule)
    },
    {
        path: 'admin',
        canActivate: [authGuard],
        canActivateChild: [authGuard],
        data: {
            title: 'Operator Area'
        },
        loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminPageModule)
    },
    {
        path: 'introducer',
        canActivate: [authIntroducerGuard],
        canActivateChild: [authIntroducerGuard],
        data: {
            title: 'Introducer Area'
        },
        loadChildren: () => import('./pages/introducer-page/introducer-page.module').then(m => m.IntroducerPageModule),
    },

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
