// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyD3E1C3ZOznnfaf6vBb8kuVNot34nUnCBk',
    authDomain: 'dtaxback-staging.firebaseapp.com',
    databaseURL: 'https://dtaxback-staging-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'dtaxback-staging',
    storageBucket: 'dtaxback-staging.appspot.com',
    messagingSenderId: '1038568172786',
    appId: '1:1038568172786:web:be9548e5dde1374493746b',
    measurementId: 'G-YT9E2K445X'
  },
  // eslint-disable-next-line max-len
  statuses: ['Trip created (no invoices)', '1st invoice Added', 'Tax form requested', 'Download tax free form / Scan your STAMPED tax free form', 'Refund under way / Waiting for bank details', 'Refund done', 'Manual validation needed' , 'Trip archived', 'Bve Error']
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
