<div>
    <ion-button [disabled]="item.status < 4 || buttonClicked"
                color="success" (click)="pennylaneCreation()">
        SEND TO PENNYLANE
    </ion-button>
    <ion-button (click)="pennylaneCreation(true)"
                [disabled]="item.status < 4 || buttonClicked"
                color="warning">
        RESUME PENNYLANE
    </ion-button>
    <br/>
    <ion-col *ngIf="item.status_compta !== null">
        <ion-row>
            <b>Processing of Pennylane requests :</b>
        </ion-row>
        <ion-row>
            <app-compta-status
                    [status]="statusCompta?.create_customer"></app-compta-status>
            <ion-text class="center-label">Create the customer</ion-text>
        </ion-row>
        <ion-row>
            <app-compta-status
                    [status]="statusCompta?.create_customer_invoice"></app-compta-status>
            <ion-text class="center-label">Create the customer invoice
            </ion-text>
        </ion-row>
        <ng-container *ngFor="let invoice of (item.shop_invoices | keyvalue); let i = index;">
            <ion-row *ngIf="invoice.value.status_qbo !== null">
                <app-compta-status *ngIf="invoice.value.approved !== 'anomaly'"
                                   [status]="invoice.value.status_compta?.create_bill"></app-compta-status>
                <ion-text *ngIf="invoice.value.approved !== 'anomaly'" class="center-label">
                    Importing {{ invoice.value.shop_name }} {{ invoice.value.invoice_number }} invoice
                </ion-text>
            </ion-row>
        </ng-container>
        <ion-row>
            <app-compta-status [status]="statusCompta?.create_journal"></app-compta-status>
            <ion-text class="center-label">Create the journal</ion-text>
        </ion-row>
        <ion-row>
            <app-compta-status [status]="statusCompta?.create_journal_bank"></app-compta-status>
            <ion-text class="center-label">Create the journal bank</ion-text>
        </ion-row>
        <ion-row>
            <app-compta-status [status]="statusCompta?.push_to_drive"></app-compta-status>
            <ion-text class="center-label">Push to drive</ion-text>
        </ion-row>
        <ion-row>
            <app-compta-status [status]="statusCompta?.bank_make_payment"></app-compta-status>
            <ion-text class="center-label">Process payment</ion-text>
        </ion-row>
    </ion-col>
</div>
