import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-grade-selector',
    templateUrl: './grade-selector.component.html',
    styleUrls: ['./grade-selector.component.css']
})
export class GradeSelectorComponent implements OnChanges {

    @Input() grade: string;
    @Input() disabled: boolean;
    @Output() gradeChange = new EventEmitter<string>();

    grades = [
        {label: 'Standard', value: 'standard'},
        {label: 'Premium', value: 'premium'},
        {label: 'VIP', value: 'vip'},
        {label: 'VVIP', value: 'vvip'}
    ];

    selectedGrade: string = 'regular';

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.grade) {
            this.selectedGrade = this.grade || 'standard';
            console.log("Grade updated: ", this.grade);
        }
    }

    onSelect($event: CustomEvent) {
        this.selectedGrade = $event.detail.value;
        this.gradeChange.emit(this.selectedGrade);
        console.log("Grade selected: ", this.selectedGrade);

    }
}
