import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-doc-status',
    templateUrl: './doc-status.component.html',
    styleUrls: ['./doc-status.component.css']
})
export class DocStatusComponent implements OnChanges {

    @Input() status: "valid" | "pending" | "rejected" | "expired";
    color: string;
    text: string;
    tipText: string;

    constructor() {
    }

    onStatusChange(): void {
        switch (this.status) {
            case 'valid':
                this.color = 'success';
                this.text = "Valid";
                this.tipText = "Document is valid.";
                break;
            case 'pending':
                this.color = 'warning';
                this.text = "Pending";
                this.tipText = "Document is pending.";
                break;
            case 'rejected':
                this.color = 'danger';
                this.text = "Rejected";
                this.tipText = "Document is rejected.";
                break;
            case 'expired':
                this.color = 'danger';
                this.text = "Expired";
                this.tipText = "Document is expired.";
                break;
            default:
                this.color = undefined;
                this.text = "Unknown";
                this.tipText = "Document has no status.";
                break;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.status) {
            this.onStatusChange();
        }
    }
}