<div>
  <div>
    <mat-card *ngFor="let trip of data?.error | keyvalue">
      <mat-card-title *ngIf="trip.value?.user">User: {{trip?.value?.user}}</mat-card-title>
      <mat-card-title *ngIf="trip.value?.error">{{trip?.value?.error}}</mat-card-title>
      <mat-card-subtitle *ngIf="trip.value?.trip">Trip: {{trip?.value?.trip}}</mat-card-subtitle>
      <mat-divider inset></mat-divider>
      <mat-card-actions>
        <div>
          <button mat-button (click)="openDialog(trip.value)">+ d'info</button>
          <button *ngIf="trip.value?.trame && trip.value?.trip && trip.value?.user" mat-button (click)="sendMail(trip)">Renvoyer le mail</button>
        </div>
        <button mat-button (click)="removeError(trip.key)" color="warn">Supprimer</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>