import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';

import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFireDatabaseModule} from '@angular/fire/compat/database';
import {AngularFireStorageModule} from '@angular/fire/compat/storage';
import {AngularFireModule} from '@angular/fire/compat';
import {HttpClientModule} from '@angular/common/http';
import {MomentModule} from 'ngx-moment';
import {AngularFireFunctionsModule, REGION} from '@angular/fire/compat/functions';
import {
    NbSidebarModule,
    NbThemeModule,
    NbLayoutModule,
    NbChatModule,
    NbWindowModule,
    NbAccordionModule
} from '@nebular/theme';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {MatCardModule} from '@angular/material/card';
import {MatLineModule} from '@angular/material/core';
import {MatBadgeModule} from '@angular/material/badge';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {CustomsStubComponent} from './pages/admin/customs-stub/customs-stub.component';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {CustomsErrorComponent} from './pages/admin/customs-error/customs-error.component';
import {MatDividerModule} from '@angular/material/divider';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {InfoErrorComponent} from './pages/admin/customs-error/info-error/info-error.component';
import {MatRadioModule} from '@angular/material/radio';
import {NgxFileDropModule} from 'ngx-file-drop';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {AdminPageModule} from './pages/admin/admin.module';
import {IntroducerPageComponent} from './pages/introducer-page/introducer-page.component';
import {NotificationsComponent} from './widgets/notifications/notifications.component';
import {MatListModule} from "@angular/material/list";
import {NotificationDialogComponent} from './widgets/notifications/notification-dialog/notification-dialog.component';
import {initializeApp, provideFirebaseApp} from "@angular/fire/app";
import {getFirestore, provideFirestore} from "@angular/fire/firestore";
import {MatTabsModule} from "@angular/material/tabs";
import {NotificationTypeComponent} from './widgets/notifications/notification-type/notification-type.component';
import {ErrorDialogComponent} from './widgets/error-dialog/error-dialog.component';
import {DocStatusComponent} from './widgets/doc-status/doc-status.component';
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
    declarations: [
        AppComponent,
        CustomsStubComponent,
        CustomsErrorComponent,
        InfoErrorComponent,
        IntroducerPageComponent,
        NotificationsComponent,
        NotificationDialogComponent,
        NotificationTypeComponent,
        ErrorDialogComponent,
    ],
    imports: [
        BrowserModule,
        MomentModule,
        HttpClientModule,
        FormsModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        ReactiveFormsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideFirestore(() => getFirestore()),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFireFunctionsModule,
        AngularFirestoreModule.enablePersistence(),
        AngularFireDatabaseModule,
        AngularFireStorageModule,
        NbThemeModule.forRoot(),
        NbWindowModule.forRoot({hasBackdrop: false, closeOnBackdropClick: false}),
        NbSidebarModule.forRoot(),
        NbChatModule.forRoot(),
        BrowserAnimationsModule,
        NbThemeModule.forRoot({name: 'default'}),
        NbLayoutModule,
        NbEvaIconsModule,
        MatCardModule,
        NbChatModule,
        MatLineModule,
        MatBadgeModule,
        MatToolbarModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatButtonModule,
        MatDividerModule,
        MatCardModule,
        MatRadioModule,
        ClipboardModule,
        NgxFileDropModule,
        PdfViewerModule,
        AdminPageModule,
        NbAccordionModule,
        MatListModule,
        MatTabsModule,
        MatTooltipModule,
    ],
    providers: [
        MatDialogModule,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: REGION, useValue: 'europe-west1'}
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
