<div class="bg-white w-96 shadow-md p-5">
    <p class="text-xl font-bold">Notifications</p>
    <mat-tab-group (selectedTabChange)="onTabChange($event)">
        <mat-tab *ngFor="let tab of tabs" [label]="tab">
            <ng-template matTabContent *ngFor="let notification of notifications; let last=last">
                <div (click)="onNotificationClick(notification)" class="py-3">
                    <ion-row>
                        <ion-col size="1">
                            <app-notification-type [level]="notification.level"></app-notification-type>
                        </ion-col>
                        <ion-col size="10">
                            <div class="flex flex-col">
                                <p class="break-words wrap hyphens-auto">{{notification.text}}</p>
                                <ion-button size="small" (click)="removeNotification($event, notification.id)">Done
                                </ion-button>
                            </div>
                        </ion-col>
                    </ion-row>
                </div>
                <mat-divider *ngIf="!last"></mat-divider>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>