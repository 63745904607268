import { Injectable } from '@angular/core';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {firstValueFrom} from 'rxjs';
import {ShopInvoice} from '../../../models/shop_invoice';
import {HttpClient} from "@angular/common/http";

interface OcrResponse {
    success: number;
    min_error?: string;
    error?: string;
    data: {
      invoice: ShopInvoice;
      rawOcr: string;
    };
}

@Injectable({
  providedIn: 'root'
})
export class InvoiceOcrService {

  constructor(private fns: AngularFireFunctions) { }

    async getInvoiceData(base64Datas: string[]): Promise<OcrResponse> {
      const callable = this.fns.httpsCallable('mindee-ocrInvoice');
      return await firstValueFrom<OcrResponse>(callable({files: base64Datas}));
    }
}
