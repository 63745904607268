import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {Subscription} from 'rxjs';
import {KeyValue} from '@angular/common';

interface CustomsHistoryData {
    tripId: string;
    userId: string;
}

@Component({
    selector: 'app-customs-history',
    templateUrl: './customs-history.component.html',
    styleUrls: ['./customs-history.component.css']
})
export class CustomsHistoryComponent implements OnInit, OnDestroy {

    historyRef: Subscription;
    history: Map<number, any>;

    constructor(@Inject(MAT_DIALOG_DATA)
                public data: CustomsHistoryData,
                private fns: AngularFireFunctions,
                private db: AngularFireDatabase) {
        this.historyRef = db.object(`users/${data.userId}/trips/${data.tripId}/customs/history`)
            .valueChanges()
            .subscribe((history: Map<number, any>) => {
                this.history = history;
            });
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        this.historyRef.unsubscribe();
    }

    keyDescOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);

}
