import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {IntroducerService} from 'src/app/services/introducer/introducer.service';
import {IntroducerTrip, IntroducerUser} from '../../../../../models/marketers';

interface IUserTable extends IntroducerUser, IntroducerTrip {
    userId: string;
}

@Component({
    selector: 'app-user-table',
    templateUrl: './user-table.component.html',
    styleUrls: ['./user-table.component.css']
})
export class UserTableComponent implements OnInit {

    @Input() introducerId: string;

    displayedColumns: string[] = ['firstName', 'lastName', 'gov_rf_date', 'comp_rf_date', 'refund', 'fees', 'id'];
    dataSource = new MatTableDataSource();

    constructor(private introducerService: IntroducerService) {
    }

    async ngOnInit(): Promise<void> {
        console.log('Init user table');
        const userTable: IUserTable[] = [];
        const introducerUsers = await this.introducerService.getIntroducerUsers(this.introducerId);
        for (const [userId, user] of introducerUsers) {
            const trips = user.trips;
            const tripMap = new Map(Object.entries(trips ?? {}));
            for (const [, trip] of tripMap) {
                userTable.push({
                    userId,
                    ...user,
                    ...trip
                });
            }
        }
        this.dataSource = new MatTableDataSource(Object.values(userTable));
    }


}
