import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalController } from '@ionic/angular';

interface ConfirmData {
  tripId: string;
  userId: string;
}

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmData,
  private confirmDialog: MatDialog,
  private modalCtrl: ModalController,
  private afDb: AngularFireDatabase) { }

  ngOnInit(): void {
  }

  async close() {
    this.confirmDialog.closeAll();
  }

  async closePage() {
    const modal = await this.modalCtrl.getTop();
    modal.dismiss();
  }

  async deleteTrip() {
    console.log('delete trip confirm');
    const user = this.data.userId;
    const trip = this.data.tripId;
    this.afDb.object('users/' + user + '/trips/' + trip).remove();
    this.close();
    this.closePage();
  }

}
