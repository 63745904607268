<form [formGroup]="form" [ngStyle]="isNewItem ? {'background-color': '#bbe8f9'} : {}">
    <ion-grid>
        <ion-row>
            <ion-col size="12" size-md="3">
                <ion-item>
                    <ion-input formControlName="description" class="ion-text-left"
                               placeholder="Description"></ion-input>
                    <ion-icon *ngIf="form.controls.description.invalid" name="alert-circle-outline"
                              color="danger"></ion-icon>
                </ion-item>
            </ion-col>

            <ion-col size="12" size-md="2">
                <ion-item>
                    <ion-select formControlName="category" class="select_style" interface="action-sheet"
                                placeholder="Category" required>
                        <ion-select-option *ngFor="let option of categories"
                                           [value]="option">{{ option.nom }}</ion-select-option>
                    </ion-select>
                    <ion-icon *ngIf="form.controls.category.invalid" name="alert-circle-outline"
                              color="danger"></ion-icon>
                </ion-item>
            </ion-col>

            <ion-col size="12" size-md>
                <ion-item>
                    <ion-input formControlName="brand" placeholder="Brand"></ion-input>
                </ion-item>
            </ion-col>
            <ion-col size="12" size-md="2">
                <ion-item>
                    <ion-input formControlName="num_serie" placeholder="Serial number"></ion-input>
                </ion-item>
            </ion-col>

            <ion-col size="12" size-md>
                <ion-item>
                    <ion-input formControlName="quantity" class="ion-text-left" min="1" type="number"
                               placeholder="Qty"></ion-input>
                    <ion-icon *ngIf="form.controls.quantity.invalid" name="alert-circle-outline"
                              color="danger"></ion-icon>
                </ion-item>
            </ion-col>

            <ion-col size="12" size-md>
                <ion-item>
                    <ion-select formControlName="tax" class="select_style" interface="popover"
                                placeholder="Tax" required>
                        <ion-select-option *ngFor="let option of taxes" [value]="option">{{ option }}%
                        </ion-select-option>
                    </ion-select>
                </ion-item>
            </ion-col>

            <ion-col size="12" size-md>
                <ion-item>
                    <ion-input formControlName="amountHT" class="ion-text-left" min="0" placeholder="HT"></ion-input>
                </ion-item>
            </ion-col>

            <ion-col size="12" size-md>
                <ion-item>
                    <ion-input formControlName="amountTVA" class="ion-text-left" min="0" placeholder="TVA"></ion-input>
                </ion-item>
            </ion-col>

            <ion-col size="12" size-md>
                <ion-item>
                    <ion-input formControlName="amountTTC" class="ion-text-left" min="0" placeholder="TTC"></ion-input>
                    <ion-icon *ngIf="form.controls.amountTTC.invalid" name="alert-circle-outline"
                              color="danger"></ion-icon>
                </ion-item>
            </ion-col>
            <ion-col size="12" size-md>
                <ion-button
                        matTooltip="Save this item"
                        matTooltipPosition="above"
                        color="light"
                        class="trash-button-item"
                        (click)="onItemSave()"
                        [disabled]="form.invalid"
                        size="small"
                >
                    <ion-icon class="trash-size" name="save-outline" color="primary"></ion-icon>
                </ion-button>
                <ion-button
                        matTooltip="Delete this item"
                        matTooltipPosition="below"
                        color="light"
                        (click)="onDeleteItem()"
                        class="trash-button-item"
                        size="small"
                >
                    <ion-icon class="trash-size" name="trash-outline" color="danger"></ion-icon>
                </ion-button>
            </ion-col>

        </ion-row>
    </ion-grid>
</form>
