import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {StatusComptaType} from "../../../../../models/shop_invoice";

@Component({
    selector: 'app-compta-status',
    templateUrl: './compta-status.component.html',
    styleUrls: ['./compta-status.component.css',],
})
export class ComptaStatusComponent implements OnInit, OnChanges {

    @Input() status: StatusComptaType = 'pending';
    color: string;
    icon: string;

    constructor() {
    }

    initDesign() {
        switch (this.status) {
            case "progress":
                this.color = "primary";
                this.icon = "sync";
                break;
            case "valid":
                this.color = "success";
                this.icon = "checkmark-circle-outline";
                break;
            case "error":
                this.color = "danger";
                this.icon = "close-circle";
                break;
            case "pending":
                this.color = "warning";
                this.icon = "radio-button-off";
                break;
            case "skip":
                this.color = "primary";
                this.icon = "play-skip-forward-circle-outline";
                break;
            default:
                this.color = "medium";
                this.icon = "remove";
                break;
        }
    }

    ngOnInit(): void {
        this.initDesign();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.initDesign();
    }

}
