import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgxFileDropEntry} from 'ngx-file-drop';
import {DomSanitizer} from '@angular/platform-browser';
import {ShopInvoice} from '../../../../models/shop_invoice';
import {RemoteStorageService} from '../../../services/remote_storage/remote-storage.service';
import {LoadingController} from '@ionic/angular';
import { FileSystemFileEntry as NgxFileSystemFileEntry } from 'ngx-file-drop';
import {Fichier, FileCategory} from 'src/models/fichier';


@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent{

  @Input() invoice!: ShopInvoice;
  @Input() fileCategory!: FileCategory;
  @Input() userId!: string;
  @Input() tripId!: string;
  @Input() dragAndDropText = 'Drag and drop files here or click to select files';
  @Output() fileDropped: EventEmitter<Fichier[]> = new EventEmitter<Fichier[]>();
  constructor(private sanitizer: DomSanitizer,
              private loadingController: LoadingController,
              private storageService: RemoteStorageService) {
  }

  public async dropped(files: NgxFileDropEntry[]) {
    const loading = await this.loadingController.create({
        message: 'Uploading files...',
    });
    await loading.present();
    const fichiers: Fichier[] = [];
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as NgxFileSystemFileEntry;
        const uploadResult = await this.storageService.uploadFile({
          file: fileEntry,
          path: `${this.userId}/${this.tripId}/invoices`,
        });
        const fichier: Fichier = {
            id: uploadResult.id,
            uri: uploadResult.url,
            fileCategory: this.fileCategory,
        };
        fichiers.push(fichier);
      }
    }
    this.fileDropped.emit(fichiers);
    await loading.dismiss();
  }

  public fileOver(event: any){
    console.log(event);
  }

  public fileLeave(event: any){
    console.log(event);
  }

}
