<link rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"/>
<div>
    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="w-full">

            <!-- Nom Marketer Column -->
            <ng-container matColumnDef="nomMarketer">
                <th mat-header-cell *matHeaderCellDef> Introducer</th>
                <td mat-cell *matCellDef="let marketer"> {{marketer.name}} </td>
            </ng-container>

            <!-- Nombre de Clicks Column -->
            <ng-container matColumnDef="nombreClicks">
                <th mat-header-cell *matHeaderCellDef> Click-throughs</th>
                <td mat-cell *matCellDef="let marketer"> {{marketer.click_counts ?? 0}} </td>
            </ng-container>

            <!-- Nombre d'Utilisateurs Référés Column -->
            <ng-container matColumnDef="nombreRefers">
                <th mat-header-cell *matHeaderCellDef> No. of registrations</th>
                <td mat-cell *matCellDef="let marketer"> {{marketer.total_users ?? 0}} </td>
            </ng-container>

            <!-- Total refund Column -->
            <ng-container matColumnDef="totalRefund">
                <th mat-header-cell *matHeaderCellDef> No. of refund</th>
                <td mat-cell *matCellDef="let marketer"> {{(marketer.total_refund_count ?? 0)}}</td>
            </ng-container>

            <ng-container matColumnDef="urlInvitation">
                <th mat-header-cell *matHeaderCellDef> Invitation URL</th>
                <td mat-cell *matCellDef="let marketer"> {{marketer.url}} </td>
            </ng-container>

            <!-- Actions Column -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> QR Code</th>
                <td mat-cell *matCellDef="let marketer">
                    <button mat-icon-button (click)="saveAsImage(marketer)">
                        <mat-icon>qr_code_2</mat-icon>
                    </button>
                    <button mat-icon-button (click)="downloadCsv($event, marketer)">
                        <mat-icon fontSet="material-symbols-outlined">csv</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let marketer"
                    [attr.colspan]="displayedColumns.length" class="h-0 max-h-0">
                    <app-user-table *ngIf="expandedElement === marketer" [introducerId]="marketer.name">
                    </app-user-table>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                class="hover:bg-gray-100"
                (click)="expandedElement = expandedElement === element ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="h-0 max-h-0"></tr>

        </table>

        <div class="visually-hidden">
            <qrcode #qrCode
                    [qrdata]="url"
                    [allowEmptyString]="true"
                    [ariaLabel]="'QR Code image with the following content...'"
                    [cssClass]="'center'"
                    [colorDark]="'#000000ff'"
                    [colorLight]="'#ffffffff'"
                    [elementType]="'canvas'"
                    [errorCorrectionLevel]="'M'"
                    [margin]="4"
                    [scale]="1"
                    [title]="'A custom title attribute'"
                    [width]="300"
            ></qrcode>
        </div>
    </div>
</div>