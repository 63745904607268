<div>
  <ngx-file-drop #fileDrop
                 [dropZoneLabel]=dragAndDropText
                 accept=".pdf,.jpg,.png"

                 [directory]="false"
                 (onFileDrop)="dropped($event)"
                 (onFileOver)="fileOver($event)"
                 (onFileLeave)="fileLeave($event)"
                 (click)="fileDrop.openFileSelector()">
  </ngx-file-drop>
</div>
