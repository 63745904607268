<div>

    <ion-row>
        <!-- Personal information -->
        <ion-col>
            <div class="computer-profile-table">
                <ion-grid class="grid-style">
                    <ion-row class="profile-header">
                        <ion-col>
                            <h3>
                                Personnal information
                            </h3>
                            <span><i>Required <ion-text color="danger">*</ion-text></i></span>
                        </ion-col>
                    </ion-row>

                    <!-- Computer version -->
                    <ion-row class="computer-profile-row">
                        <ion-col class="input-style">
                            <ion-item>
                                <ion-label position="floating">Email address
                                    <ion-text color="danger">*</ion-text>
                                </ion-label>
                                <ion-input [(ngModel)]="profile.email" class="hl" type="email">
                                </ion-input>
                            </ion-item>
                            <ion-item>
                                <ion-label position="floating">Phone
                                    <ion-text color="danger">*</ion-text>
                                </ion-label>
                                <ion-input [(ngModel)]="profile.phone" class="hl" type="tel"></ion-input>
                            </ion-item>
                            <app-grade-selector [grade]="admin?.grade"
                                                (gradeChange)="onGradeChange($event)"></app-grade-selector>
                        </ion-col>
                        <ion-col class="input-style">
                            <ion-item>
                                <ion-label position="stacked">Address
                                    <ion-text color="danger">*</ion-text>
                                </ion-label>
                                <ion-input [(ngModel)]="profile.address" class="hl">
                                </ion-input>
                            </ion-item>
                            <ion-item>
                                <ion-label position="stacked">City
                                    <ion-text color="danger">*</ion-text>
                                </ion-label>
                                <ion-input [(ngModel)]="profile.city" class="hl"></ion-input>
                            </ion-item>
                            <ion-item>
                                <ion-label position="stacked">Country of residency
                                    <ion-text color="danger">*</ion-text>
                                </ion-label>
                                <select [(ngModel)]="profile.country" class="select_profile" required>
                                    <option *ngFor="let country of residencyCountryList" [ngValue]="country.code"
                                            [selected]="country.code === profile.country">
                                        {{ country.name }}
                                    </option>
                                </select>
                            </ion-item>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </div>
        </ion-col>

        <!-- Passport -->
        <ion-col>
            <div class="computer-profile-table">
                <ion-grid class="grid-style">
                    <ion-row class="profile-header">
                        <ion-col>
                            <ion-row>
                                <h3>
                                    Passport details
                                </h3>
                            </ion-row>
                            <span
                            ><i>Required <ion-text color="danger">*</ion-text></i></span
                            >
                        </ion-col>
                    </ion-row>
                    <!-- Computer version -->
                    <ion-row class="computer-profile-row">
                        <ion-col class="input-style">
                            <ion-item>
                                <ion-label position="floating">First Name
                                    <ion-text color="danger">*</ion-text>
                                </ion-label>
                                <ion-input [(ngModel)]="profile.first_name" class="hl" type="given-name">
                                </ion-input>
                            </ion-item>
                            <ion-item>
                                <ion-label position="stacked">Nationality
                                    <ion-text color="danger">*</ion-text>
                                </ion-label>
                                <select [(ngModel)]="profile.nationality" class="select_profile" required>
                                    <option *ngFor="let country of nationalityCountryList" [ngValue]="country.code"
                                            [selected]="country.code === profile.nationality">
                                        {{ country.name }}
                                    </option>
                                </select>
                            </ion-item>
                            <ion-item>
                                <ion-label position="floating">Passport Number
                                    <ion-text color="danger">*</ion-text>
                                </ion-label>
                                <ion-input [(ngModel)]="profile.passport_number" class="hl"></ion-input>
                            </ion-item>
                            <ion-item>
                                <ion-label position="floating">Date of passport Expiry
                                    <ion-text color="danger">*</ion-text>
                                </ion-label>
                                <ion-input [(ngModel)]="profile.passport_expiration" class="hl">
                                </ion-input>
                            </ion-item>
                        </ion-col>
                        <ion-col class="input-style">
                            <ion-item>
                                <ion-label position="floating">Last Name
                                    <ion-text color="danger">*</ion-text>
                                </ion-label>
                                <ion-input [(ngModel)]="profile.last_name" class="hl" type="family-name">
                                </ion-input>
                            </ion-item>
                            <ion-item>
                                <ion-label position="floating">Birthday date
                                    <ion-text color="danger">*</ion-text>
                                </ion-label>
                                <ion-input [(ngModel)]="profile.dob" class="hl"></ion-input>
                            </ion-item>
                            <ion-item>
                                <ion-row size="3">
                                    <ion-col size="3" class="flex items-center justify-center">
                                        Status
                                    </ion-col>
                                    <ion-col size="3">
                                        <app-doc-status
                                                [status]="profile.passportState"></app-doc-status>
                                    </ion-col>
                                </ion-row>
                            </ion-item>
                            <ion-button color="primary"
                                        (click)="redirectIdVerificationFlow()">Process ID verification
                            </ion-button>
                            <ion-button *ngIf="admin?.ariadnext?.dossiers" color="primary"
                                        (click)="downloadPassportReport()">Download passport report
                            </ion-button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </div>
        </ion-col>
    </ion-row>

    <ion-row>
        <ion-col size="7.8">
            <div class="computer-profile-table">
                <ion-grid class="grid-style">
                    <ion-row class="profile-header">
                        <ion-col>
                            <h3>
                                Proof of residency
                            </h3>
                        </ion-col>
                    </ion-row>
                    <!-- Computer version -->
                    <ion-row class="computer-profile-row">
                        <ion-col>
                            <div class="pad">
                                <input #fileResidency
                                       (change)="addProfileImage(fileResidency.files, 'residencyPhotos')"
                                       accept="image/*" class="file-uploader" type="file"/>
                                <div class="image-set">
                                    <ng-container *ngFor="let image of residencyImages | keyvalue; index as i;">
                                        <a href="{{image.value.uri}}" target="_blank">
                                            <div *ngIf="image && image.value.uri"
                                                 [style.background-image]="'url(' + image.value.uri + ')'"
                                                 class="image">
                                                <ion-icon
                                                        (click)="removeProfileImage(image.value, 'residencyPhotos')"
                                                        color="danger" name="close-circle"></ion-icon>
                                            </div>
                                        </a>
                                    </ng-container>
                                    <div (click)="fileResidency.click()" class="add"></div>
                                </div>
                            </div>
                        </ion-col>
                        <ion-col class="input-style">
                            <ion-item class="ion-text-right">
                                <ion-label>Expiration</ion-label>
                                <ion-input [(ngModel)]="profile.residency_expiration"></ion-input>
                            </ion-item>
                            <ion-item>
                                <ion-label>Type of document</ion-label>
                                <ion-select [(ngModel)]="profile.residencyDocumentType"
                                            (ionChange)="onDocumentTypeSelection($event)"
                                            interface="popover">
                                    <ion-select-option *ngFor="let document of documentTypeList"
                                                       [value]="document.name">
                                        {{ document.name }}
                                    </ion-select-option>
                                </ion-select>
                            </ion-item>
                        </ion-col>
                        <ion-col class="input-style">
                            <ion-item>
                                <ion-label>Country</ion-label>
                                <ion-select [(ngModel)]="profile.residencyCountry" interface="popover"
                                            (ionChange)="onResidencyCountrySelection($event)">
                                    <ion-select-option *ngFor="let country of residencyCountryList"
                                                       [value]="country.code">
                                        {{ country.name }}
                                    </ion-select-option>
                                </ion-select>
                            </ion-item>
                            <ion-item>
                                <ion-label>Status</ion-label>
                                <app-doc-status
                                        [status]="profile.residencyState"></app-doc-status>
                            </ion-item>
                        </ion-col>
                    </ion-row>
                </ion-grid>
                <ion-row>
                    <ion-button (click)="updateProfile('profile',profile); setResidencyValid()"
                                [disabled]="!profile.residencyPhotos || profile.residencyState === 'valid'"
                                color="success">Set
                        residency as valid
                    </ion-button>
                    <ion-button (click)="setResidencyPending()" [disabled]="profile.residencyState === 'pending'"
                                color="warning">
                        Set residency as pending
                    </ion-button>
                    <ion-button (click)="setResidencyRejected()" [disabled]="profile.residencyState === 'rejected'"
                                color="danger">Set residency as rejected
                    </ion-button>
                </ion-row>
            </div>
        </ion-col>

        <ion-col size="4">
            <!-- Bank details -->
            <div class="computer-profile-table">
                <ion-grid class="grid-style">
                    <ion-row class="profile-header">
                        <ion-col>
                            <h3>Refund method</h3>
                        </ion-col>
                    </ion-row>
                    <ion-col class="input-style">
                        <ion-item>
                            <ion-label position="floating">IBAN</ion-label>
                            <ion-input (ngModelChange)="profile.bank.iban=$event" [ngModel]="profile.bank?.iban"
                                       class="hl">
                            </ion-input>
                        </ion-item>
                        <ion-item>
                            <ion-label position="stacked">Bank Country</ion-label>
                            <div class="hl">
                                {{ getCountry(profile.bank?.iban?.substring(0, 2)) }}
                            </div>
                        </ion-item>
                    </ion-col>
                </ion-grid>
            </div>
        </ion-col>
    </ion-row>
</div>